.wrapBlueSpan {
  display: flex;
  flex-flow: wrap row;
  .blueSpan {
    display: flex;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    align-self: flex-start;

    &.navLinks {
      display: inline-flex;
      text-decoration: underline;
      color: #3863BB;

      &.textDisable {
        color: #3863BB;
        margin-left: 5px;
      }

      &:hover {
        color: #3863BB;
        background-color: rgba(74, 135, 202, 0.1);
        transition: all .1s ease-out;
      }
    }

    &.redColor {
      &.navLinks {
        color: #D94560;
        padding: 5px 5px;
        &:hover {
          background: rgba(217, 69, 96, 0.12);
        }
      }
    }

    &.linkForRedBg {
      &.navLinks {
        color: rgb(125, 203, 129);
        &:hover {
          background: rgba(154, 220, 157, 0.31);
        }
      }
    }

    &.minHeight {
      padding: 0 10px;
    }
  }

  &.marginLeft165 {
    padding: 5px 10px;
    border-radius: 5px;
    margin-left: 165px;
    align-items: center;
    display: inline-flex;
    .img {
      width: 21px;
      height: auto;
    }
    &:hover {
      color: #3863BB;
      background-color: rgba(74, 135, 202, 0.1);
      transition: all .1s ease-out;
    }

    .blueSpan {
      padding: 0 10px;
      &.navLinks {
        &:hover {
          background-color: transparent;
        }
      }
    }
  }


}
