.svBookHeaderContainer {
    text-align: center;
    //----------------------
    display: flex;
    flex-flow: column wrap;
    //----------------------
    .routes {
        margin: 0 45px;
        display: flex;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 3px 3px 0 0;
        width: fit-content;
        a {
            position: relative;
            display: inline-block;
            border-radius: 3px 3px 0 0;
            &.active {
                background: #fff;
                &:before {
                    content: '';
                    position: absolute;
                    left: -6px;
                    bottom: 0;
                    height: 6px;
                    width: 6px;
                    background: url('../../assets/left-corner.svg');
                }
                &:after {
                    content: '';
                    position: absolute;
                    right: -6px;
                    bottom: 0;
                    height: 6px;
                    width: 6px;
                    background: url('../../assets/right-corner.svg');
                }
                .text {
                    color: #3863bb;
                    border-color: transparent;
                }
            }
            .text {
                font-size: 14px;
                line-height: 35px;
                color: #fff;
                margin: 11px 0;
                padding: 0 12px;
            }
        }
        a + a {
            .text {
                border-left: 1px solid rgba(0,0,0,0.12);
            }
        }
        a.active + a {
            .text {
                border-color: transparent;
            }
        }
    }
}
.svBookHeader {
    display: flex;
    flex-flow: row wrap;
    align-self: stretch;
    align-items: center;
    justify-content: space-between;
    padding: 30px 50px;
}

.carImgInfo p {
    margin: 5px;
    font-size: 16px;
}

.carInfo {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    align-self: stretch;
}

.carImgInfo {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-self: stretch;
}

.carImgInfo img {
    padding-right: 10px;
}

.mainImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    //margin: 0 auto;
}
.mainImgRnd {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0 auto;
}

.imgIn {
    overflow: hidden;
    height: 87px;
    width: 87px;
    min-width: 70px;
    border-radius: 50%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    background-image: url("../../assets/car-default-image-deep-purple.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-color: #4C6E9B;
    background-size: 100%;
    margin-right: 15px;
    &.rnd0{
        background-image: url("../../assets/randomImages/0.svg");
        background-size: 70%;
    }
    &.rnd1{
        background-image: url("../../assets/randomImages/1.svg");
        background-size: 70%;
    }
    &.rnd2{
        background-image: url("../../assets/randomImages/2.svg");
        background-size: 70%;
    }
    &.rnd3{
        background-image: url("../../assets/randomImages/3.svg");
        background-size: 70%;
    }
    &.rnd4{
        background-image: url("../../assets/randomImages/4.svg");
        background-size: 70%;
    }
    &.rnd5{
        background-image: url("../../assets/randomImages/5.svg");
        background-size: 70%;
    }
    &.rnd6{
        background-image: url("../../assets/randomImages/6.svg");
        background-size: 70%;
    }
    &.rnd7{
        background-image: url("../../assets/randomImages/7.svg") ;
        background-size: 70%;
    }
    &.rnd8{
        background-image: url("../../assets/randomImages/8.svg") ;
        background-size: 70%;
    }
    &.rnd9{
        background-image: url("../../assets/randomImages/9.svg") ;
        background-size: 70%;
    }
    &.rnd10{
        background-image: url("../../assets/randomImages/10.svg") ;
        background-size: 70%;
    }
    &.rnd11{
        background-image: url("../../assets/randomImages/11.svg") ;
        background-size: 70%;
    }
    &.rnd12{
        background-image: url("../../assets/randomImages/12.svg") ;
        background-size: 70%;
    }
}
