.imgIn {
    position: relative;
    display: block;
    overflow: hidden;
    height: 190px;
    width: 190px;
    border-radius: 50%;
    background: url("../../assets/car-default-image-grey.svg") no-repeat center;
    background-size: 100%;
    flex: 0 0 0;
    min-width: 190px;
    .inputHidden {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    label {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        z-index: 1;
        cursor: pointer;
        &:hover {
            background: rgba(0,0,0,0.1);
        }
    }
}

.carBasicImg {
    object-fit: cover;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}