.guestWrap {
  padding: 40px 73px 48px 73px;
  display: flex;
  flex-flow: column wrap;
  align-self: stretch;
  .titleGuest {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
    justify-content: stretch;
    padding-bottom: 10px;
    min-height: 55px;
    h2 {
      padding: 0;
      margin: 0;
      font-weight: 600;
      line-height: 30px;
      color: #434343;
    }
    .checkBoxesStatuses {
      position: relative;
      display: flex;
      flex-flow: row wrap;
      padding-left: 20px;
      padding-top: 5px;
      align-items: flex-end;
      justify-content: flex-start;
      align-self: stretch;
      flex-grow: 1;
      .statusesWithUpCheckBox {
        display: flex;
        flex-flow: column wrap;
        align-items: stretch;
        justify-content: stretch;
        .unionCheckBox {
          display: flex;
          flex-flow: row wrap;
          position: absolute;
          padding-left: 110px;
          margin-top: -10px;
          align-items: center;
          justify-content: center;
          .unionCheckBoxBg {
            background-color: white;
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: center;
          }
        }
        .forUnionCheckBox {
          display: flex;
          flex-flow: row wrap;
          .wrapCheckBox {
            display: flex;
            flex-flow: column wrap;
            .pipeUp {
              display: flex;
              flex-flow: row wrap;
              margin-bottom: 5px;
              align-items: stretch;
              justify-content: stretch;
              .inLine1 {
                width: 11px;
                display: flex;
                flex-flow: row wrap;
                border-top: 1px solid #CCD1D9;
                align-items: stretch;
                justify-content: stretch;
                height: 20px;
              }
              .inLine2 {
                display: flex;
                flex-flow: row wrap;
                border-top: 1px solid #CCD1D9;
                border-left: 1px solid #CCD1D9;
                align-items: stretch;
                justify-content: stretch;
                height: 20px;
                flex-grow: 1;
              }
            }

            &:first-child {
              .inLine1 {
                border-top: 1px solid transparent;
              }
              .inLine2 {
                border-top: 1px solid #CCD1D9;
                border-top-left-radius: 4px;
              }
            }

            &:last-child {
              .inLine1 {
                border-top-right-radius: 4px;
                display: flex;
                width: 11px;
                flex-flow: row wrap;
                border-top: 1px solid #CCD1D9;
                border-right: 1px solid #CCD1D9;
                align-items: stretch;
                justify-content: stretch;
                height: 20px;
              }

              .inLine2 {
                border: 1px solid transparent;
              }
            }

            &.groupChecked {
              .pipeUp {
                .inLine1 {
                  border-top: 1px solid #2a87d0;
                }
                .inLine2 {
                  border-top: 1px solid #2a87d0;
                  border-left: 1px solid #2a87d0;
                }
              }

              &:first-child {
                .inLine1 {
                  border-top: 1px solid transparent;
                }
                .inLine2 {
                  border-top: 1px solid #2a87d0;
                }
              }

              &:last-child {
                .inLine1 {
                  border-top: 1px solid #2a87d0;
                  border-right: 1px solid #2a87d0;
                }

                .inLine2 {
                  border: 1px solid transparent;
                }
              }
            }
          }
        }
      }
    }
  }
  .rowGuest {
    display: flex;
    flex-flow: row wrap;
    padding-top: 10px;
  }
}