.svBookHeaderContainer {
  text-align: center;
  .routes {
    margin: 0 45px;
    display: flex;
    width: fit-content;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 3px 3px 0 0;
    a {
      position: relative;
      display: inline-block;
      border-radius: 3px 3px 0 0;
      &.active {
        background: #fff;
        &:before {
          content: '';
          position: absolute;
          left: -6px;
          bottom: 0;
          height: 6px;
          width: 6px;
          background: url('../../../assets/left-corner.svg');
        }
        &:after {
          content: '';
          position: absolute;
          right: -6px;
          bottom: 0;
          height: 6px;
          width: 6px;
          background: url('../../../assets/right-corner.svg');
        }
        .text {
          color: #3863bb;
          border-color: transparent;
        }
      }
      .text {
        font-size: 14px;
        line-height: 35px;
        color: #fff;
        margin: 11px 0;
        padding: 0 12px;
      }
    }
    a + a {
      .text {
        border-left: 1px solid rgba(0,0,0,0.12);
      }
    }
    a.active + a {
      .text {
        border-color: transparent;
      }
    }
  }
}
.svBookHeader {
  display: flex;
  flex-flow: row wrap;
  align-self: stretch;
  align-items: baseline;
  justify-content: space-between;
  padding: 30px 50px;
}

.carImgInfo p {
  margin: 5px;
  font-size: 16px;
}

.carInfo {
  color: white;
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  align-self: stretch;
}

.carImgInfo {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-self: stretch;
}

.carImgInfo img {
  padding-right: 10px;
}

.mainImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0 auto;
}

.imgIn {
  overflow: hidden;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.4);
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-self: stretch;
  // background: url("../../../assets/car-default-image-deep-purple.svg") no-repeat center;
  background-size: 100%;
}

.carAndVisitorInfo {
  padding-left: 15px;
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
}

.subHeader {
  padding-bottom: 30px;
}
