.checkBoxes {
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  align-items: center;
  align-self: stretch;
  color: #3863BB;
  padding-right: 15px;
  font-size: 14px;
  line-height: 19px;
}