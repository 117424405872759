.svBookDetails {
    background-color: #FFFFFF;
    color: #373a3c;
    padding: 40px 50px;
    //----------------------
    display: flex;
    flex-flow: column wrap;
    align-self: stretch;
    //----------------------
}

.svEngineNodes {
    display: flex;
    flex-flow: column wrap;
    flex: 1 1 0;
}

.svRow {
    display: flex;
    flex-flow: row wrap;
    padding: 40px 0;
    justify-content: space-between;
}

.carEngine {
    display: flex;
    flex-flow: column wrap;
    background:  #F3F3F3 url('assets/engine.svg') no-repeat right;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24), 0 1px 4px rgba(0, 0, 0, 0.12);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    margin: 0 40px 40px 0;
    max-height: 300px;
    padding: 15px 40px 40px;
    flex: 1 1;
}

.carLowNodes {
    flex-flow: column wrap;
    flex: 1 1;
    background: url('assets/chain.svg') no-repeat right top;
}

.inlineFiles {
    display: flex;
    align-items: flex-end;
    flex-flow: row nowrap;
}

.inlineFiles img {
    padding-bottom: 7px;
    padding-left: 5px;
    flex: 0 0;
    width: 44px;
}