.monthsWrap {
  padding-right: 35px;
  display: flex;
  flex-flow: column wrap;
  align-items: flex-end;
  .monthSelected {
    display: flex;
    flex-flow: column wrap;
  }

  .titleMonths {
    margin-bottom: 9px;
  }

  &.padBottom {
    padding-bottom: 30px;
  }

  .paddingRight30 {
    padding-right: 35px;

  }
}
