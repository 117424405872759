.column {
  display: flex;
  flex-flow: column wrap;
}
.row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}
.subheader {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  min-height: 70px;
  border-radius: 2px 2px 0 0;
  background: linear-gradient(0deg, #EEEEEE, #EEEEEE), linear-gradient(177.04deg, #FFEAF7 -0.74%, #FFF3E0 100%);

  &.paddingRight20 {
    padding-right: 20px;
  }

  &.anchors {
    padding-bottom: 15px;
    padding-top: 19px;
  }

  .startHead {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
  }

  .wrapMarkTitle {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    font-size: 22px;
    line-height: 30px;

    div {
      padding-right: 15px;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }

  .roundMarkOrange {
    height: 26px;
    width: 26px;
    background: transparent url('../../assets/marckOrange.svg') no-repeat center center;
  }

  .roundMarkRed {
    height: 26px;
    width: 26px;
    background: transparent url('../../assets/marckRed.svg') no-repeat center center;
  }

  .roundMarkGreen {
    height: 26px;
    width: 26px;
    background: transparent url('../../assets/marckGreen.svg') no-repeat center center;
  }

  .textHeader {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding-left: 15px;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
  }

  &.justifyContentLeft {
    // justify-content: flex-start;
    .rowHeaderItems {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;
    }
  }
  &.withoutPadding {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 20px;
    height: 74px;
    align-items: center;
  }

  .rightActions {
    display: flex;
    align-self: stretch;
    &:last-child {
      align-items: center;
    }
  }
}

.leftHead {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}