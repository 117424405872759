  .buttonPanel {
    width: 340px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 100px;
    display: inline-flex;
    box-shadow: 0 2px 4px rgba(121, 121, 121, 0.14), 0 3px 4px rgba(121, 121, 121, 0.12), 0 1px 5px rgba(63, 63, 63, 0.2);
    margin-bottom: 30px;
  }
  .button {
    width: 130px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    vertical-align: middle;
    &:hover {
      cursor: pointer;
      background-color: #F0F6FE;
      color: #1D55D7;
    }
    &.active {
      cursor: pointer;
      background: #4A87CA;
      color: white;
    }
  }
  .buttonFirst {
    width: 170px;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    &:hover {
      cursor: pointer;
      background-color: #F0F6FE;
      color: #1D55D7;
      border-radius: 100px  0 0 100px;
    }
    &.active {
      cursor: pointer;
      background: #4A87CA;
      color: white;
      border-radius: 100px  0 0 100px;
    }
  }
  .buttonLast {
    width: 170px;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    &:hover {
      cursor: pointer;
      background: #F0F6FE;
      color: #1D55D7;
      border-radius: 0 100px 100px 0;
    }
    &.active {
      cursor: pointer;
      background: #4A87CA;
      color: white;
      border-radius: 0 100px 100px 0;
    }
  }
  .borderBox {
    background-color: rgba(0, 0, 0, 0.1);
    height: 27px;
    width: 1px;
    margin-top: 7px;
  }

.mainContainer {
  // margin: -60px -60px -30px -60px ;
}