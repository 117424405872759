.cardReport {
  height: 257px;
  width: 212px;
  display: flex;
  flex-flow: column wrap;
  background: #FFFFFF;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 3px;
  padding: 10px;
  justify-content: space-between;
  cursor: pointer;
  color: #434343;
  font-weight: 600;
  font-size: 16px;
  line-height: 120.6%;
  transition: all .1s ease-out;

  .imgIn {
    padding-top: 5px;
    width: 212px;
    height: 210px;
  }

  &:hover {
    color: #3863BB;
    transition: all .1s ease-out;
  }
}