@mixin btnActions () {
  height: 40px;
  width: 40px;
  margin: 4px;
  border: 1px solid transparent;
  border-radius: 5px;

}

.btnTrash {
  @include btnActions;
  border: 1px solid #DBDBDB;
  background: transparent url("../../../../assets/delete-icon-grey.svg") no-repeat center center;
  transition: all .2s ease;
  &:hover {
    border: 1px solid #D94560;
    background: #FFF5F7 url("../../../../assets/delete-icon.svg") no-repeat center center;
    transition: all .2s ease;
  }
  &.withoutMargin {
    display: flex;
    height: 42px;
    width: 42px;
    margin: 0 0 0 20px;
    align-self: flex-start;
    justify-self: flex-end;
  }
}

.btnAdd {
  @include btnActions;
  border: 1px solid #DBDBDB;
  background: transparent url("../../../../assets/add-btn.svg") no-repeat center center;
  transition: all .2s ease;
  &:hover {
    border: 1px solid #1CBC8C;
    background: #F0FFFB url("../../../../assets/add-btn.svg") no-repeat center center;
    transition: all .2s ease;
  }
}

.btnUpdate {
  @include btnActions;
  border: 1px solid #DBDBDB;
  background: transparent url("../../../../assets/pencil.svg") no-repeat center center;
  transition: all .2s ease;
  &:hover {
    border: 1px solid #1CBC8C;
    background: #F0FFFB url("../../../../assets/pencilGreen.svg") no-repeat center center;
    transition: all .2s ease;
  }
}

.btnEmpty {
  @include btnActions;
  border: none;
  background-color: transparent;
}