.reportsBody {
  display: flex;
  flex-flow: row wrap;
  background: #F9FAFC;
  color: #434343;
  flex-grow: 1;
  padding: 35px 50px;
  align-items: flex-start;
  justify-content: flex-start;

  a {
    margin: 15px 30px 15px 0;
  }
}