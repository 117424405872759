.trHead {
  height: 80px;
  color: rgba(0, 0, 0, 0.58);
  text-align: start;
}

.trHead th {
  text-align: start;
  padding-left: 45px;
  font-size: 16px;
  line-height: 22px;
}

