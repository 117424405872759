.dayTimes {
  justify-self: stretch;
  align-self: stretch;
  display: flex;
  flex-flow: column wrap;
  padding: 16px 32px 16px 57px;
  border-right: solid 1px #CCD1D9;
  flex-grow: 2;
  justify-content: flex-start;
  align-items: flex-start;

  &.andPosition {
    border-right: transparent;
  }
}
