.arrowDown  {
  position: relative;
  top: -2px;
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-right: 0.2em solid #434343;
  border-top: 0.2em solid #434343;
  transform: rotate(135deg);
  margin-right: 0.5em;
  margin-left: 1.0em;
}

.arrowUp {
  position: relative;
  top: -2px;
  content: "";
  display: inline-block;
  width: 9px;
  height: 9px;
  border-right: 0.2em solid #434343;
  border-top: 0.2em solid #434343;
  transform: rotate(-45deg);
  margin-right: 0.5em;
  margin-left: 1.0em;
}

.headAnchor {
  min-height: 103px;
  border-radius: 3px 3px 0 0;
  display: flex;
  flex-flow: row wrap;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
}