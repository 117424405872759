.photoModal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(127, 133, 152, 0.3);
  text-align: center;
  z-index: 5;
  &:before {
    content: '';
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }
  .body {
    box-sizing: border-box;
    position: relative;
    min-width: 550px;
    max-height: 100vh;
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 60px;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2), 0 6px 30px rgba(0, 0, 0, 0.12), 0 16px 24px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
    .imgContainer {
      position: relative;
      overflow: hidden;
      .ok {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        animation: hideOkIcon 1.2s ease-in forwards;
        &:before {
          content: '';
          height: 100%;
          display: inline-block;
          vertical-align: middle;
        }
        &:after {
          content: '';
          width: 86px;
          height: 86px;
          display: inline-block;
          vertical-align: middle;
          background: url('assets/ok-icon.svg') center no-repeat;
          background-size: contain;
        }
      }
    }
    img {
      display: block;
      min-width: 100%;
      max-width: 100%;
      max-height: calc(100vh - 60px);
    }
    .close {
      position: absolute;
      top: 25px;
      right: 25px;
      height: 54px;
      width: 54px;
      background: url('assets/close-icon.svg') center no-repeat rgba(0, 0, 0, 0.3);
      background-size: 50%;
      border-radius: 50%;
      cursor: pointer;
      z-index: 4;
    }
    .shift {
      position: absolute;
      top: 22%;
      width: 65px;
      height: 50%;
      background: rgba(0, 0, 0, 0.3);
      opacity: 0;
      z-index: 4;
      cursor: pointer;
      &:before {
        content: '';
        height: 100%;
        display: inline-block;
        vertical-align: middle;
      }
      &:after {
        top: 2px;
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 7px;
        height: 7px;
        border-right: 2px solid snow;
        border-top: 2px solid snow;
        margin-right: 0.5em;
        margin-left: 1.0em;
      }
      &:hover {
        opacity: 1;
      }
      &.left {
        left: 0;
        border-radius: 0 3px 3px 0;
        &:after {
          transform: rotate(225deg);
        }
      }
      &.right {
        right: 0;
        border-radius: 3px 0 0 3px;
        &:after {
          transform: rotate(45deg);
        }
      }
    }
    .footer {
      box-sizing: border-box;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 60px;
      width: 100%;
      background: #fff;
      padding: 0 10%;
      .buttonContainer {
        box-sizing: border-box;
        width: 50%;
        height: 100%;
        display: inline-block;
        vertical-align: top;
        font-size: 16px;
        line-height: 22px;
        &.main {
          color: #3863bb;
          text-align: left;
          span {
            background: url('assets/star-icon.svg') center left no-repeat;
            background-size: 21px;
            padding-left: 26px;
          }
        }
        &.delete {
          color: #e15173;
          text-align: right;
          span {
            background: url('../../../../../assets/delete-icon.svg') center left no-repeat;
            background-size: 17px;
            padding-left: 27px;
          }
        }
        .button {
          height: 100%;
          display: inline-block;
          vertical-align: top;
          cursor: pointer;
          &:before {
            content: '';
            height: 100%;
            display: inline-block;
            vertical-align: middle;
          }
          &.disabled {
            opacity: 0.5;
          }
          span {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }
  }
}

@keyframes hideOkIcon {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}