@mixin cells () {
  display: flex;
  align-self: stretch;
  justify-self: stretch;
  padding: 0 6px;
  width: calc(100% / 2);

  &.boldText {
    font-weight: 600;
    line-height: 19px;
  }

  &.greenText {
    color: #3A8F15;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }

  &.transparentBorder {
    border-bottom: transparent 1px solid;
  }
}

.columnMonth {
  width: 112px;
  display: flex;
  flex-flow: wrap column;
  flex-grow: 1;
  div {
    display: flex;
    color: rgba(0, 0, 0, 0.85);
    height: 48px;
    align-items: center;
    border-bottom: 1px #E6E6E6 solid;
  }

  .headerColumn {
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 15px;
    color: rgba(0, 0, 0, 0.55);
  }

  .boldText {
    font-weight: 600;
    line-height: 19px;
  }

  .greenText {
    color: #3A8F15;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }

  .itemEnd {
    border-bottom: 1px transparent solid;
  }
}

.count {
  @include cells;
  justify-content: flex-end;
}

.percent {
  @include cells;
  justify-content: flex-start;

  &.onlyCount {
    color: transparent;
  }
}