.completedCards {
    align-self: center;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}
.aHref {
    text-decoration: none;
    transition: 150ms liner;
    transition-property: color, border-bottom-color;
}

.textColorBlue {
    color: #A3B6C8;
    border-bottom: 1px solid #A3B6C8;
}

.textColorViolet {
    color: #A6A3C2;
    border-bottom: 1px solid #A6A3C2;
}

.filledListInspect .aHref:hover,
.filledListInspect .aHref:after {
    color: white;
    border-bottom: 1px solid white;
    text-decoration: none;
}