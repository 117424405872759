.rButtons {
  display: flex;
  flex-flow: wrap row;
  align-items: stretch;
  max-width: max-content;
  flex: 0 0 0;
  border-radius: 50px;
  margin: 12px 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  .rButton {
    height: 40px;
    box-sizing: border-box;
    color: #3863BB;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    min-width: 214px;
    border-top: 1px solid #4A87CA;
    border-bottom: 1px solid #4A87CA;
    border-left: 1px solid #4A87CA;
    cursor: pointer;
    user-select: none;
    flex: 1 0 150px;
    transition: all ease-out .2s;

    &:first-child {
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      border-left: 1px solid #4A87CA;
    }
    &:last-child {
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      border-right: 1px solid #4A87CA;
    }

    &.select {
      color: #FFFFFF;
      background-color: #4A87CA;
      transition: all ease-out .2s;
    }

    &.withLabel {
      background-color: transparent;
      transition: all ease-out .2s;
    }
  }
}