.inputKm {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.lines {
  border-top: 1px solid #E0E0E0;
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  align-self: stretch;
  align-items: center;
  padding: 35px 73px 35px 73px;

/*  input {
    min-height: 40px;
    padding: 0 0 0 15px;
    border-radius: 3px;
    min-width: 170px;
    max-width: 270px;
    border: solid 1px #E0E0E0;
    background: #F0F0F0;
    font-size: 14px;
    &::placeholder {
      color: #ABABAB;
    }
  }*/

  .oilChoice {
    display: flex;
    flex-flow: row wrap;
    align-self: center;
    min-width: 170px;
  }
}

.inputGray {
  display: flex;
  flex-flow: wrap row;
  min-height: 40px;
  max-height: 40px;
  padding: 0 0 0 15px;
  border-radius: 3px;
  min-width: 170px;
  max-width: 270px;
  border: solid 1px #E0E0E0;
  background: #F0F0F0;
  font-size: 14px;
  &::placeholder {
    color: #ABABAB;
  }
  &.bigMaxWidth {
    min-width: 50px;
    max-width: 470px;
    flex-grow: 1;
  }

  &.light {
    background: white;
  }

  &.light:disabled {
    background: #f3f3f3;
  }

  &.light2 {
    background: #FFFFFF;
    &:disabled {
      background: #FFFFFF;
    }
  }

  &.borderRed {
    transition: all 250ms ease-out;
    border-color: #D94560;
  }

  &.stretch {
    align-self: stretch;
  }

  &.font16 {
    font-size: 16px;
    line-height: 22px;
  }

  &.maxWidth600 {
    max-width: 600px;
  }

  &.minHeight38 {
    min-height: 38px;
  }

  &.marginRight12 {
    padding-right: 12px;
  }

  &.marginRight8 {
    margin-right: 8px;
  }

  &.disabled2 {
    background-color: #E6E6E6;
    border-color: #CCD1D9;
    color: rgba(0, 0, 0, 0.65);
    cursor: not-allowed;
  }

  &.marginBottom5 {
    margin-bottom: 5px;
  }
}


.oilOldKm {
  max-width: 209px;
  margin: 0 0 35px;
  font-size: 16px;
  line-height: 22px;
  height: 38px;
  &.redBorder {
    border-color: #D94560;
  }
}

form.oilFormField {
  display: flex;
  flex-flow: row wrap;

  input {
    margin-bottom: 0;
  }
}