.rus {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
}

.carNumberIntroRus {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-flow: column wrap;
  border-left: 1px solid #868686;
  padding: 3px;
  flex-grow: 1;
  font-size: 18px;
  line-height: 14px;
  font-weight: 600;
  color: #6B6B6B;
}