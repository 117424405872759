.notFound {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  display: flex;
  padding: 50px;
  color: #949DA8;
  flex-flow: column wrap;
  align-items: center;
  background: #F9FAFC;
  flex-grow: 1;
}

.historyReportBody {
  display: flex;
  flex-flow: column wrap;
  background: #F9FAFC;
  color: #252626;
  flex-grow: 1;

  .tableHeader {
    display: flex;
    flex-flow: column wrap;
    align-items: stretch;
    justify-content: stretch;
    font-size: 14px;
    line-height: 17px;
    height: 70px;
  }

  .tableBody {
    display: flex;
    flex-flow: column wrap;
  }

  .rowOwner {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: stretch;
    padding: 7px 0 7px 40px;
    border-top: solid 1px #E6E6E6;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;

    div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 10px 0 0;
      width: 250px;
    }

    .dataRowBody {
      display: flex;
      justify-content: flex-start;
      padding: 0 10px 0 0;
      width: 250px;
      align-items: flex-start;
    }

    div.pairDescription {
      display: flex;
      flex-flow: column wrap;
      justify-content: flex-start;
      align-items: flex-start;
      color: #252626;
      div {
        flex-grow: 0;
        width: auto;
        align-items: stretch;
      }
    }

    .greyText {
      color: #707171;
      font-style: italic;
    }

    .flexEnd {
      flex-grow: 1;
    }

    .dataRow {
      width: 120px;
    }

    &:last-child {
      flex-grow: 1;
    }

    &.headerRowOwner {
      border-top: none;
      border-bottom: solid 4px #E6E6E6;
      color: #707171;
      font-size: 14px;
      line-height: 17px;
      .dataRow {
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}

.collectionSheetDetails {
  background: #E6E6E6;
  height: 48px;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.55);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 40px;
  div {
    padding-right: 10px;
  }
}