@mixin divDelMixin () {
  .divDel {
    background-color: transparent;
    color: #D94560;
    justify-content: center;
    border: none;
    cursor: pointer;

    img {
      min-width: 22px;
      text-align: center;
      min-height: 22px;
      max-width: 22px;
      width: 22px;
    }
  }
}

@mixin divAddMixing () {
  width: 100%;
  padding-bottom: 45px;
  border: none;
  border-collapse: collapse;
  transition: opacity .3s ease-in-out;

  td {
    padding-left: 45px;
    border-top: 1px solid #E0E0E0;
  }

  .trWrap {
    max-width: content-box;
    min-height: 44px;
    max-height: 44px;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: space-between;

    td.maxArrow {
      justify-content: center;
      text-align: center;
      max-width: 67px;
      min-width: 67px;
      width: 67px;
      color: #3863BB;
    }

    .spanAround {
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-right: none;
      max-width: content-box;
      user-select: none;
      color: rgba(0, 0, 0, 0.37);

      div {
        margin: 0 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 35px;
        border: solid #E0E0E0 1px;
        transition: all 0.5ms;
        cursor: pointer;
        min-height: 24px;
        min-width: 110px;
      }


      div.selected {
        border-color: #4a87ca;
        color: #3863bb;
        transition: all 0.5ms;
      }

      div.selected:hover {
        background-color: #F0F6FF;
        border: 1px solid #4A87CA;
        color: #3863BB;
        transition: all 0.5ms;
      }

      div.notSelected {
        border-color: #E0E0E0;
        color: #ABABAB;
        transition: all 0.5ms;
      }

      div:hover {
        border-color: rgba(0, 0, 0, 0.37);
        color: rgba(0, 0, 0, 0.57);
        transition: all 0.5ms;
      }

    }

    td.pink {
      color: #CD56A4;
    }

    td.notpink {
      color: #3863BB;
    }

    td {
      padding: 0 10px;
      margin: 0;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      max-width: content-box;
      border-right: solid 1px #E0E0E0;
      flex: 1 0 auto;

    }

    .spanBtnPlus {
      color: #ABABAB;
      cursor: pointer;

      button {
        color: #1CBC8C;
        background-color: transparent;
        text-align: center;
        border: none;
        cursor: pointer;

        img {
          min-width: 22px;
          text-align: center;
          min-height: 22px;
          max-width: 22px;
          width: 22px;
        }
      }
    }

    .spanBtnPlus:hover {
      color: #14A77B;
    }

    .delWrap {
      padding: 0 10px;
      border-right: none;
      justify-content: flex-start;
      cursor: pointer;
    }
  }

  .andCell {
    max-width: 16px;
    min-width: 16px;
    color: #ABABAB;
    justify-content: center;
  }

  @include divDelMixin;

  .timeOne {
    max-width: 192px;
    min-width: 192px;
    align-items: stretch;
    justify-content: stretch;
  }
}

.secretaryCallTime {
  display: flex;
  flex-flow: column wrap;
  justify-content: stretch;
  align-items: stretch;
  margin: 0 50px 25px 50px;
  border: 1px solid #CCD1D9;
  border-radius: 3px;

  &.firstSecretary {
    margin-top: 50px;
  }

  .wrapBodySubCollect {
    display: flex;
    flex-flow: column wrap;
    justify-content: stretch;
    align-self: stretch;
  }

  .fields {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 25px 73px 25px 73px;

    form {
      padding-bottom: 35px;

      label {
        display: flex;
        align-items: center;
        justify-content: stretch;
      }
    }

    input {
      min-height: 40px;
      padding-left: 15px;
      border-radius: 3px;
      border: solid 1px #E0E0E0;
      background: #F0F0F0;
      margin-right: 15px;
    }
  }

  .tableSecretary {
    @include divAddMixing;
  }

  .tableSecretaryDetails {
    min-height: 353px;
    @include divAddMixing;
  }

  &.inVisitor {
    margin: 25px 0 0 0;
  }
}

.secretaryCallTime:last-child {
  margin-bottom: 50px;
}

.scrollSetX {
  min-height: content-box;
  overflow-x: auto;
  scrollbar-face-color: gray;
  scrollbar-track-color: transparent;
  scrollbar-arrow-color: transparent;
  scrollbar-shadow-color: transparent;
  scrollbar-3dlight-color: transparent;
  scrollbar-highlight-color: white;
}

.scrollSetX.tableSecretaryDetails {
}

.scrollSetX::-webkit-scrollbar {
  width: 3px;
  background-color: transparent;
}

.scrollSetX::-webkit-scrollbar-thumb {
  width: 3px;
  border-radius: 3px;
  background-color: rgba(255,255,255,0.1);
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}

.scrollSetX:hover::-webkit-scrollbar-thumb {
  width: 3px;
  border-radius: 3px;
  background-color: rgba(0,0,0,0.1);
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}


/**** Secretary button overall ****/

@mixin btnSecretaryCallTime () {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  color: #3863BB;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;

  border: solid #4A87CA 1px;
  font-size: 14px;
  line-height: 19px;
  flex-grow: 1;
  min-width: 160px;

  &:hover {
    background-color: #F0F6FF;
    color: #3863BB;
    transition: all .2s ease-out;
  }
}

.btnOverall {
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-self: stretch;
  margin: 25px 0;
  max-width: 350px;
  min-width: 320px;
  min-height: 40px;
  font-size: 14px;
  line-height: 19px;

  .first {
    border-bottom-left-radius: 100px;
    border-top-left-radius: 100px;
    border-right: 1px solid transparent;
    @include btnSecretaryCallTime
  }

  .second {
    border-bottom-right-radius: 100px;
    border-top-right-radius: 100px;
    @include btnSecretaryCallTime
  }

  .selectBtn, .selectBtn:hover {
    background: #4A87CA;
    color: white;
  }

}
