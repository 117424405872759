.mileage {
  position: relative;
  background-color: #FFFFFF;
  padding: 30px;
  display: flex;
  flex-flow: wrap column;
  min-width: 752px;
  //----------------------
  align-self: stretch;
  // flex: 1 9;
  //----------------------
  color: rgba(0, 0, 0, 0.85);
  .block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #F3F3F3;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24), 0 1px 4px rgba(0, 0, 0, 0.12);
    border-radius: 3px;
    margin: 20px;
    padding: 30px 50px 35px;
    box-sizing: border-box;
    &.averageMileage {
      padding-left: 40px;
      padding-right: 40px;
      .blockTitle {
        padding: 0 10px 24px;
      }
    }
    &.small {
      padding-top: 18px;
      padding-bottom: 23px;
    }
    .blockTitle {
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
      color: #574CB7;
      display: inline-block;
      vertical-align: bottom;
      padding-right: 11px;
    }
    .blockDate {
      display: inline-block;
      vertical-align: bottom;
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
      color: rgba(0, 0, 0, 0.58);
      padding-right: 11px;
    }
    .blockDays {
      display: inline-block;
      vertical-align: bottom;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: rgba(0, 0, 0, 0.33);
    }
    //.blockTitle + .blockDate {
    //  padding-left: 11px;
    //}
    //.blockDate + .blockDays {
    //  padding-left: 11px;
    //}
  }
  .header {
    display: flex;
    flex-flow: row wrap;
    margin: 12.5px 0;
  }
  .speedometerContainer {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex: 1 1;
    max-width: 327px;
    min-width: 327px;
    flex-wrap: wrap;
    min-height: 253px;
    padding: 35px 32px;
    margin: 7.5px 20px;
    .speedometer {
      height: 183px;
      width: 100%;
      background: url('./assets/kmh.png') center top no-repeat;
      background-size: 266px;
      text-align: center;
      white-space: nowrap;
      &:before {
        content: '';
        height: 100%;
        display: inline-block;
        vertical-align: middle;
      }
      &.miles {
        background-image: url('./assets/MPH.png');
      }
      .button {
        width: 132px;
        display: inline-block;
        vertical-align: bottom;
        font-size: 14px;
        line-height: 38px;
        border: 1px solid #4A87CA;
        box-sizing: border-box;
        background: #fff;
        cursor: pointer;
        padding: 0;
        color: rgba(0, 0, 0, 0.85);
        &:hover {
          background-color: #F0F6FF;
          color: #3863BB;
          transition: all .2s ease-out;
        }

        &.left {
          border-radius: 100px 0 0 100px;
        }
        &.right {
          border-radius: 0 100px 100px 0;
        }
        &.active, &.active:hover {
          background: #4a87ca;
          color: #fff;
        }
      }
    }
  }
  .mileageCollectionDates {
    display: flex;
    flex-flow: column wrap;
    flex: 1 1;
    min-width: 250px;
    box-sizing: border-box;
    max-height: 685px;
    .block {
      margin: 7.5px 20px;
      flex: 1 1;
    }
  }
  .averageMileageValues {
    display: flex;
    flex-wrap: wrap;
    .averageMileage {
      flex: 1 1;
      box-sizing: border-box;
      margin: 0 10px;
      .title {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.58);
        padding-bottom: 3px;
      }
      .value {
        background: #ffffff;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        border-radius: 3px;
        font-size: 16px;
        line-height: 38px;
        color: rgba(0, 0, 0, 0.58);
        padding: 0 15px;
        white-space: nowrap;
      }
    }
  }
  .lastMileageValue {
    padding-top: 36px;
    .numbersContainer {
      background: #686868;
      padding: 2px;
      border-radius: 2px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 11px;
      white-space: nowrap;
      .numbers {
        background: #212121;
        height: 33px;
        box-sizing: border-box;
        border-radius: 1px;
        padding: 1px;
        .number {
          width: 25px;
          display: inline-block;
          font-size: 24px;
          font-weight: 600;
          line-height: 31px;
          background: linear-gradient(180deg, #494949 -10.61%, #6A6A6A 47.56%, #494949 109.09%);
          border-radius: 1px;
          color: #fff;
          margin-right: 1px;
          text-align: center;
          &:last-child {
            margin: 0;
          }
        }
      }
    }
    .unit {
      display: inline-block;
      vertical-align: middle;
      font-size: 18px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.33);
    }
  }
  .mileageYear {
    border: 1px solid #E0E0E0;
    border-radius: 3px;
    margin-top: 10px;
    .mileageHeader {
      position: relative;
      font-size: 18px;
      font-weight: 600;
      line-height: 70px;
      color: #3863BB;
      background: #DDDDDD;
      padding: 0 30px;
      &:after {
        content: '';
        position: absolute;
        top: 29px;
        right: 34px;
        width: 10px;
        height: 10px;
        border-right: 3px solid #434343;
        border-top: 3px solid #434343;
        transform: rotate(45deg);
        transition: transform .25s ease-out 0s;
      }
      &.opened {
        &:after {
          transform: rotate(135deg)
        }
      }
    }
    .tableWrapper {
      height: 0;
      overflow: hidden;
      box-sizing: border-box;
      transition: height .25s ease-out 0s;
    }
    table {
      width: 100%;
      border-spacing: 20px;
      //margin: -10px;
    }
    th {
      font-size: 16px;
      font-weight: 400;
      //height: 70px;
      color: rgba(0, 0, 0, 0.58);
    }
    td {
      //padding: 10px;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 3px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      padding: 8px 15px;
      //.value {
      //  background: #ffffff;
      //  border: 1px solid #e0e0e0;
      //  box-sizing: border-box;
      //  border-radius: 3px;
      //  font-size: 16px;
      //  color: rgba(0, 0, 0, 0.58);
      //  padding: 8px 15px;
      //}
    }
  }
  .blockTitle + .mileageYear {
    margin-top: 32px;
  }
}