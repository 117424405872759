.car {
  display: flex;
  flex-flow: row nowrap;
  height: 27px;
  align-items: stretch;
  justify-content: stretch;
  min-width: 115px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  font-family: 'Open Sans', sans-serif;

  .whiteNumber {
    margin-left: 8px;
    border-radius: 3px 0 0 3px;
    padding: 0 5px 0 8px;
    display: flex;
    flex-flow: row wrap;
    background-color: #FFFFFF;
    color: #456299;
    align-items: center;
    min-width: 70px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
  }
  .region {
    display: flex;
    flex-flow: column nowrap;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-left: 2px;
    border-radius: 0 3px 3px 0;
    border-left: none;
    background-color: #FFFFFF;
    color: #456299;
    min-width: 29px;
    .numReg {
      padding: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
    .regionChar {
      padding: 0;
      font-style: normal;
      font-weight: bold;
      font-size: 9px;
      line-height: 9px;
    }
  }

  &.darkTheme {
    .region, .whiteNumber {
      background-color: #456299;
      color: #FFFFFF;
    }
  }

}