.carBasicNumber {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
}

/*------------------------*/

.carNumber {
    display: flex;
    flex-flow: row nowrap;
    justify-content: stretch;
    align-items: stretch;
    margin: 0 13px;
    min-height: 40px;
    min-width: 154px;
    border-radius: 3px;
    border: 1px solid gray;
    background-color: white;
}

.carNull {
    color: #ABABAB;
    border-color: #E0E0E0;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 22px;
}
