.svBooks {
  display: flex;
  flex-flow: column wrap;

  .inputWithFilter {
    display: flex;
    flex-flow: row wrap;
  }

  .svBooksHeader {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    padding: 30px 50px 20px;

    .h1Numbers {
      padding-left: 10px;
      color: #AFBADC;
    }
  }

  .formSearch {
    display: flex;
    flex-flow: row wrap;
    height: 40px;
    background-color: white;
    border-radius: 3px;
    border: none;
    flex: 1 0 auto;
    margin: 0 50px 30px 50px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24), 0 1px 4px rgba(0, 0, 0, 0.12);
    &.delMargin {
      margin: 0 50px 30px 50px;
    }

    .btnSubmit {
      flex-grow: 1;
      -webkit-flex-grow: 1;
      min-width: 64px;
      &.isLoadBg {
        background-image: none;
      }
    }

    & label {
      display: flex;
      align-self: stretch;
      justify-self: flex-end;
      -webkit-flex-grow: 14;
      flex-grow: 14;

      & input {
        font-family: 'Open Sans', sans-serif;
        border: none;
        font-size: 16px;
        padding: 5px 15px;
        border-radius: 3px 3px 0 3px;
        width: 100%;

        &::-moz-placeholder {
          color: rgba(0, 0, 0, 0.33);
        }
        &::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.33);
        }
      }
    }
  }

  .btnSubmit {
    position: relative;
    border: none;
    border-radius: 0 3px 3px 0;
    background: #4E91DB url("../../assets/search.svg") no-repeat center center;
  }
}

@media(max-width: 1700px) {
  .formSearch {
    margin: 0 20px 10px 20px;

    & label {
      display: flex;
      align-self: stretch;
      justify-self: flex-end;
      -webkit-flex-grow: 7;
      flex-grow: 7;

    }

    & label input, & button.btnSubmit {
      flex-grow: 3;
      -webkit-flex-grow: 3;
    }
  }
}
.addGuest {
  width: 135px;
  height: 33px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 3px;
  margin-left: auto;
  background-image: url("./assets/addGuest.svg");
  background-repeat: no-repeat;
  background-position: 15px 10px;
  font-weight: normal;
  font-size: 16px;
  color: rgba(56, 99, 187, 1);
  padding-left: 45px;
  padding-top: 7px;
  &:hover {
    cursor: pointer;
  }
}