.svBookDetails {
  background-color: #FFFFFF;
  color: #373a3c;
  padding: 40px 50px;
  //----------------------
  display: flex;
  flex-flow: column wrap;
  flex-grow: 1;
  align-self: stretch;
  //----------------------
}