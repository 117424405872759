.carNumberIntro {
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-grow: 2;
    font-size: 28px;
    line-height: 38px;
}

.carNumberIntro span {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    padding: 2px 3px;
}
