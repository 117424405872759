.svCar {
    margin: 0 25px 25px 0;
    display: flex;
    flex-flow: column wrap;
    align-items: stretch;
    justify-content: stretch;
    max-width: 180px;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24), 0 1px 4px rgba(0, 0, 0, 0.12);
    background-color: white;
}

.cardSvH {
    height: 100px;
    width: 180px;
    background: #AAB7C6 url("./assets/car_img.svg") no-repeat center center;
}

.cardSvH img {
    height: 100px;
    width: 180px;
    align-self: stretch;
    object-fit: cover;
}

.cardSvB {
    display: flex;
    flex-flow: column wrap;
    min-height: 120px;
    justify-content: space-around;
}

.cardSvBH2 {
    color:rgba(0, 0, 0, 0.58);
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-self: flex-start;
    justify-content: center;
    padding: 0 10px;
}

