/*------------Cards-----------*/
.card  {
  display: flex;
  flex-flow: column wrap;
  background: #ffffff;
  border-radius: 3px;
  color: #262626;
  margin: 5px;
  min-height: 80px;
}

.hiddenItems {
  display: none;
}

.elems {
  display: flex;
  align-items: center;
  justify-content: center;
}

.scrollSet {
  position: relative;
  min-height: 200px;
  max-height: 500px;
  overflow-y: auto;
  scrollbar-face-color: white;
  scrollbar-track-color: transparent;
  scrollbar-arrow-color: transparent;
  scrollbar-shadow-color: transparent;
  scrollbar-3dlight-color: transparent;
  scrollbar-highlight-color: white;

  .carsLoad {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
.scrollSet::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.scrollSet::-webkit-scrollbar-thumb {
  width: 6px;
  border-radius: 5px;
  background-color: rgba(255,255,255,0.1);
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}

.scrollSet:hover::-webkit-scrollbar-thumb {
  width: 6px;
  border-radius: 5px;
  background-color: rgba(255,255,255,0.3);
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}

.addCars {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  justify-self: center;
  cursor: pointer;
  min-width: 150px;
  border: 1px solid snow;
  margin: 10px;
  padding: 10px;
  border-radius: 3px;
}