.wrapStatusesWithValidate {
  position: relative;
  .btnLoad {
    position: absolute;
    display: flex;
    padding: 0;
    align-self: stretch;
    justify-self: stretch;
    min-width: 100%;
    min-height: 100%;
    background-color: transparent;
  }
}
