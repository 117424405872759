
.wrapScroll {
  position: sticky;
  bottom: 0;
  transition: all .200ms ease-out;
  z-index: 3;

  .trackScroll {
    width: 100vw;
    height: 32px;
    display: flex;
    align-self: stretch;
    justify-self: stretch;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.24);
    padding: 0 10px;
    transition: all .200ms ease-out;
    z-index: 4;
    .thumbScroll {
      height: 12px;
      width: 200px;
      border-radius: 100px;
      background-color:rgba(255, 255, 255, 0.8);
      transition: all .200s ease-out;
      z-index: 5;

    }
    &:hover {
      background: rgba(0, 0, 0, 0.32);
      transition: all .200s linear;
      .thumbScroll {
        background: #FFFFFF;
        transition: all .200s linear;
      }
    }
  }

  @media(max-width: 1400px) {
    .trackScroll {
      width: 100vw;
    }
  }
}