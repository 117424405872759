.rowOwner {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: stretch;
  padding: 7px 0 7px 40px;
  border-top: solid 1px #E6E6E6;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px 0 0;
    width: 250px;
  }

  .dataRowBody {
    display: flex;
    justify-content: flex-start;
    padding: 0 10px 0 0;
    width: 250px;
    align-items: flex-start;
  }

  div.pairDescription {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-start;
    color: #252626;
    div {
      flex-grow: 0;
      width: auto;
      align-items: stretch;
    }
  }

  .greyText {
    color: #707171;
    font-style: italic;
  }

  .flexEnd {
    flex-grow: 1;
  }

  .dataRow {
    width: 120px;
  }

  &:last-child {
    flex-grow: 1;
  }

  &.headerRowOwner {
    border-top: none;
    border-bottom: solid 4px #E6E6E6;
    color: #707171;
    font-size: 14px;
    line-height: 17px;
    .dataRow {
      font-size: 14px;
      line-height: 17px;
    }
  }
}