.aboutAutoText {
    p {
        margin: 5px 0;
        font-size: 16px;
    }
    .guest {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
    }
}