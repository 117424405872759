.blListInspect {
    display: flex;
    // flex-flow: column wrap;
    flex-flow: column;
    justify-content: stretch;
    align-items: stretch;
    align-self: stretch;
    padding: 0 10px;

    .emptyData {
        padding: 0 35px;
    }

    .btnLoad {
        position: absolute;
        margin: 0 -10px;
        display: flex;
        min-width: 20%;
        min-height: 100%;
        border-radius: 100px;
    }

}

.blListInspect p {
    margin: 0;
}

.listCollectInspectHeader {
    padding-top: 20px;
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    align-self: stretch;
    align-items: stretch;
}

.listCollectInspectHeader h1 {
    text-align: center;
}

/*------------Cards-----------*/
.card  {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    background: #ffffff;
    border-radius: 3px;
    color: #262626;
    margin: 5px;
}

.textBlack {
    padding-left: 15px;
}

.cardItem {
    align-self: stretch;
    display: flex;
    flex-flow: row wrap;
    justify-content: stretch;
    align-items: stretch;
    border-radius: 3px;
    min-height: 90px;
    min-width: content-box;
    max-height: 100px;
    flex-wrap: nowrap;
    cursor: pointer;
}

.cardHeader {
    display: flex;
    justify-content: center;
    background-color: #77B4AC;
    flex-flow: column wrap;
    max-width: 70px;
    min-width: 50px;
    max-height: 90px;
    min-height: 80px;
    border-radius: 3px 0 0 3px;
}

/* card body */

.cardBody {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: stretch;
    max-height: 100px;
    min-height: 80px;
    flex-grow: 1;
    .cardP {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: flex-start;
        font-weight: normal;
        font-style: normal;
        font-size: 14px;
        line-height: 160%;
        p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}




