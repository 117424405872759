.reportsHeader {
  display: flex;
  height: 100px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  color: #FFFFFF;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  padding: 0 50px;
  .count {
    font-weight: 600;
    font-size: 30px;
    line-height: 41px;
  }

  &.inReport {
    padding: 0 32px;
    font-size: 22px;
    line-height: 28px;
    justify-content: space-between;
  }

  &.reportProlongation {
    padding: 0 32px;
    font-size: 22px;
    line-height: 28px;
    justify-content: space-between;
  }

  &.cards {
    color: #434343;
    align-items: flex-start;
    border-radius: 5px;
    background-color: #C9F1E0;
    padding: 10px 15px;
  }

  &.notActive {
    background-color: #E5E1E1;
    color: #949DA8;
    transition: 0.3s all ease-out;
    .textHeader {}
    &:hover {
      transition: 0.3s all ease-out;
      background-color: #FFFFFF;
      color: #434343;
    }
  }

  &.columnWrap {
    flex-flow: column wrap;
  }

  &.sticky {
    position: sticky;
    top: 56px;
  }
}