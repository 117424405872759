.reasonsModal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  // background: rgba(127, 133, 152, 0.3);
  background: rgba(0, 0, 0, 0.3);
  z-index: 5;

  .whiteReasonsModal {
    position: fixed;
    padding: 15px 35px;
    top: 25%;
    left: 25%;
    width: 50%;
    min-width: 453px;
    background: #FFFFFF;
    text-align: center;
    display: flex;
    flex-flow: column wrap;

    .headerModal {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;

      .btnClose {
        background-color: #FFFFFF;
        border: none;
        min-width: 50px;
        min-height: 50px;
        padding: 0;
      }
    }

    .fieldsReason {
      padding: 25px 0;
      display: flex;
      flex-flow: column wrap;
      justify-content: flex-start;
      align-items: center;

      .fieldInputs {
        display: flex;
        flex-flow: column wrap;
        justify-content: flex-start;
        align-items: flex-start;
      }
      .childPadding {
        margin: 20px 0 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: row wrap;

        .btnAdd {
          justify-self: center;
          height: 40px;
          width: 160px;
          padding: 0;
          background: #4A87CA;
          border: 1px solid transparent;
          color: #ffffff;
          margin-top: 15px;
        }
      }


    }

  }
}

@media(max-width: 720px) {

  .reasonsModal {
    .whiteReasonsModal {
      padding: 25px 10px;
      top: 10px;
      left: 10px;

      .fieldsReason {
        padding:  0;



        .childPadding {

          .btnAdd {
            width: content-box;
            align-self: center;
          }

        }

      }
    }
  }
}
