.statusForm {
  display: flex;
  flex-flow: column wrap;
  padding-bottom: 23px;
  &:first-child {
    padding-top: 0;
  }
  .statusFormHeader {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 12px;
    min-height: 30px;
    .text {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #434343;
      &.orange {
        color: #D27F12;
      }
    }
    .areaPopup {
      position: fixed;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 16;
    }
  }
  .popupStatusCopyTo {
    display: flex;
    flex-flow: column wrap;
    position: absolute;
    overflow: auto;
    max-width: 300px;
    min-width: 100px;
    padding: 12px 0;
    background-color: #ffffff;
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.85);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    box-shadow: 0 2px 3px rgba(8, 14, 17, 0.2), 0 5px 8px rgba(69, 77, 82, 0.18);
    z-index: 16;
    .status {
      padding: 10px 20px;
      cursor: pointer;
      user-select: none;
      transition: all 200ms ease-out;
    }

    &.addStatus {
      padding: 12px 0 0 0;
    }
  }
}