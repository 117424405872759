.contentCollect {
  display: flex;
  flex-flow: column wrap;
}

.contentCollect.hiddenContent {
  display: none;
}

.rightHead {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  &.marginLeft10 {
    margin-left: 10px;
  }
}
