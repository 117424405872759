.rowPair {
    display: inline-flex;
    border-top: 1px #C6CCE4 solid;
    height: 56px;
    align-items: center;
}

.rowPairLeft {
    cursor: pointer;
    white-space: nowrap;
    max-width: 20%;
    flex: 1 1 18%;
    &:hover {
        div {
            color: #3863BB;
        }
        span {
            background-image: url("./../../components/Header/assets/openedBlue.svg");
        }
    }
}

.rowPairRight {
    max-width: 20%;
    flex: 1 1 18%;
}

.rowPairRight > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.rowPairCenter {
    align-self: center;
    flex-direction: row;
    display: flex;
    flex: 2 1 auto;
}

@media(max-width: 991px) {
    .rowPairLeft {
        padding: 10px 0 10px 10px;
    }

    .rowPairRight {
        padding: 10px  10px 10px 0;
    }
}

@media(max-width: 767px) {
    .rowPairRight > div {
        justify-content: center;
    }
}
