.dpp {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  padding-right: 10px;
}

.dateEnd {
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  align-self: stretch;
  // align-items: center;

  input {
    min-height: 40px;
    padding: 0 0 0 15px;
    border-radius: 3px;
    border: solid 1px #E0E0E0;
    background: #F0F0F0;
    &::placeholder {
      color: #ABABAB;
    }
  }
}

.labelChoice {
  display: flex;
  flex-flow: row wrap;
  align-self: center;
  min-width: 170px;
}

.dateInput {
  background: url("./assets/datePicker.svg") no-repeat center right;
}

form.oilFormField {
  display: flex;
  flex-flow: row wrap;

  input {
    margin-bottom: 0;
  }
}

.oilOldKm {
  min-width: 200px;
  margin: 0 0 35px;
}