@mixin btnInterests () {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-self: stretch;
  min-width: 100px;
  max-width: content-box;
  padding: 0 15px;
  cursor: pointer;
  color: #3863BB;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  font-size: 14px;
  line-height: 19px;
  flex-grow: 1;
  border:  solid #4A87CA 1px;

  &:hover {
    background-color: #F0F6FF;
    color: #3863BB;
    transition: all .2s ease-out;
  }
}

.interest {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: flex-start;
  padding: 35px 40px;

  align-self: stretch;
  max-width: content-box;
  min-height: 40px;
  font-size: 14px;
  line-height: 19px;

  .buttonsInterest {
    display: flex;
    flex-flow: row wrap;
    justify-content: stretch;
    align-self: stretch;
    min-height: 40px;
    max-width: 600px;
    border-radius: 100px;
    font-size: 14px;
    line-height: 19px;
    margin: 10px;

    .btnLoad {
      position: absolute;
      display: flex;
      min-width: 396px;
      min-height: 40px;
      border-radius: 100px;
      justify-content: center;
      padding: 0;
    }

    .first {
      @include btnInterests;
      border-bottom-left-radius: 100px;
      border-top-left-radius: 100px;
      border-right: 1px solid transparent;
    }

    .center {
      @include btnInterests;
    }

    .right {
      @include btnInterests;
      border-bottom-right-radius: 100px;
      border-top-right-radius: 100px;
      border-left: 1px solid transparent;
    }

    .chooseBtn, .chooseBtn:hover {
      background: #4A87CA;
      color: white;
    }
  }

  .labelChoiceInterest {
    font-size: 16px;
    line-height: 22px;
    display: flex;
    flex-flow: row wrap;
    align-self: center;
    min-width: 170px;
  }

  .selected, .selected:hover {
    color: white;
    background-color: #4A87CA;
  }

}

