@mixin paddingMargin () {
  margin: 2.5px 5px;
  border-radius: 3px;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

@mixin emptyFilter () {
  min-height: 40px;
  border: none;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  border-bottom: 1px solid #E0E0E0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background: #FFFFFF;
}

.modalMultiSelect {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 12;
}

.wrapFilter {
  margin: 5px;
  min-width: 232px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24), 0 1px 4px rgba(0, 0, 0, 0.12);

  .wrapSelected {
    background: #FFFFFF;
    color: black;
    border-radius: 3px;
    padding: 3.5px 5px;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;

    .selectedOptions {
      display: flex;
      flex-flow: row wrap;
      flex-grow: 1;
      .pointSelected {
        display: flex;
        flex-flow: row wrap;
        flex: 0 1 auto;
        background: rgba(0, 0, 0, 0.06);

        padding: 3px 8px;
        @include paddingMargin;

        &.owner {
          background-color: #EFCFFF;
        }
      }
      .pointWithoutBg {
        display: flex;
        flex-flow: row wrap;
        flex: 0 1 auto;
        padding: 3px 8px;
        color: rgba(0, 0, 0, 0.85);
        @include paddingMargin;
      }

      .countOverThree {
        font-size: 14px;
        line-height: 19px;
        color: #3863BB;
        display: flex;
        flex-flow: row wrap;
        flex: 0 1 auto;
        padding: 3px 0;
        margin: 3.5px 5px 3.5px 1px;
      }
    }
    .selectDropDownArrow {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      min-width: 20px;
      max-width: 20px;

      padding: 10px 3px;

      flex-grow: 1;
      @include paddingMargin;
    }
  }

  &.withoutShadow {
    box-shadow: none;
  }

  &.marginInSettings {
    margin: 5px 10px 5px 0;
    .wrapSelected {
      border: 1px solid #E0E0E0;
    }
    .wrapSelected:focus {
      border-color: #4A87CA;
      transition: ease-out all .2s;
    }
  }

  &.bgGrayUnder {
    .wrapSelected {
      background: #F0F0F0;
    }
  }

  &.allWidth {
    .wrapSelected {
      background: #F0F0F0;
    }
  }

  &.withoutMargins {
    margin: 0;
  }

  &.maxWidthStretch {
    flex-grow: 1;
  }

  &.light {
    .wrapSelected {
      background: white;
    }
  }

  &.borderRed {
    .wrapSelected {
      transition: all 250ms ease-out;
      border-color: #D94560;
    }
  }
}

.optionForSelection {
  margin: 3px 0;
  border-radius: 3px;
  display: flex;
  flex-flow: column wrap;
  background: #FFFFFF;
  color: black;
  position: absolute;
  min-width: 232px;
  box-shadow: 0 2px 3px rgba(8, 14, 17, 0.2), 0 5px 8px rgba(69, 77, 82, 0.18), 0 -1px 1px rgba(218, 218, 218, 0.23);
  z-index: 31;

  .changeOwner {
    cursor: pointer;
    @include emptyFilter;
    color:  #3863BB;
    transition: all .1s ease-out;
  }
  .changeOwner:hover {
    background: #F2F8FF;
    transition: all .1s ease-out;
  }

  .nothingFilter {
    @include emptyFilter;
    color: rgba(0, 0, 0, 0.33);
  }

  .stringOption {
    padding: 9px 20px;
  }
}