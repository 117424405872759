.radioBtnCollected {
  padding: 10px 30px;
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  align-items: center;

  .radioBtn {
    height: 26px;
    width: 52px;
    background: #DEDEDE;
    border-radius: 50px;
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.16);
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    .radioCircle {
      margin: 0 2px;
      height: 22px;
      width: 22px;
      border-radius: 100%;
      border: 0.5px solid rgba(0, 0, 0, 0.04);
      background: #FFFFFF;
      box-shadow: 0 3px 1px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.16), 0 3px 8px rgba(0, 0, 0, 0.15);
    }
  }

  .radioText {
    padding: 0 8px;
    color: #A99586;
    font-size: 14px;
    line-height: 19px;
    user-select: none;
    cursor: pointer;
    -moz-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
  }

  &.columnTextFirst {
    flex-flow: column wrap;
    font-style: normal;
    font-weight: normal;
    padding: 0 0 10px 0;
    .radioText {
      order: 1;
      padding: 10px;
      font-size: 16px;
      line-height: 22px;
      color: #242424;
    }
    .radioBtn {
      order: 2;
    }
  }

  &.activeUnCollect {
    .radioBtn {
      background: #82B4F2;
      justify-content: flex-end;
      align-items: center;
      transition: 0.3s;
    }

    .radioText {
      color: #242424;
    }
  }
}
