.prolongationTable {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  flex-grow: 1;

  .allTypes {
    display: flex;
    flex-flow: column nowrap;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: hidden;
    user-select: none;

    &::-webkit-scrollbar {
      height: 12px;
      background-color: rgba(0, 0, 0, 0.24);
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.24);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background-color: rgba(255, 255, 255, 0.8);
      transition: all 1s;
    }
  }

  @media(max-width: 1400px) {
    .allTypes {
      width: 100vw;
    }
  }
}
