.svHeaderRow {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}
.svHeaderRowGuests {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    height: 49px;
    background: linear-gradient(90deg, #D7EBFA 0%, #ECE6FF 100%);
    border-radius: 3px 3px 0 0;
    padding-left: 30px;
}

.paddingLeft {
   padding-left: 10px;
}

.toggleArrowRotate {
    transform: rotate(-90deg);
    transition: all .2s ease-out;
}

.fontToggle {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    font-weight: 600;
    font-size: 16px;
    color: #3863BB;

    .cars {
        font-size: 22px;
    }

    .guests {
        font-size: 16px;
        border-radius: 3px ;
    }
}
