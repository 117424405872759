.iconDel {
  width: 40px;
  height: 40px;
  padding: 0 8px;
  display: flex;
  align-self: center;
  justify-content: center;
  border: none;
  cursor: pointer;

  background: transparent url("../../../assets/delete.svg") no-repeat center center;
  transition: all .2s ease;
  &:hover {
    background: transparent url("../../../assets/deleteRed.svg") no-repeat center center;
    transition: all .2s ease;
  }
}