
.tableInspectNodes {
    width: 100%;
    padding-bottom: 45px;
    border: none;
    border-collapse: collapse;
}

.tableInspectNodes td {
    border-top: 1px solid #E0E0E0;
}

.trHead {
    height: 80px;
    color: rgba(0, 0, 0, 0.58);
    text-align: start;
}

.trHead th {
    text-align: start;
}

.trBody {
    height: 70px;
    color: rgba(0, 0, 0, 0.85);
    justify-content: stretch;
}

.thFirst {
    align-self: stretch;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    min-width: 250px;

    img {
        padding-right: 15px;
        padding-top: 5px;
        width: 27px;
    }

    span {
        padding-top: 5px;
    }
}

.thOther {
    padding-left: 25px;
    padding-right: 25px;
    flex-grow: 1;
}

.nodesFont {
    font-size: 16px;
    line-height: 22px;
}

.paddingBody {
    padding: 15px 25px 15px 45px;
}