.mileageCollectWrap {
  .clickArea {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0);
    z-index: 14;
  }

  .mileageCollect {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    .text {
      align-self: center;
      padding: 0 10px 0 23px;
    }
  }
  .buttonWrap {
    padding: 20px 20px 0 128px;
    &.withoutPaddingTop {
      padding: 0 20px 0 128px;
    }
  }
}

