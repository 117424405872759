.cardInfo {
  display: flex;
  width: 250px;
  max-width: 250px;
  height: 135px;
  margin: 15px 15px 15px 15px;
  padding: 15px 20px;
  background-color: #C9F1E0;
  color: #434343;
  box-sizing: border-box;
  border-radius: 5px;
  flex-flow: column wrap;
  .text {
    margin: 0;
    font-weight: 600;
    font-style: normal;
    &::-webkit-scrollbar {
      height: 3px;
      width: 3px;
      background-color: transparent;
      transition: .4ms ease-out all;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      transition: .4ms ease-out all;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background-color: transparent;
      transition: .4ms ease-out all;
    }

    &:hover {
      &::-webkit-scrollbar {
        height: 3px;
        background-color: #E0E0E0;
        transition: .4ms ease-out all;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        background-color: rgba(0, 0, 0, 0.16);
        transition: .4ms ease-out all;
      }
    }
  }
  .children {}
  &.maxWidth {
    max-width: 250px;
    flex-flow: column nowrap;
    .text {
      margin: 0;
      width: 220px;
      font-weight: 600;
      font-style: normal;
      overflow: auto;
      transition: .4ms ease-out all;
    }
  }
  &.minLineHeight {
    .text {
      line-height: 25px;
    }
  }
  &.nextStep {
    background: #ECC7F9;
    .text {
      font-size: 18px;
    }
  }
  &.nowStep {
    background: #6cbd9a;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.32), 0 1px 2px rgba(0, 0, 0, 0.34);
    &.red {
      background: #B64843;
      color: #FFFFFF;
    }
    &.green {
      background: #6cbd9a;
      color: #151515;
    }
    &.yellow {
      background: #EFF3A5;
      color: #434343;
    }
  }
  &.marginEmpty {
    margin: 0;
  }

  &.font16 {
    .text {
      font-size: 16px;
    }
  }
  &.minHeight {
    height: 100px;
  }
}