.prolongationTableType {
  display: flex;
  flex-flow: column wrap;
  border-bottom: 26px solid #E3E5E8;
  transition: all .1s ease-out;

  .yearLine {
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 243px;
    color: rgba(0, 0, 0, 0.55);
  }
  .columns {
    display: flex;
    flex-flow: row nowrap;
    transition: all .1s ease-out;
  }

  &.bottomBlock {
    border-bottom: none;
    margin-bottom: 40px;
  }
}

@media(max-width: 1400px) {
  .prolongationTableType {
    width: 100vw;
  }
}