.daysOfWeekForm {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 200px;

  .daysOfWeekGroup {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: stretch;

    .underCheckbox {
      z-index: 10;
      margin-top: -22px;
      display: flex;
      flex-flow: wrap column;
      justify-content: center;
      align-items: center;
      max-width: 34px;
      background: #FFFFFF;
    }
    .daysGroup {
      display: flex;
      flex-flow: row nowrap;

      .line {
        height: 24px;
        width: 182px;
        margin: 6px;
        border: 1px solid #CCD1D9;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        border-bottom: 1px solid transparent;
        display: flex;
        justify-content: center;
      }
    }
    .daysOfWeek {
      display: flex;
      flex-flow: row nowrap;
      .day {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        height: 38px;
        border: 1px solid #CCD1D9;
        color: #6B6E73;
        margin-right: -1px;
        border-right: 1px solid transparent;
        cursor: pointer;
        user-select: none;
        z-index: 2;

        &:hover {
          background-color: #F0F6FF;
          z-index: 1;
          transition: all .2s ease-out;
        }

        &.monday {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }

        &.friday {
          border-right: 1px solid #CCD1D9;
        }

        &.sunday {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          border-right: 1px solid #CCD1D9;
        }

        &.saturday {
          margin-right: -2;
        }

        &.checkedDay {
          border: 1px solid #4A87CA;
          z-index: 9;
          color: #3863BB;
        }
      }
    }
  }
  &.week {
    &.checked {
      .daysOfWeekGroup {
        .daysGroup {
          .line {
            border: 1px solid #4A87CA;
            border-bottom: 1px solid transparent;
          }
        }
        .daysOfWeek {
          .day {
            border: 1px solid #4A87CA;
            color: #3863BB;
            border-right: 1px solid transparent;

            &.friday {
              border-right: 1px solid #4A87CA;
            }
          }
        }
      }
    }

    &.fridayNot {
      .daysOfWeekGroup {
        .daysOfWeek {
          .day {
            &.friday {
              border-right: 1px solid transparent;
            }
          }
        }
      }
    }

  }

  &.weekend {
    .daysOfWeekGroup {
      .daysGroup {
        .line {
          width: 64px;
          border: 1px solid #CCD1D9;
          border-top-right-radius: 3px;
          border-top-left-radius: 3px;
          border-bottom: 1px solid transparent;
        }
      }
      .daysOfWeek {
        .day {
          &.saturday {
            margin-left: -1px;
            border-left: 1px solid transparent;
          }

          &.checkedDay {
            border: 1px solid #4A87CA;
            z-index: 9;
            color: #3863BB;
          }
        }
      }
    }
    &.checked {
    .daysOfWeekGroup {
      .daysGroup {
        .line {
          border: 1px solid #4A87CA;
          border-bottom: 1px solid transparent;
        }
      }
      .daysOfWeek {
        .day {
          border: 1px solid #4A87CA;
          color: #3863BB;
          border-right: 1px solid transparent;
          &.sunday {
            border-right: 1px solid #4A87CA;
          }
        }
      }
    }
    }
  }

}