.connection {
  display: flex;
  flex-flow: column wrap;
  padding: 32px 28px;
  border-right: solid 1px #CCD1D9;
  max-width: 166px;
  min-width: 166px;
  width: 166px;

  .connectionHeader {
    display: block;
    padding-bottom: 18px;
    font-size: 16px;
    line-height: 22px;
  }

  &.andPosition {
    border-right: transparent;
  }
}