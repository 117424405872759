
.formSelect label {
  color: rgba(0, 0, 0, 0.58);
  font-size: 12px;
  line-height: 16px;
  padding-left: 10px;
}

.formSelect select {
  display: flex;
  align-self: center;
  height: 40px;
  border: 1px solid #E0E0E0;
  margin: 5px 5px 5px 10px;
  color: #262626;
  min-width: 233px;
  font-size: 16px;
  border-radius: 3px;
  padding: 5px 15px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: white url("../../assets/arrow-down.png") no-repeat 95% 50%;
}

table .formSelect {
  box-shadow: none;
}
