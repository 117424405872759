.carBasicBody {
    display: flex;
    flex-flow: row wrap;
    justify-content: stretch;
    align-items: stretch;
    flex: 1 1 0;
    min-width: min-content;
}

.carBasicBody div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
    padding: 0 15px;
    height: 40px;
    color: #262626;
    background: white;
    border: 1px solid #E0E0E0;
    border-radius: 3px;
}

.mark, .model {
    min-width: 150px;
}