.carWrap {
  display: flex;
  flex-flow: row nowrap;
  height: 27px;
  align-items: stretch;
  justify-content: stretch;
  transition: all 0.2ms;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  &.darkTheme {
    color: #f1f1f1;
  }

  &.darkText {
    color: #262626;
  }

  &:hover {
    transition: all 0.2ms;
    text-decoration: underline;
  }

  &.withoutLink {
    &:hover {
      text-decoration: none;
    }
  }

  .carMark {
    display: flex;
    align-items: center;
  }
}