.connectionRowCheckBoxes {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  .order {
    text-align: center;
    width: 19px;
    font-size: 14px;
    color: #949DA8;
  }

  .arrows {
    padding: 0 16px 0 0;
  }

  .arrow {
    display: block;
    cursor: pointer;
    height: 8px;
    margin: 1px 0;
    width: 17px;
  }

  .arrow:hover {
    color: #1a6dca;
  }
}