.menuUp {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    height: 37px;
    padding: 0;
}

.menuUp > li {
    padding: 0 15px;
    border-right: 1px solid #C6CCE4;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.85);
    &:last-child {
        border-right: none;
    }

}

.liDisabled {
    a {
        color: #A4A7A9;
    }
}

a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.85);
}
a:hover {
    color: #3863BB;
}

.liSelected {
    a {
        color: #3863BB;
        border-bottom: 3px solid #4A87CA;
    }
}


@media(max-width: 767px) {
    .menuUp {
        flex-flow: row nowrap;
        font-size: 12px;
        justify-content: center;
        align-items: center;
    }
    .brdRght {
        border-right: none;
    }
}
