.carBasicInfoBottom {
  display: flex;
  flex-flow: column wrap;
  justify-content: stretch;
  align-items: stretch;
  background-color: #F3F3F3;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24), 0 1px 4px rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.imgItemAdd {
  display: flex;
  align-self: center;
  overflow: hidden;
  height: 80px;
  width: 110px;
  border-radius: 3px;
  background: darkgray;

  label {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-self: center;
    justify-content: center;
    cursor: pointer;
    background: url('assets/plus.svg') center no-repeat;
    background-size: 28px;
  }

  #submitPhoto {
    display: flex;
    height: content-box;
    width: auto;
  }
}

.marginInput {
}

.addBtn {
  visibility: visible;
  height: 80px;
  width: 110px;
  margin: 0 0 0 10px;
  border-radius: 3px;
  background: white;
}

.delBtn {
  visibility: hidden;
}

figure {
  margin: 0;
}

.imgArray {
  position: relative;
  background-color: #CFCFCF;
  height: 100px;
  padding: 0 95px 0 215px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  .side {
    position: absolute;
    top: 0;
    height: 100%;
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }
  .arrow {
    width: 85px;
    height: 100%;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    &:before {
      content: '';
      height: 100%;
      display: inline-block;
      vertical-align: middle;
    }
    &.end {
      background: rgba(0, 0, 0, 0.07);
      padding-right: 10px;
    }
  }
  .inputsAdd {
    width: 110px;
    height: 100%;
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
    padding: 10px 0;
    box-sizing: border-box;
    .inputHidden {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
  }
  .imagesContainer {
    position: relative;
    white-space: nowrap;
    height: 100%;
    overflow: hidden;
    .images {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      transition: left .2s ease;
    }
    .imgItem {
      position: relative;
      height: 80px;
      width: 110px;
      display: inline-block;
      vertical-align: top;
      border-radius: 3px;
      margin: 10px 0;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        object-fit: cover;
      }
    }
    .imgItem + .imgItem {
      margin-left: 10px;
    }
  }
}