.blockWrap {
  display: flex;
  flex-flow: column wrap;
  justify-content: stretch;
  align-items: stretch;
  margin: 32px 50px;
  border-radius: 3px 3px 0 0;

  .btnLoad {
    position: absolute;
    display: flex;
    top:0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    justify-content: center;
    padding: 0;
  }
}
