.listServiceBooks {
    background-color: #FFFFFF;
    color: #373a3c;
    padding: 40px 50px 0 50px;
    display: flex;
    flex-flow: column wrap;
    align-items: stretch;
    justify-content: stretch;
}

.wrapCarsCategory {
    display: flex;
    flex-flow: column wrap;
    justify-content: stretch;
    align-items: stretch;
    &.paddingBottom {
        padding-bottom: 50px;
    }
}
.svCarsList {
    padding: 25px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;

    &.carsListVisitor {
        padding: 0 0 25px 0;
    }
}

.withButton {
    display: flex;
    flex-flow: wrap row;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
}

.notFound {
    font-size: 16px;
    padding: 15px 0 42px 0;
    display: flex;
    flex-flow: row wrap;
    max-width: 600px;
    color: rgba(0, 0, 0, 0.58);
}
