.text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #434343;
  background-color: transparent;
  display: flex;
  flex-flow: row wrap;

  &.h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }

  &.maxWidth200 {
    max-width: 130px;
  }
}