.monthsSelectWrap {
  display: block;
  width: 195px;
  padding: 10px 0;
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin: 43px 0 2px 0;
  background: #FFFFFF;
  color: black;
  position: absolute;
  z-index: 14;
  height: 300px;
  overflow-y: auto;

  &.noPadding {
    padding: 0;
  }

  &.width183 {
    width: 183px;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }
  .allTypes::-webkit-scrollbar-track {
    background-color: #E0E0E0;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: rgba(0, 0, 0, 0.16);
  }

  &.minHeight {
    height: min-content;
  }
  .options {
    display: flex;
    position: relative;
    flex-flow: row nowrap;
    min-width: 150px;
    flex: 0 0;
    justify-content: stretch;
    align-items: stretch;
    & > div {
      flex-grow: 1;

      &.scrollY {
        height: 300px;
        overflow-y: auto;
        &:first-child {
          border-right: 1px solid #E2E6EB;
        }
      }
      &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
      }
      .allTypes::-webkit-scrollbar-track {
        background-color: #E0E0E0;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        background-color: rgba(0, 0, 0, 0.16);
      }

      &.minHeight {
        height: min-content;
      }
    }
  }
}

.modalSelect {
  position: fixed;
  background-color: transparent;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 12;
}
