.buttonsCreate {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: stretch;
  margin-right: 10px;

  &.margin10_0 {
    margin: 10px 0;
  }
}

.notice {
  display: flex;
  align-self: center;
  justify-content: flex-start;
  padding: 5px 15px 5px 0;
}