.dateInput {
  font-size: 16px;
  line-height: 22px;
  font-family: 'Open Sans', sans-serif;
  width: 184px;
  height: 40px;
  background-color: #F0F0F0;
  border: 1px solid #E0E0E0;
  border-radius: 3px;
  display: flex;
  padding: 0;
  justify-content: stretch;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-out;

  .span {
    padding-left: 15px;
    display: flex;
    align-self: stretch;
    justify-self: stretch;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
  }

  .imgDateInput {
    display: flex;
    flex-flow: wrap row;
    align-self: stretch;
    justify-self: stretch;
    align-items: center;
    justify-content: center;
    min-width: 47px;
    box-sizing: border-box;
  }
  .paddingAround {
    padding: 0 5px;
  }
  &:focus {
    border-color: #1a6dca;
  }

  &.disabled {
    color: #808891;
    &:focus {
      border-color: #E0E0E0;
    }
  }

  &.sizeMore {
    width: 200px;
  }

  &.redBorder {
    border-color: #D94560;
  }

  &.rangeReport {
    width: 135px;
    color: #262626;
    background: #FFFFFF;
    font-size: 14px;
    line-height: 19px;

    .imgDateInput {
      min-width: 37px;
    }
  }

  &.left {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    &:focus {
      border-color: #CCD1D9;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &.right {
    border-left: 1px solid transparent;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    &:focus {
      border-color: #CCD1D9;
      border-left: 1px solid transparent;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }

  &.blueBorder {
    border-color: #4A87CA;
    &.right, &.left {
      border-radius: 3px;
      &:focus {
        border-color: #4A87CA;
      }
    }
    &.left {
      margin-right: 5px;
    }
  }

  &.warningColor {
    color: #C76F0A;
    border-color: #D9930B;
  }
}

.popperClass {
  z-index: 10;
}
