.carAndVisitorInfo {
  // padding-left: 15px;
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  &.listLabel {
    & p {
      font-size: 18px;
      font-weight: 600;
      color: white;
      display: flex;
      flex-flow: row wrap;
      &:hover {
        cursor: pointer;
        border-bottom: 1px solid rgba(255, 255, 255, 1);
        margin-bottom: 4px;
      }
    }
    a {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
    }
    &.span {
      font-size: 18px;
      font-weight: 600;
      color: white;
      display: flex;
      flex-flow: row wrap;
    }
  }
}