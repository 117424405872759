
.tireSection {
  display: flex;
  flex-flow: column wrap;
  padding: 0 10px 15px;
  flex-grow: 2;

  .labelTire {
    display: flex;
    flex-flow: row wrap;
    align-self: flex-start;
    min-width: 170px;
    padding-bottom: 10px;
  }

  .labelBtnTire {
    display: flex;
    flex-flow: row wrap;
    align-self: flex-start;
    min-width: 170px;
    padding: 25px 0;
    font-style: oblique;
  }

  .tireChildSection {
    display: flex;
    flex-flow: column wrap;
    padding-bottom: 25px;
    flex-grow: 2;

    .tireSeason {
      display: flex;
      flex-flow: column wrap;
      align-items: flex-start;
      justify-content: center;
      margin: 5px;
      border: solid #4A87CA 1px;
      color: #3863BB;
      min-height: 40px;
      padding: 0 15px;
      min-width: 100px;
      max-width: 200px;
      background: transparent;
      font-size: 14px;
    }

    .tireSeason:hover {
      background: #4A87CA;
      color: white;
    }

  }
}