.greenBtn {
    background-color: #1CBC8C;
    height: 40px;
    color: white;
    width: 222px;
    position: center;
    text-align: center;
    align-self: center;
    border: none;
    font-size: 16px;
    line-height: 22px;
    border-radius: 3px;
}