.leftHead {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  .roundMarkOrange {
    height: 26px;
    width: 26px;
    background: transparent url('../../assets/marckOrange.svg') no-repeat center center;
  }

  .roundMarkRed {
    height: 26px;
    width: 26px;
    background: transparent url('../../assets/marckRed.svg') no-repeat center center;
  }

  .roundMarkGreen {
    height: 26px;
    width: 26px;
    background: transparent url('../../assets/marckGreen.svg') no-repeat center center;
  }

  .textHeader {
    padding-left: 15px;
    /*font-size: 30px;
    line-height: 41px;*/
    color: #434343;
  }
}