

.formOilEveryMileage {
  display: flex;
  flex-flow: row wrap;
  align-self: flex-end;
  align-items: center;

  .oilCycleKm {
    min-width: 230px;
    max-width: 235px;
    margin: 0 15px 0 0;
    font-size: 16px;
    line-height: 22px;
    height: 40px;
    box-sizing: border-box;
    background: #F0F0F0;
    border-radius: 3px;
    border: 1px solid #E0E0E0;
    padding: 0 15px;
    transition: ease-out all .2s;

    &:focus {
      border-color: #4A87CA;
      transition: ease-out all .2s;
    }

    &.redBorder {
      border-color: #D94560;
      transition: ease-out all .2s;
    }
  }
}
