.selectBoxContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  align-self: stretch;
  align-items: stretch;
  padding: 0;
  margin: 0;
  max-width: 350px;

  .selectField {
    display: flex;
    flex-flow: wrap row;
    justify-content: center;
    align-content: center;
    align-self: stretch;
    min-width: 352px;
    height: 43px;
    border: 1px solid #E0E0E0;
    color: #262626;
    font-size: 16px;
    line-height: 22px;
    padding: 0 10px;

    border-radius: 3px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: white url("../../assets/arrow-down.png") no-repeat 95% 50%;
  }

  &.backgroundGray {
    .selectField {
      background-color: #F0F0F0;
      &:focus {
        border-color: #4A87CA;
        transition: ease-out all .2s;
      }
    }
  }

  &.modalSelect {
    .selectField {
      min-width: 350px;
    }
  }

  &.maxWidth257 {
    max-width: 257px;
    .selectField {
      max-width: 257px;
      min-width: 257px;
    }
  }
}


@media(max-width: 720px) {
  .selectBoxContainer {
    &.modalSelect {
      min-width: auto;
      .selectField {
        min-width: auto;
      }
    }
  }
}