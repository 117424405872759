.prereserve {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: stretch;
  z-index: 1;

  & > div {
    padding-left: 25px;
    min-height: 60px;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    border-bottom: 1px #5d89f8 solid;
  }

  .sort {
    width: 10px;
    height: 13px;
    padding: 5px;
    display: flex;
    flex-flow: row wrap;
    color: #FFFFFF;
    &.sortAsk {
      transform: rotate(180deg);
    }
    &.sortDesk {
      transform: rotate(0);
    }
  }

  .sortStyle {
    cursor: pointer;
    text-decoration: underline dotted;
    position: relative;

    .clearSort {
      display: none;
      transition: all ease-out .5s;
    }
    &:hover {
      transition: all ease-out .5s;
      .clearSort {
        transition: all ease-out .5s;
        display: flex;
        flex-flow: wrap column;
        padding: 20px 0 10px 0;
        position: absolute;
        box-sizing: border-box;
        top: 60px;
        left: 60px;
        background: #FFFFFF;
        min-width: 250px;
        min-height: 50px;
        border-radius: 4px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        & > div {
          padding: 5px 15px;
          &:hover {
            background-color: #EBF5FF;
            color: #3863BB;
            border-radius: 4px;
          }
        }
        .red {
          color: #D94560;
          &:hover {
            color: #af3047;
          }
        }
        .select {
          background-color: #EBF5FF;
          &:hover {
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }
    }
  }

  .id {
    min-width: 50px;
  }
  .date2 {
    width: 235px;
    min-width: 235px;
  }
  .date {
    width: 90px;
    min-width: 90px;
  }

  .car {
    width: 150px;
    min-width: 150px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    .text {
      display: flex;
      flex-flow: row wrap;
      max-width: 150px;
    }
  }

  .carNumber {
    width: 150px;
    min-width: 150px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    .text {
      display: flex;
      flex-flow: row wrap;
      max-width: 150px;
    }
  }

  .other {
    width: 110px;
    min-width: 110px;
  }
  .owner {
    min-width: 110px;
    flex-grow: 1;
  }

  &.prereserveHeader {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: stretch;
    background-color: #E3EAFC;
    color: rgba(0, 0, 0, 0.95);
    min-height: 80px;
    flex-flow: row nowrap;
    & > div {
      padding-left: 25px;
      min-height: 50px;
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
      border-bottom: 1px transparent solid;
    }
    .dateHeader {
      flex-flow: column wrap;
      align-items: stretch;
      justify-content: stretch;
      padding-left: 0;
      min-width: 260px;
      .dateHeaderHeader {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        padding-left: 25px;
        justify-content: flex-start;
        align-self: stretch;
        justify-self: stretch;
        flex-grow: 1;
      }
      .dateHeaderRow {
        display: flex;
        flex-flow: row nowrap;
        height: 40px;
        & > div {
          padding-left: 25px;
          display: flex;
          height: 40px;
          align-items: center;
          justify-content: flex-start;
          min-width: 115px;
          flex-grow: 1;
        }
      }
    }
  }
}
