
.blue {
  color: #3863BB;
}

.bold {
  font-weight:bold;
}

.app {
  display: flex;
  flex-flow: column wrap;
  align-items: stretch;
  align-self: stretch;
  justify-self: stretch;
/*  flex: 1 1;
  min-height: 100vh;*/
  flex: 1 1 content-box;
  min-height: 100%;
}

input:focus {
  border-color: #4A87CA;
  border-radius: 3px;
  transition: all .2s ease;
}

select:focus {
  border-color: #4A87CA;
  border-radius: 3px;
  transition: all .2s ease;
}


/*-------------------Color Text----------------------*/
.colorTextRed {
  color: #E15173;
}

.colorTextGreen {
  color: #1CBC8C;
}

.colorTextGrey {
  color: #AAA9CF;
}/*-------------------Color Text----------------------*/

/*-----------------Arrow in filter: down------------------*/


.triangle-down-b {
  position: relative;
  top: -2px;
  content: "";
  display: inline-block;
  width: 9px;
  height: 9px;
  border-right: 0.2em solid snow;
  border-top: 0.2em solid snow;
  transform: rotate(135deg);
  margin-right: 0.5em;
  margin-left: 1.0em;
}/*-----------------------------------*/

/*------------------Arrow in filter: up-----------------*/
.triangle_up1 {
  position: relative;
  top: 2px;
  content: "";
  display: inline-block;
  width: 7px;
  height: 7px;
  border-right: 0.1em solid snow;
  border-top: 0.1em solid snow;
  transform: rotate(-45deg);
  margin-right: 0.5em;
  margin-left: 1.0em;
}

.triangleUp2B {
  position: relative;
  top: 2px;
  content: "";
  display: inline-block;
  width: 9px;
  height: 9px;
  border-right: 0.2em solid snow;
  border-top: 0.2em solid snow;
  transform: rotate(-45deg);
  margin-right: 0.5em;
  margin-left: 1.0em;
}
/*-----------------------------------*/
.arrowLeftMiddle {
  position: relative;
  top: -2px;
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-right: 0.2em solid snow;
  border-top: 0.2em solid snow;
  transform: rotate(-135deg);
  margin-right: 0.5em;
  margin-left: 1.0em;
}

.arrowRightMiddle {
  position: relative;
  top: -2px;
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-right: 0.2em solid snow;
  border-top: 0.2em solid snow;
  transform: rotate(45deg);
  margin-right: 0.5em;
  margin-left: 1.0em;
}