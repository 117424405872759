.prereserve {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: stretch;
  z-index: 1;

  & > div {
    padding-left: 25px;
    min-height: 60px;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    border-bottom: 1px #5d89f8 solid;
  }
  .id {
    min-width: 50px;
  }

  .date {
    width: 190px;
    min-width: 190px;
    &.column {
      display: flex;
      flex-flow: column wrap;
      align-items: flex-start;
      justify-content: center;
    }
  }

  .car {
    width: 150px;
    min-width: 150px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    .text {
      display: flex;
      flex-flow: row wrap;
      max-width: 150px;
    }
  }
  .other {
    width: 110px;
    min-width: 110px;
  }
  .channel {
    width: 220px;
    min-width: 220px;
    padding: 10px;
  }
  .action {
    min-width: 110px;
    flex-grow: 1;
  }

  &.prereserveHeader {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: stretch;
    background-color: #E3EAFC;
    color: rgba(0, 0, 0, 0.95);
    min-height: 80px;
    flex-flow: row nowrap;
    & > div {
      padding-left: 25px;
      min-height: 50px;
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
      border-bottom: 1px transparent solid;
    }
    .dateHeader {
      flex-flow: column wrap;
      align-items: stretch;
      justify-content: stretch;
      padding-left: 0;
      min-width: 260px;
      .dateHeaderHeader {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        padding-left: 25px;
        justify-content: flex-start;
        align-self: stretch;
        justify-self: stretch;
        flex-grow: 1;
      }
      .dateHeaderRow {
        display: flex;
        flex-flow: row nowrap;
        height: 40px;
        & > div {
          padding-left: 25px;
          display: flex;
          height: 40px;
          align-items: center;
          justify-content: flex-start;
          min-width: 115px;
          flex-grow: 1;
        }
      }
    }
  }
}
