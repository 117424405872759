.cardShInspBody {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: stretch;
    background-color: #F3F3F3;
    padding-bottom: 20px;
    border-radius: 0 0 3px 3px;
}

.bNodes {
    color: #242424;
}