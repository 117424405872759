.btnOilSmall {
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  align-self: stretch;
  align-items: stretch;
  max-width: 250px;
  min-height: 40px;
  margin: 15px 0 20px;
  flex-shrink: 0;

  .textBefore {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 2;
  }
}
