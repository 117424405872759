.sheetCollectBody {
  display: flex;
  flex-flow: wrap column;
  min-width: 1024px;
}
.noMileageDataMessage {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}

.collectWrap {
  background-color: white;
  margin: 10px 8px;
  color: #1f2021;
  border-radius: 3px;
  display: flex;
  flex-flow: column wrap;
  font-size: 16px;
  line-height: 20px;
  &.forSvBook {
    margin: 0;
  }
  &.marginBottom130 {
    margin-bottom: 130px;
  }
}

.headAnchor {
  min-height: 103px;
  background: linear-gradient(95.33deg, #FFEED4 0%, #F8CCBA 100%);
  border-radius: 3px 3px 0 0;
  display: flex;
  flex-flow: row wrap;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;

  .rightHead {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
  }
}

.headSecretary {
  background: linear-gradient(90deg, #DFF7E5 0%, #C2F0DF 100%), linear-gradient(90deg, #D7EEFF 0%, #CED6FE 100%);
}

.headService {
  background: linear-gradient(95.33deg, #FFE7F8 0%, #FBCED9 100%);
}

.headSettings {
  background: #55539F;
}

.headMileage {
  background: linear-gradient(90deg, #D7EEFF 0%, #CED6FE 100%);
}

.arrowDown  {
  position: relative;
  top: -2px;
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-right: 0.2em solid #434343;
  border-top: 0.2em solid #434343;
  transform: rotate(135deg);
  margin-right: 0.5em;
  margin-left: 1.0em;
}

.arrowUp {
  position: relative;
  top: -2px;
  content: "";
  display: inline-block;
  width: 9px;
  height: 9px;
  border-right: 0.2em solid #434343;
  border-top: 0.2em solid #434343;
  transform: rotate(45deg);
  margin-right: 0.5em;
  margin-left: 1.0em;
}

.radioBtnCollected {
  padding: 10px 30px;
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  align-items: center;

}

.contentCollect {
  display: flex;
  flex-flow: column wrap;
}

.contentCollect.hiddenContent {
  display: none;
}

.blockAnchors {
  display: flex;
  flex-flow: column wrap;
  justify-content: stretch;
  align-items: stretch;
  margin: 36px 50px;
  border: solid 1px #E8E8E8;
  border-radius: 3px;

  &:first-child {
    margin-top: 50px;
  }

  &:last-child {
    margin-bottom: 50px;
  }

  .wrapBodySubCollect {
    display: flex;
    flex-flow: column wrap;
    justify-content: stretch;
    align-self: stretch;
  }

  .fields {
    display: flex;
    flex-flow: column wrap;
    align-items: stretch;
    justify-content: stretch;
    padding: 50px 40px 0 40px;
    .hasCasco {
      padding-bottom: 35px;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      & > label {
        display: flex;
        max-width: 140px;
        width: 140px;
      }
    }

    form {
      padding-bottom: 35px;

      label {
        display: flex;
        align-items: center;
        justify-content: stretch;
      }
    }

    input {
      min-height: 40px;
      padding-left: 15px;
      border-radius: 3px;
      border: solid 1px #E0E0E0;
      background: #F0F0F0;
      margin-right: 15px;
    }
  }
}


.rowGrow {
  display: flex;
  flex-flow: wrap row;
  align-items: stretch;
  justify-content: stretch;

  .column {
    display: flex;
    flex-flow: wrap column;
    align-items: stretch;
    justify-content: stretch;
    flex-grow: 1;
    transition: ease-out all .2s;
    min-width: 100px;
    box-sizing: border-box;
    &.borderGrey {
      border-left: 1px solid #EEEEEE;
      border-collapse: collapse;
    }
    &.paddings {
      padding: 19px 12px 30px 40px;
    }
    &.margin15 {
      margin: 15px 0;
    }
    &.alignCenter {
      align-items: center;
    }
    &.justifyCenter {
      justify-content: center;
    }
    &.margin10 {
      margin: 10px 0;
    }
    &.margin8 {
      margin: 8px 0 0 0;
    }
    &.half {
      flex-basis: 25%;
    }
    &.maxWidth512 {
      max-width: 512px;
    }
  }

  .row {
    display: flex;
    flex-flow: wrap row;
    align-items: stretch;
    justify-content: stretch;
    &.around {
      justify-content: space-around;
    }
    &.paddingTop20 {
      padding-top: 20px;
    }
    &.paddingRight35 {
      padding-right: 35px;
    }
    &.margin15 {
      margin: 15px 0;
    }
    &.marginBottom15 {
      margin: 0 0 15px 0;
    }
    &.center {
      align-items: center;
    }
  }

  &.borderGrey {
    border-bottom: 1px solid #EEEEEE;
    border-top: 1px solid #EEEEEE;
    border-collapse: collapse;
  }
}



.paragraph {
  border-top: 1px solid #E0E0E0;
}

.paragraph2 {
}

.inputCheckbox {
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  align-self: stretch;
}
.cost {
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  align-self: stretch;
  padding-bottom: 35px;
  height: 40px;

  .label {
    align-items: center;
    display: flex;
    width: 170px;
  }
  .inputCost {
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: stretch;
    border-radius: 3px;
    padding: 0 15px 0 15px;
    border: 1px solid #E0E0E0;
    background-color: #F0F0F0;
    width: 184px;
    flex: 0 0 auto;
    transition: ease-out all 0.2s;

    &.focusSet {
      border-color: #1a6dca;
    }

    .wrapInput {
      padding: 0;
      flex-grow: 1;
      .cost {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-color: transparent;
        width: 132px;
        max-width: 132px;
        flex-grow: 1;
        margin: 0;
        padding: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
      }
    }

    .icon {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-color: transparent;
      padding-left: 6px;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }

}

.dateEnd {
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  align-self: stretch;

  label {
    display: flex;
    align-items: center;

    .forCheckBox {
      color: #3863BB;
      font-size: 14px;
      line-height: 19px;
    }
  }

  div.checkBox {
    margin-left: 15px;
    padding-bottom: 35px;
  }
}

.insurance {
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  align-self: stretch;

  select {
    padding: 0 10px;
    height: 40px;
    min-width: 350px;
    background: #F0F0F0;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 3px;
  }
}

.labelChoice {
  display: flex;
  flex-flow: row wrap;
  align-self: center;
  min-width: 170px;
}

.labelSelect {
  min-width: 170px;
}

.checkBox {
  height: 22px;
  width: 22px;
  margin-right: 8px;
  border-radius: 3px;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  border: #4A87CA solid 1px;
}

.checkBoxMarked {
  height: 22px;
  width: 22px;
  margin-right: 15px;
  border-radius: 3px;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  border: #4A87CA solid 1px;
  transition: 0.3s;
  background: transparent url('../../../assets/assets/marked.svg') no-repeat center center;
}

.lines {
  border-top: 1px solid #E0E0E0;
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  align-self: stretch;
  align-items: center;
  padding: 35px 73px 35px 73px;

  input {
    min-height: 40px;
    padding: 0 0 0 15px;
    border-radius: 3px;
    min-width: 170px;
    max-width: 270px;
    border: solid 1px #E0E0E0;
    background: #F0F0F0;
    font-size: 14px;
    &::placeholder {
      color: #ABABAB;
    }
  }

  .oilChoice {
    display: flex;
    flex-flow: row wrap;
    align-self: center;
    min-width: 170px;
  }

}

.lineMonths {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  max-width: 238px;
}

.oilCycleKm {
  min-width: 30px;
  max-width: 70px;
  margin: 0 15px;
}

.months {
  padding-right: 15px;

  select {
    padding: 0 10px;
    height: 40px;
    min-width: 100px;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 3px;
    appearance: none;
    background: #F0F0F0 url("assets/arrowDownSelect.svg") no-repeat 90% 50%;
    font-size: 14px;
    line-height: 19px;
  }
}

.btnTire {
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  align-self: stretch;
  max-width: 350px;
  min-height: 40px;
  font-size: 14px;
  line-height: 19px;
  margin: 15px 0 35px;

  .firstOil {
    border-bottom-left-radius: 100px;
    border-top-left-radius: 100px;
    border: solid #4A87CA 1px;
    border-right: 1px solid transparent;
    font-size: 14px;
    line-height: 19px;
    flex-grow: 2;
  }

  .secondOil {
    border-bottom-right-radius: 100px;
    border-top-right-radius: 100px;
    border:  solid #4A87CA 1px;
    font-size: 14px;
    line-height: 19px;
    flex-grow: 2;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3863BB;
  }

  div:hover {
    background: #4A87CA;
    color: white;
  }

}


/* кнопки для цикличности замены масла */
.btnOil {
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  align-self: stretch;
  max-width: 250px;
  min-height: 40px;
  font-size: 14px;
  line-height: 19px;
  margin: 15px 0 35px;

  .firstOil {
    border-bottom-left-radius: 100px;
    border-top-left-radius: 100px;
    border: solid #4A87CA 1px;
    border-right: 1px solid transparent;
    font-size: 14px;
    line-height: 19px;
    flex-grow: 2;
  }

  .secondOil {
    border-bottom-right-radius: 100px;
    border-top-right-radius: 100px;
    border:  solid #4A87CA 1px;
    font-size: 14px;
    line-height: 19px;
    flex-grow: 2;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3863BB;
  }

}
/* ------END -----кнопки для цикличности замены масла */

/* кнопки для цикличности замены масла */
.btnOilSmall {
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  align-self: stretch;
  align-items: stretch;
  max-width: 250px;
  min-height: 40px;
  font-size: 14px;
  line-height: 19px;
  margin: 15px 0 20px;


  span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 2;
  }

  .btnSmall {
    display: flex;
    color: #3863BB;
    flex-grow: 3;
    min-width: 180px;
    max-width: 180px;

    .firstOil {
      border-bottom-left-radius: 100px;
      border-top-left-radius: 100px;
      border: solid #4A87CA 1px;
      border-right: 1px solid transparent;
      font-size: 14px;
      line-height: 19px;
      flex-grow: 4;
    }

    .secondOil {
      border-bottom-right-radius: 100px;
      border-top-right-radius: 100px;
      border:  solid #4A87CA 1px;
      font-size: 14px;
      line-height: 19px;
      flex-grow: 2;
    }

    div {
      align-items: center;
      justify-content: center;
      display: flex;

      min-height: 40px;
    }
  }

}
/* ------END -----кнопки для цикличности замены масла */

.oilOldKm {
  min-width: 200px;
  margin: 0 0 35px;
}

.blueSpan {
  padding: 20px 0;
  text-decoration: underline;
  color: #1a6dca;
  cursor: pointer;
}

.oilInf {
  display: flex;
  flex-flow: row nowrap;

  form {
    padding-bottom: 35px;

    label {
      display: flex;
      align-items: center;
      justify-content: stretch;
    }
  }

  input {
    // height: 40px;
    padding-left: 15px;
    border-radius: 3px;
    border: solid 1px #E0E0E0;
    background: #F0F0F0;
    margin-right: 15px;
  }

  .nextTimeOil {
    padding: 20px 0;
    color: darkgray;
  }


  /* ToDo */
  .oilSection {
    display: flex;
    flex-flow: column wrap;
    flex: 1 1 0;
    min-width: 250px;
    padding: 20px;

    align-self: stretch;
    justify-self: stretch;
    justify-content: flex-start;
    align-content: center;
    -webkit-align-content: center;
    border-right: 1px solid #EEEEEE;

    .labelOilCycle {
      display: flex;
      flex-flow: row wrap;
      align-self: flex-start;
      // min-width: 170px;
      margin: 0;
      padding: 25px 0;
      font-size: 18px;
      line-height: 25px;
      color: #434343;

      &.paddingMessage {
        padding: 25px 0 0 0;
      }
    }

    .labelOilNonActive {
      display: flex;
      margin: 0;
      flex-flow: row wrap;
      align-self: flex-start;
      min-width: 170px;
      color: darkgray;
      padding: 25px 0;
      font-size: 18px;
      line-height: 25px;
    }
    &.positionStart {
      flex: 0 1;
      border-right: transparent;
      padding-left: 40px;
    }
  }
}

.oilCenter {
  -webkit-order: 4;
  order: 4
}

.oilRight {
  -webkit-order: 2;
  order: 2;
}

.tireSection {
  display: flex;
  flex-flow: column nowrap;
  padding: 0 45px 10px 45px;
  justify-content: flex-start;
  border-right: 1px solid #EEEEEE;

  .pairSeason {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
  }

  .labelTire {
    display: flex;
    flex-flow: row nowrap;
    align-self: flex-start;
    align-items: center;
    min-width: 170px;

    .navLinks {
      text-decoration: underline;
      color: #3863BB;

      &.textDisable {
        color: #3863BB;
        margin-left: 5px;
      }

      &:hover {
        color: #3863BB;
        background-color: rgba(74, 135, 202, 0.1);
        transition: all .1s ease-out;
      }
    }

    .textDisable {
      padding: 0 10px;
      min-height: 40px;
      color: #949DA8;
      display: flex;
      align-items: center;
      transition: all .2s ease-out;
      border-radius: 3px;

    }

    .seasonTiresImg {
      height: 17px;
      margin-right: 6px;
    }
  }

  .labelBtnTire {
    display: flex;
    flex-flow: row wrap;
    align-self: flex-start;
    margin: 0;
    color: #434343;
    min-width: 170px;
    padding: 25px 0;
    font-size: 18px;
    line-height: 25px;

    &.paddingMessage {
      padding: 25px 0 0 0;
    }
  }

  .tireChildSection {
    display: flex;
    flex-flow: column wrap;
    padding-bottom: 25px;

    .tireSeason {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: flex-start;
      margin: 5px 5px 5px 0;
      border: solid #4A87CA 1px;
      border-radius: 3px;
      color: #3863BB;
      min-height: 40px;
      padding: 0 15px;
      min-width: 200px;
      max-width: 245px;
      width: 245px;
      white-space: normal;
      background: transparent;
      font-size: 14px;
      line-height: 19px;
      -webkit-transition-property: all;
      -moz-transition-property: all;
      -o-transition-property: all;
      transition-property: all;
      -webkit-transition-duration: 0.3s;
      -moz-transition-duration: 0.3s;
      -o-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -moz-user-select: none;
      -khtml-user-select: none;
      user-select: none;
      cursor: pointer;

      &.redBorder {
        border-color: #D94560;
      }
    }

    .tireSeason:hover {
      background: #F0F6FF;;
      color: #3863BB;
      -webkit-transition-property: all;
      -moz-transition-property: all;
      -o-transition-property: all;
      transition-property: all;
      -webkit-transition-duration: 0.3s;
      -moz-transition-duration: 0.3s;
      -o-transition-duration: 0.3s;
      transition-duration: 0.3s;
    }

    .tireSeasonSelect {
      background: #4A87CA;
      color: white;
      &:hover {
        background: #4A87CA;
        color: white;
      }
    }

  }

  &.tireSeasons {
    flex: 2 1 0;
  }
}

.secretaryCallTime {
  display: flex;
  flex-flow: column wrap;
  justify-content: stretch;
  align-items: stretch;
  margin: 50px;
  border: solid 1px #E8E8E8;
  border-radius: 3px 3px 0 0;

  .wrapBodySubCollect {
    display: flex;
    flex-flow: column wrap;
    justify-content: stretch;
    align-self: stretch;
  }

}

/**** Secretary button overall ****/

.btnOverall {
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  align-self: stretch;
  max-width: 350px;
  min-height: 40px;
  font-size: 14px;
  line-height: 19px;
  margin: 0 0 35px;

  .first {
    border-bottom-left-radius: 100px;
    border-top-left-radius: 100px;
    border: solid #4A87CA 1px;
    border-right: 1px solid transparent;
    font-size: 14px;
    line-height: 19px;
    flex-grow: 2;
  }

  .second {
    border-bottom-right-radius: 100px;
    border-top-right-radius: 100px;
    border:  solid #4A87CA 1px;
    font-size: 14px;
    line-height: 19px;
    flex-grow: 2;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3863BB;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
  }

  .selectBtn {
    background: #4A87CA;
    color: white;
  }

}
