.authBlock {
  position: relative;
  width: 684px;
  display: inline-block;
  vertical-align: top;
  margin-top: 150px;
  background: #FFFFFF;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24), 0 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  .goBack {
    position: absolute;
    left: 13px;
    top: 11px;
    width: 32px;
    height: 32px;
    background: url('../../assets/go-back-arrow.svg') center no-repeat;
    background-size: 50%;
    cursor: pointer;
  }
  .title {
    font-weight: 600;
    font-size: 30px;
    text-align: center;
    padding-top: 56px;
    padding-bottom: 37px;
    span {
      color: #3863bb;
    }
  }
  .inputBlock {
    position: relative;
    width: 422px;
    box-sizing: border-box;
    margin-top: 20px;
    margin-left: 120px;
    label {
      width: 74px;
      display: inline-block;
      font-size: 16px;
      text-align: left;
      color: rgba(0, 0, 0, 0.85);
    }
    input {
      width: 348px;
      background-color: #f0f0f0;
      font-size: 14px;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 3px;
      line-height: 2.6;
      padding-left: 14px;
      padding-right: 46px;
      font-family: 'Open Sans', sans-serif;
      &:focus {
        border-color: #4a87ca;
        transition: ease-out all .2s;
        &.error {
          border-color: #d94560;
        }
      }
      &.disabled {
        color: rgba(0, 0, 0, 0.33);
      }
      &.error {
        color: #d94560;
      }
    }
    input::-webkit-input-placeholder {color:rgba(0, 0, 0, 0.33);}
    input::-moz-placeholder          {color:rgba(0, 0, 0, 0.33);}/* Firefox 19+ */
    input:-moz-placeholder           {color:rgba(0, 0, 0, 0.33);}/* Firefox 18- */
    input:-ms-input-placeholder      {color:rgba(0, 0, 0, 0.33);}
    .showPassword {
      position: absolute;
      right: 0;
      top: 0;
      width: 46px;
      height: 100%;
      background: url('../../assets/eye-icon-grey.svg') center no-repeat;
      background-size: 50%;
      cursor: pointer;
      &.passwordVisible {
        background-image: url('../../assets/eye-icon-blue.svg');
      }
    }
    .dashedText {
      position: absolute;
      right: 3px;
      bottom: -27px;
      font-size: 16px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.33);
      border-bottom: 1px dashed rgba(0, 0, 0, 0.33);
      cursor: pointer;
    }
  }
  .confirmButton {
    position: relative;
    min-width: 150px;
    height: 40px;
    display: inline-block;
    background: #4a87ca;
    border-radius: 3px;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    margin: 52px auto;
    line-height: 2.5;
    padding: 0 38px;
    box-sizing: border-box;
    cursor: pointer;
    &.inactive {
      opacity: 0.5;
      cursor: not-allowed;
    }
    &.transparentText {
      color: transparent;
    }
  }
  .authError {
    position: absolute;
    bottom: 22px;
    left: 0;
    width: 100%;
    font-size: 16px;
    color: #d94560;
    text-align: center;
  }
}