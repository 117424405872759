.childPadding {
  margin: 20px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;

  .newReasonLabel {
    display: flex;
    flex-flow: row wrap;
    justify-content: stretch;
    align-self: center;
    align-items: center;

    .inputModal {
      min-height: 38px;
      border-radius: 3px;
      min-width: 328px;
      border: solid 1px #E0E0E0;
      background: #F0F0F0;
      font-size: 14px;
      padding: 0 10px;
      &::placeholder {
        color: #ABABAB;
      }
    }

    .reasonLabel {
      display: flex;
      flex-flow: row wrap;
      align-self: center;
      min-width: 170px;
    }

    .dateInput {
        display: flex;
        flex-flow: row wrap;
        justify-content: stretch;
        align-self: stretch;
        align-items: stretch;

        input {
          min-height: 40px;
          min-width: 328px;
          padding: 0 10px;
          border-radius: 3px;
          border: solid 1px #E0E0E0;
          background: #F0F0F0;
          &::placeholder {
            color: #ABABAB;
          }
        }
    }
  }
}

@media(max-width: 720px) {
  .childPadding {
    .newReasonLabel {
      .inputModal  {
        min-width: auto;
      }

      .reasonLabel {
        min-width: auto;
      }

      .dateInput {
        min-width: auto;

        input {
          min-width: auto;
        }
      }
    }
  }
}