.svBookDetails {
  background-color: #FFFFFF;
  color: #373a3c;
  padding: 40px 50px;
}
.mainContainer {
  display: flex;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.svColumn {
  margin: 0;
  display: flex;
  flex-flow: column wrap;
  min-height: 100vh;
  background-color: #F3F3F3;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24), 0 1px 4px rgba(0, 0, 0, 0.12);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  //padding: 25px 50px;
}
.catalogueNameContainer {
margin-left: 10px;
}
.catalogueName {
  font-size: 16px;
  padding-bottom: 20px;
  padding-top: 10px;
}
.catalogueNameUnder {
  margin: 20px 0;
  background: #F0F0F0;
  border-radius: 3px 3px 3px 0;
  height: 19px;
  width: fit-content;
  padding: 0 20px;
  position: relative;
  & div {
    margin-left: 20px;
    white-space: nowrap;
    margin-bottom: 20px;
    left: 0;
    bottom: -10px;
    position: absolute;
  }
}
.templatesContainer {
  border-left: 8px solid #F0F0F0;
  padding-left: 15px;
  margin-top: -20px;
  white-space: nowrap;
}
.templateName {
  display: flex;
  margin-top: 20px;
  height: 38px;
  &:hover {
    color: #0d2a4a;
  }

}
.connectedServiceName {
  display: inline-flex;
  color: #3A8F15;
  font-size: 14px;
  margin-left: 15px;
  margin-top: 7px;
  cursor: pointer;
}
.deleteButton {
  margin-left: 5px;
  height: 22px;
  width: 22px;
  background-image: url("./assets/greyDelete.svg");
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    cursor: pointer;
    background-image: url("./assets/redDelete.svg");
  }
}
.notGreenDiv {
  position: sticky;
  height: 38px;
  min-width: 400px;
  margin-left: auto;
  margin-top: -5px;
  &.greenDiv {
    background: rgba(101, 204, 20, 0.1);
    border-radius: 100px 0 0 100px;
  }
  }

.dotted {
  border-bottom: 4px dashed #C5DDF7;
  width: 100%;
  margin-bottom: 8px;
  margin-left: 14px;
  height: 11px;
  &.active {
    border-bottom: 4px solid #C3EAB3;
  };
}

.templateNameLabel {
  float: left;
  width: fit-content;
  min-width: fit-content;
  &.active {
    color: #3A8F15;
  }
}

.ortusContainer {
  width: 100%;
}
.tableTopOrtus {
  white-space: nowrap;
  height: 38px;
  padding-top: 12px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  background: #E5E5E5;
  width: 100%;
}
.firstTableTop {
  margin-left: 20px;
  margin-right: 20px;
  float: left;
}
.secondTableTop {
  float: right;
  position: sticky;
  min-width: 382px;
}
.thirdTableTop {
  margin-left: auto;
  margin-right: auto;
}
.tableTopOne {
  display: inline-flex;
  height: 38px;
  width: 100%;
  padding-top: 12px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  background: rgba(0, 0, 0, 0.08);
}
.childrenContainer {
}
.oneServicesContainer {
  margin-left: auto;
  z-index: 5;
  position: sticky;
  width: 30%;
  max-width: 550px;
  background-color: rgba(0, 0, 0, 0.08);
  &.green {
    background-color: rgba(101, 204, 20, 0.1);
  }
}

.blueButton {
  height: 22px;
  width: 22px;
  cursor: pointer;
  margin-left: 11px;
  margin-top: 7px;
  background-image: url("./assets/bluePlus.svg");
  background-repeat: no-repeat;
  background-position: center;
  &.green {
    background-image: url("./assets/greenPlus.svg");
  }
}
.buttonPanel {
  width: 393px;
  height: 47px;
  background: #FFFFFF;
  border-radius: 100px;
  display: inline-flex;
  box-shadow: 0 2px 4px rgba(121, 121, 121, 0.14), 0 3px 4px rgba(121, 121, 121, 0.12), 0 1px 5px rgba(63, 63, 63, 0.2);
  margin-bottom: 30px;
}
.button {
  width: 130px;
  text-align: center;
  height: 47px;
  line-height: 45px;
  vertical-align: middle;
  &:hover {
    cursor: pointer;
    background-color: #F0F6FE;
    color: #1D55D7;
  }
  &.active {
    cursor: pointer;
    background: #4A87CA;
    color: white;
  }
}
.buttonFirst {
  width: 130px;
  text-align: center;
  vertical-align: middle;
  line-height: 45px;
  &:hover {
    cursor: pointer;
    background-color: #F0F6FE;
    color: #1D55D7;
    border-radius: 100px  0 0 100px;
  }
  &.active {
    cursor: pointer;
    background: #4A87CA;
    color: white;
    border-radius: 100px  0 0 100px;
  }
}
.buttonLast {
  width: 131px;
  text-align: center;
  vertical-align: middle;
  line-height: 45px;
  &:hover {
    cursor: pointer;
    background: #F0F6FE;
    color: #1D55D7;
    border-radius: 0 100px 100px 0;
  }
  &.active {
    cursor: pointer;
    background: #4A87CA;
    color: white;
    border-radius: 0 100px 100px 0;
  }
}
.borderBox {
  background-color: rgba(0, 0, 0, 0.1);
  height: 27px;
  width: 1px;
  margin-top: 10px;
}
.oneServiceItem {
  border: none;
  font-size: 14px;
  margin-top: 10px;
  margin-left: 25px;
  margin-right: 25px;
  padding-top: 9px;
  padding-bottom: 10px;
  padding-left: 20px;
  position: relative;
  cursor: default;
  &.active {
    &:hover {
      cursor: pointer;
      color: #0d2a4a;
      background: #C3EAB3;
      border-radius: 100px;
    }
  }
}