.insurance {
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  align-self: stretch;

  select {
    padding: 0 10px;
    height: 40px;
    min-width: 350px;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 3px;
    appearance: none;
    background: #F0F0F0 url("./assets/arrowDownSelect.svg") no-repeat 95% 50%;
    font-size: 16px;
    line-height: 22px;
  }

  .labelChoice {
    display: flex;
    flex-flow: row wrap;
    align-self: center;
    min-width: 170px;
  }

}

@media(max-width: 720px) {

  .insurance {

    select {
      padding: 0 5px;
      height: 40px;
      min-width: 150px;

    }
    .labelChoice {
      min-width: auto;
      justify-content: flex-start;
      margin: 5px;
    }
  }
}

