.oneLine {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: rgba(0, 0, 0, 0.85);
    text-decoration: none;
    min-width: 120px;
    .showLogoutBtn {
        display: flex;
        flex-flow: column wrap;
        align-items: stretch;
        justify-content: stretch;
        padding: 5px 5px;
        max-width: 153px;

        .indentLeft {
            padding: 0 15px;
        }

        .logoutBtn {
            display: none;
            padding: 5px 0 0 0;
            background-color: snow;
            min-width: 150px;
            max-width: 200px;
            flex-flow: column wrap;

            .navLinks {
                padding: 15px 63px;
                display: flex;
            }

            .borderTop {
                padding-left: 35px;
                border-top: 1px #C6CCE4 dashed;
            }

            .logOutPng {
                max-width: 20px;
                padding-right: 10px;
            }
        }

        &:hover {
            .logoutBtn {
                position: absolute;
                display: flex;
            }
        }
    }

}

.logo {
    height: 37px;
    border-radius: 50%;
}

.logo img {
    padding: 5px;
}
.avatarContainer {
    display: flex;
    margin-left: 40px;
    margin-right: 8px;
    //margin-top: 7px;
    cursor: pointer;
}
.avatarContainerOpened {
    height: 84px;
    display: flex;
    margin: 20px;
    padding-left: 25px;
    border-bottom: 1px solid #CCD1D9;
}
.avatar {
    width: 40px;
    height: 40px;
    border: 1px solid #BDC3C7;
    border-radius: 50%;
}
.avatarSecond {
    width: 48px;
    height: 35px;
    padding-top: 13px;
    border: 1px solid #BDC3C7;
    border-radius: 50%;
}
.noNameImg {

}
.surnameName {
    min-width: 40px;
    margin-top: 9px;
    text-align: center;
    vertical-align: middle;
    background: none;
    color: black;
}
.surnameNameSecond {
    min-width: 48px;
    height: 48px;
    text-align: center;
    vertical-align: middle;
    background: none;
    color: black;
}
.anotherNameContainer {
    display: inline-block;
    padding-left: 14px;
}
.exitArrow {
    background-image: url("./assets/opened.svg");
    background-repeat: no-repeat;
    height: 12px;
    width: 12px;
    margin: auto 5px;
    background-position: bottom;
}
.semibold {
    font-weight: 600;
    font-size: 16px;
    margin-top: 10px;
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.85);
}
.phoneRole {
    display: flex;
    padding-top: 5px;
}
.phoneLabel {
    border: unset;
    width: 122px;
    padding-right: 10px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
}
.role {
    font-size: 14px;
    color: #696870;
    display: flex;
    padding-left: 5px;
    &.blue {
        color: #504BCC;
    }
    &.green {
        color: #568C11;
    }
}
.round {
    width: 8px;
    height: 8px;
    background: #696870;
    border-radius: 44px;
    margin-left: 10px;
    &.blue {
        background-color: #504BCC;
    }
    &.green {
        background-color: #568C11;
    }
}
.clickArea {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    &.active {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
    }
}
.logoutBtnNew {
    z-index: 2;
    display: none;
    width: 482px;
    height: 156px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    position: absolute;
    left: 40px;
    top: 54px;
    border-radius: 4px;
    box-shadow: 0 2px 9px rgba(28, 15, 83, 0.15), 0 16px 48px rgba(28, 15, 83, 0.12);
    background-color: #FFFFFF;
    & img {
        height: 20px;
        width: 18px;
        margin: auto 15px;
    }
    & div {
        margin: auto 0;
    }
    &.active {
        display: inline-block;
    }
}
.exitButton {
    display: flex;
    padding: 25px 19px;

}
.appHeader {
    font-size: 16px;
    background: linear-gradient(90deg, #CED5D9 0%, #DAE0E7 18.29%, #E8EBF1 37.01%, #E7E7E7 49.46%, #EFF3F7 57.73%, #F5FAFD 76.36%, #E6EBF0 89.85%, #D7DDE1 100%), #FFFFFF;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1), 0 1px 4px rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    align-self: stretch;
    justify-self: stretch;
    color: #262626;
    border-bottom: #C6CCE4 1px solid;
    flex-grow: 1;
    max-height: 56px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 32;
}

.blueText {
    color: #3863BB;
    padding-left: 10px;
}

.cpro {
    padding-right: 10px;
}

.dateNow {
    font-size: 16px;
    line-height: 22px;
}

.piplineSt {
    display: inline-block;
    background-color: #3863BB;
    margin-bottom: -4px;
    height: 25px;
    width: 2px;
    top: 20%;
    position: relative;
    cursor: pointer;
}