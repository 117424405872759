.why {
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  align-self: stretch;
  padding: 0 40px 50px;

  textarea {
    margin: 25px 0 0 170px;
    color: #1f2021;
    min-height: 100px;
    max-height: 400px;
    min-width: 605px;
    max-width: 700px;

    background: #F0F0F0;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 9px 15px;
  }

  textarea::placeholder {
    color: rgba(0, 0, 0, 0.33);

  }
}

.labelChoice {
  display: flex;
  flex-flow: row wrap;
  align-self: center;
  min-width: 170px;
  max-width: 180px;
}
