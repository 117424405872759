.btnSearchClear {
    border: 1px solid #3863BB;
    padding: 0 25px;
    text-align: center;
    min-height: 40px;
    min-width: 161px;
    color: #3863BB;
    font-size: 16px;
    line-height: 22px;
    border-radius: 3px;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    background-color: transparent;

    &:hover {
        background: #F0F6FF;
    }

    &.notActive {
        color: rgba(56, 99, 187, 0.6);
        border-color: rgba(74, 135, 202, 0.6);
        &:hover {
            background: #FFFFFF;
        }
    }

    &.light {
        color: #FFFFFF;
        background-color: #4A87CA;
        transition: all 0.3s ease-out;
        border: 1px solid transparent;
        &:hover {
            background: #F2F8FF;
            color: #3863BB;
            transition: all .2s ease-out;
            border: 1px solid #3863BB;
        }
    }

    &.blue {
        color: #FFFFFF;
        background-color: #4A87CA;
        transition: all 0.3s ease-out;
        border: 1px solid transparent;
        border-radius: 0;
        &:hover {
            background-color: #3372B7;
            transition: all 0.3s ease-out;
        }

    }

    &.marginTop12 {
        margin-top: 12px
    }

    &.forGuests {
        border: none;
        background: none;
        text-decoration: underline;
        line-height: 21.79px;
    }
}

.btnSearchNone {
    display: none;
}