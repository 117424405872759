.timeInput {
  box-sizing: border-box;
  display: flex;
  text-align: center;
  justify-content: space-around;
  padding-left: 15px;
  align-items: center;
  position: relative;
  margin: 0 4px 0 0;
  width: 110px;
  height: 40px;
  background: #F0F0F0;
  border: 1px solid #E0E0E0;
  border-radius: 3px;
  font-weight: normal;
  cursor: pointer;
  font-size: 16px;
  color: #242424;
  transition: all ease-out .2s;
  .spanTimeInput {
    padding-right: 15px;
    color: #949DA8;
  }
  .imgDateInput {
    display: flex;
    flex-flow: wrap row;
    align-self: stretch;
    justify-self: flex-end;
    align-items: center;
    justify-content: center;
    min-width: 47px;
    box-sizing: border-box;

  }

  &:focus {
    border-color: #1a6dca;
    transition: ease-out all .2s;
  }

  &.disabled {
    color: #949DA8;
  }

  &.marginLeft12 {
    margin-left: 0;
  }

  &.withArrow {
    padding-right: 15px;
  }
}

.popup {
  display: block;
  width: 195px;
  padding: 10px 0;
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin: 43px 0 2px 0;
  background: #FFFFFF;
  color: black;
  position: absolute;
  z-index: 14;
  height: 380px;
  overflow-y: auto;
}