.radio {
  display: flex;
  flex-flow: column nowrap;

  &.horizontal {
    flex-flow: row nowrap;
    align-items: center;
    padding: 10px 0;
    .labelRadio {
      padding-right: 16px;
      margin: 0;
    }
  }

  .btnLoad {
    position: absolute;
    display: flex;
    align-self: stretch;
    justify-self: stretch;
    min-width: 240px;
    min-height: 111px;
    border-radius: 100px;
  }

  .labelRadio {
    margin-bottom: 15px;
    color: #3863BB;
    cursor: pointer;
    display: flex;
    flex-flow: row wrap;

    .divRadio {
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      border-radius: 50%;
      border: 1px solid #4A87CA;
      margin-right: 8px;
      transition: all 100ms ease-out;
      display: flex;
      align-items: center;
      justify-content: center;
      .inside {
        display: flex;
        border-radius: 50%;
        height: 12px;
        width: 12px;
        border: none;
        background-color: transparent;
        transition: all 100ms ease-out;
      }
    }

    &.marginBottom {
      margin-bottom: 23px;
      align-items: center;
      .divRadio {
        margin-right: 10px;
      }
    }

    &.inputForm {
      margin-bottom: 3px;
      .divRadio {
        margin-top: 11px;
        margin-right: 10px;
      }
    }
  }


  input[type="radio"] {
    display: none;
  }
  & input:hover ~ .divRadio {
    background-color: #F0F6FF;
    transition: all 100ms ease-out;
  }

  & input:checked ~ .divRadio {
    .inside {
      background-color: #4A87CA;
      transition: all 100ms ease-out;
    }
    transition: all 100ms ease-out;
  }
}