.dailyMileageContainer {
  border-top: 1px solid #E0E0E0;
  padding: 40px;
  .dailyMileageHeader {
    margin: 0 0 24px 0;
  }
  .averageBlock {
    display: flex;
    flex-flow: row wrap;
    margin: 20px 0 0 0;
    .descriptionText {
      display: flex;
      color: rgba(0, 0, 0, 0.85);
      margin: 10px 20px 10px 0;
      width: 165px;
    }
    .radioButtonsAndInput {
      display: flex;
      flex-flow: column wrap;
      margin: 0 20px 10px 0;
    }
    .microButtonsArea {
      display: flex;
      align-self: flex-end;
      margin: 10px 20px 10px 0;
    }
    .monthPicker {
      padding: 10px 0;
    }
  }
}