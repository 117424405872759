.checkBoxes {
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  align-items: center;
  align-self: stretch;
  color: #3863BB;
  padding-right: 15px;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 35px;
  flex: 1 1 auto;
}

.checkBox {
  height: 20px;
  width: 20px;
  margin-right: 8px;
  border-radius: 3px;
  text-align: center;
  border: #4A87CA solid 1px;
  img {
    margin-top: 4px;
  }

  &:hover {
    background-color: #F0F6FF;
    color: #3863BB;
    transition: all .2s ease-out;
  }
}

.labelCheckBox {
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  align-items: center;
  align-self: stretch;
  padding-right: 15px;
  padding-top: 10px;
}

.labelWithBox {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding-right: 15px;
  padding-bottom: 35px;
  color: #3863BB;
}