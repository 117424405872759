.bg2 {
    background-color: #F0F0F0;
}

.mgBottom {
    margin-bottom: 15px;
}

.mgBottomVisitors {
    margin-bottom: 74px;
}