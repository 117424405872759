/*-------------------h4 toggle--------------------*/
.h4Toggle {
  align-self: stretch;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  padding: 0;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  margin: 1.33rem 0;
  transition: 0.5s;

  .triangleDown1 {
    position: relative;
    top: 1px;
    content: "";
    display: inline-block;
    width: 7px;
    height: 7px;
    border-right: 0.1em solid snow;
    border-top: 0.1em solid snow;
    transform: rotate(45deg);
    margin-right: 0.5em;
    margin-left: 0.7em;
  }

  .triangleUp1 {
    position: relative;
    content: "";
    display: inline-block;
    width: 7px;
    height: 7px;
    border-right: 0.1em solid snow;
    border-top: 0.1em solid snow;
    transform: rotate(135deg);
    margin-right: 0.5em;
    margin-left: 0.7em;
  }

  &.small {
    font-size: 15px;
    font-weight: normal;
  }

  &.big {
    font-size: 18px;
    font-weight: bold;
  }

  &.fontSize16 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  &.gray {
    .triangleUp1, .triangleDown1 {
      border-color: gray;
      align-self: center;
    }
  }

  &.withoutArrow {
    .triangleUp1, .triangleDown1 {
      display: none;
    }
  }

  &.leftPosition {
    justify-content: flex-start;
    align-items: center;
    margin: 0;
  }
  &.centerPosition {
    display: flex;
    justify-self: stretch;
    justify-content: center;
    align-self: center;
    margin: 0;
    flex: 1 1;
  }

  &.withoutPadding {
    padding: 0;
  }

  &.onHoverArrow {
    &:hover {
      transform: translateX(0.1em);
      color: #3863BB;
      transition: 0.5s;
      .triangleUp1 {
        border-color: #3863BB;
        transition: color 0.5s;
      }
      .triangleDown1 {
        border-color: #3863BB;
        transition: color 0.5s;
      }
    }
  }
}
/* ----------------------------------*/

