.svBookDetails {
  background-color: #FFFFFF;
  color: #373a3c;
  padding: 40px 50px;
  .svColumn {
    margin: 0;
    display: flex;
    flex-flow: column wrap;
    min-height: 100vh;
    background-color: #F3F3F3;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24), 0 1px 4px rgba(0, 0, 0, 0.12);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    .clients {
      display: flex;
      flex-flow: wrap column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 25px 50px;
    }
    .shadowBlock {
      border-top: 2px #ffffff solid;
      border-bottom: 2px #E0E0E0 solid;
    }
  }
}




