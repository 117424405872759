.minHeader {
  position: sticky;
  display: flex;
  flex-flow: row wrap;
  top: 57px;
  background: linear-gradient(90deg, #5785B5 0%, #6A5EC1 100%), #C4C4C4;
  z-index: 31;
  min-height: 51px;
  filter: drop-shadow(0px 2px 3px rgba(8, 14, 17, 0.2)) drop-shadow(0px 5px 8px rgba(69, 77, 82, 0.18));
  .client {
    display: flex;
    flex-flow: row nowrap;
  }
  .car {
    display: flex;
    flex-flow: row nowrap;
  }
}