.wrapFilter {
  background: #FFFFFF;
  padding: 32px 32px 15px 32px;
  display: flex;
  flex-flow: wrap row;
  align-items: stretch;
  justify-content: space-between;
  color: grey;
  .filters {
    display: flex;
    flex-flow: wrap row;
    padding-bottom: 15px;
  }
  .children {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }

  &.paddingTop0 {
    padding: 0 32px 32px 32px;
  }

  &.flatRight {
    justify-content: flex-end;
  }
  &.padding50 {
    padding: 32px 130px 32px 32px;
  }
}