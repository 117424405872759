.itemsOptions {
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: flex-start;
  height: 40px;
  padding: 0 25px;
  background-color: transparent;
  transition: all .1s ease-out;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: #E8F4FF;
    color: #3863BB;
    transition: all .1s ease-out;
  }

  &.selected {
    color: #949DA8;
    background-color: #EBEEF2;
    transition: all .1s ease-out;

    &:hover {
      color: #949DA8;
      background-color: #EBEEF2;
      transition: all .1s ease-out;
    }
  }

  &.blueSelect {
    display: flex;
    align-items: center;
    margin: 4px;

    &.selected {
      color: #FFFFFF;
      background-color: #4A87CA;
      transition: all .1s ease-out;

      &:hover {
        color: #FFFFFF;
        background-color: #4A87CA;
        transition: all .1s ease-out;
      }
    }
  }

  &.itemCenter {
    justify-content: center;
  }
}
