@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/fonts/open-sans/300.woff2') format('woff2'), url('./fonts/fonts/open-sans/300.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/fonts/open-sans/400.woff2') format('woff2'), url('./fonts/fonts/open-sans/400.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/fonts/open-sans/600.woff2') format('woff2'), url('./fonts/fonts/open-sans/600.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/fonts/open-sans/700.woff2') format('woff2'), url('./fonts/fonts/open-sans/700.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/fonts/open-sans/800.woff2') format('woff2'), url('./fonts/fonts/open-sans/800.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: block;
}

html {
  height: 100%;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  margin: 0;
  padding: 0;
  font-size: 14px;
  height: 100%;
}

h1 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
}

h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
}

h3 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  font-style: normal;
}

a:active, a:focus {
  outline: none !important;
}
a {
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
}

input, textarea {
  outline: none;
}
input:active, textarea:active {
  outline: none;
}
:focus {
  outline: none !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

select::-ms-expand {
  display: none;
}

.container {
  position: relative;
  min-height: 100%;
  height: 100%;
}
