.rowsKeyValue {
  display: flex;
  align-self: stretch;
  flex-flow: row wrap;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  justify-content: flex-start;
  min-height: 50px;
  padding: 5px 96px 5px 0;
  transition: background-color 0.1s;

  .showHoverBtn {
    display: none;
    transition: all 0.1s;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.4);
    padding: 5px 0 5px 0;
    transition: background-color 0.1s;
    .showHoverBtn {
      display: block;
      transition: all 0.1s;
    }
    .withoutBtn {
      padding: 5px 96px 5px 0;
    }
  }

  &.borderDashed {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.09);
  }

  .field {
    padding: 0 15px;
    display: flex;
    flex-flow: row;
    justify-content: stretch;
    text-align: center;
    flex: 1 1 auto;
    .fieldItem {
      display: flex;
      flex-flow: row;
      min-width: 30%;
      justify-content: stretch;
    }
  }

  &.header {
    font-size: 16px;
    font-weight: bold;
    background: transparent;
    &:hover {
      background: transparent;
    }
  }

}