.preReserveCards {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  padding: 15px 30px;
  background-color: transparent;
}

.cardInfo {
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: #A34992;
  &.yellowText {
    color: #faf650;
  }
}

.cardWrap {
  display: block;
  flex-flow: column wrap;
  padding: 5px 15px;
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
  .text {
    padding: 0 5px;
  }
}
