.selectField {
  margin: 4px  10px 4px 0;
  min-width: 235px;
  height: 43px;
  border: 1px solid #E0E0E0;
  color: #262626;
  font-size: 16px;
  line-height: 22px;
  padding: 0 15px;
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  align-content: center;
  border-radius: 3px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: white url("../../assets/arrow-down.png") no-repeat 95% 50%;
}