
/* кнопки для цикличности замены масла */
@mixin isExactlyLast () {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 19px;
  min-height: 30px;
  max-height: 30px;
  border: solid #4A87CA 1px;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;

  &:hover {
    background-color: #F0F6FF;
    color: #3863BB;
    transition: all .2s ease-out;
  }
}

.btnSmall {
  display: flex;
  flex-flow: row wrap;
  color: #3863BB;
  min-width: 180px;
  max-width: 180px;
  cursor: pointer;
  margin: 0 0 0 10px;

  .btnLoad {
    position: absolute;
    display: flex;
    min-width: 180px;
    min-height: 42px;
    max-height: 42px;
    border-radius: 100px;
  }

  &.bgWhite {
    .firstOil, .secondOil {
      background-color: #FFFFFF;
    }
    .selectBtn {
      background: #4A87CA;
      transition: ease-out all .2s;
      color: white;
    }
  }

  .firstOil {
    border-bottom-left-radius: 100px;
    border-top-left-radius: 100px;
    border-right: 1px solid transparent;
    flex-grow: 4;
    @include isExactlyLast
  }

  .secondOil {
    border-bottom-right-radius: 100px;
    border-top-right-radius: 100px;
    flex-grow: 2;
    @include isExactlyLast
  }

  .selectBtn, .selectBtn:hover {
    background: #4A87CA;
    color: white;
  }

  &.btnStretchCycle {
    justify-content: stretch;
    align-self: center;
    max-width: 300px;
    min-width: 240px;
    min-height: 40px;
    max-height: 40px;
    font-size: 14px;
    line-height: 19px;
    padding: 0;
    margin: 15px 0;
    cursor: pointer;

    .btnLoad {
      position: absolute;
      display: flex;
      min-width: 250px;
      max-width: 250px;
      border-radius: 100px;
    }

    .firstOil, .secondOil {
      flex-grow: 2;
      flex-basis: 25%;
    }
  }

  &.btnTires {
    justify-content: stretch;
    align-self: stretch;
    cursor: pointer;
    max-width: 350px;
    min-height: 40px;
    font-size: 14px;
    line-height: 19px;
    margin: 15px 0 35px;
    padding: 0;

    .firstOil, .secondOil {
      min-height: 40px;
      max-height: 40px;

    }

    .btnLoad {
      min-width: 350px;
      border-radius: 100px;
    }

    .firstOil, .secondOil {
      flex-grow: 2;
      flex-shrink: 0;
      flex-basis: 25%;
    }
  }

  &.mileage {
    width: 160px;
    min-width: 160px;
    max-width: 160px;
    align-self: center;

    .btnLoad {
      min-width: 160px;
      height: 32px;
    }

    .firstOil, .secondOil {
      flex-grow: 2;
      flex-shrink: 0;
      flex-basis: 25%;
      transition: all .3s ease-out;
    }
  }

  &.margin5 {
    margin: 5px;
    align-self: center;
    justify-self: flex-start;
  }

  &.height40 {
    box-sizing: border-box;
    min-height: 40px;
    height: 40px;
    margin: 0;
    .firstOil, .secondOil {
      min-height: 40px;
      max-height: 40px;
    }
  }

  &.alignSelfEnd {
    align-self: flex-end;
  }

  &.redBorder {
    .firstOil {
      border-right: none;
    }
    .firstOil, .secondOil {
      border-color: #D94560;
    }
  }

  &.notCheck {
    .firstOil {
      border-right: none;
    }
  }
}