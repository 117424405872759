/*-----------Cards---------------*/
.textBlack {
    color: #262626;
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    text-overflow: ellipsis;
}

.textGreen {
    color: #6B6B6B;
}

/*  card-arrow-top-right  */
.arrowTop {
    display: none;
    position: relative;
    max-width: 15px;
    max-height: 15px;
    border: 15px solid transparent;
    border-bottom: 0;
    border-right: 0;
    right: 0;
    top:0;
    border-top-color: #14A77B;
}

/* --------при наведении на карточку------- */
.itemHover:hover {
    background-color:  #EFFDFA;
}

/* -----------hover active-------*/
.listCollectionActive {
    background-color: rgb(235, 249, 251);
    .textGreen {
        color: rgb(16, 131, 97);
    }
    .arrowTop {
        display: block;
    }
}

/* card header */

.cardHeaderLeft {
    display: flex;
    justify-content: center;
    background-color: #77B4AC;
    flex-flow: column wrap;

 /*   max-height: 90px;*/
    min-height: 80px;
    border-radius: 3px 0 0 3px;

    min-width: 70px;
    max-width: 70px;
    .pictures {
        max-width: 70px;
        position: relative;
        text-align: center;
    }
}

/* card body */
.cardBodyLeft {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: stretch;
    max-height: 100px;
    min-height: 80px;
    min-width: 186px;
    font-size: 12px;
    line-height: 18px;
    flex-grow: 1;
    padding-left: 15px;
    .cardP {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: flex-start;
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}