.modalSearchInput {
  display: flex;
  flex-flow: wrap column;
  // align-items: flex-start;
  justify-content: stretch;
  flex-grow: 1;

}

.loadArea {
  height: 39px;
  min-width: 170px;
  padding: 0 23px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row wrap;
  position: relative;
  color: #949DA8;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  cursor: default;
  img {
    padding-right: 8px;
  }
}
