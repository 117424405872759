.sheetInspectionsBody {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: stretch;
    padding: 30px 50px 30px;
    background-color: #FFFFFF;
    color: #373a3c;
}

.wrapMiddle {
    padding: 35px 45px;
    background: white;
}

.cardShInspBodyH {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    background-color: #F3F3F3;
    color: #666666;
    padding: 15px 25px;
    border-radius: 0 0 3px 3px;
}