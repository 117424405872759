.checkBoxes {
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  align-items: center;
  align-self: stretch;
  color: #3863BB;
  padding-right: 15px;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 35px;
  flex: 1 1 auto;
}

.checkBox {
  min-height: 20px;
  min-width: 20px;
  margin-right: 8px;
  border-radius: 3px;
  text-align: center;
  border: #4A87CA solid 1px;
}

.labelCheckBox {
  display: flex;
  justify-content: stretch;
  align-items: center;
  align-self: stretch;
  padding-right: 15px;
  padding-top: 12px;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  cursor: pointer;
  &:hover {
    .checkBox{
      background-color: #F0F6FF;
      color: #3863BB;
      transition: all .2s ease-out;
    }
  }

  &.blueLabel {
    color: #3863BB;
  }

  &.paddingConnection {
    padding: 8px 0;
  }
  &.forSecretary {
    padding: 0;

    .checkBox {
      margin: 5px;
    }
    .checkBox:empty {
      border: solid 1px #CCD1D9;
    }
  }

  &.paddingEmpty {
    padding: 0;
  }

  &.paddingRight16 {
    padding-right: 16px;
  }

  &.paddingLeft16 {
    padding-left: 16px;
  }

  &.maxWidth125 {
    max-width: 125px;font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
  }

  &.greyWithDiv {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #949DA8;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    padding: 7px 25px 9px;
    div:first-child {
      color: #262626;
      font-weight: normal;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 14px;
      line-height: 19px;
    }
  }
  text-align: left;

  &.greyWithDiv:hover {
    background: #F2F8FF;
  }

  &.margin10 {
    margin: 0 5px;
    .checkBox {
      margin: 0;
    }
  }

  &.alignItemsEnd {
    align-items: flex-end;
  }

  &.selectBgBlue {
    .checkBox {
      background-color: #4A87CA;
    }
    .checkBox:empty {
      background-color: #FFFFFF;
    }
  }
}

.labelWithBox {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding-right: 15px;
  padding-bottom: 35px;
  color: #3863BB;
}