.sheetsBody {
  display: flex;
  flex-flow: column wrap;
  background: #F9FAFC;;
  color: rgba(0, 0, 0, 0.85);
  flex-grow: 1;
  padding: 24px 0;
  .notFound {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    align-self: center;
    display: flex;
    padding: 26px;
    color: #949DA8;
  }
}