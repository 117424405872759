.filtersSheets {
  display: flex;
  flex-flow: row wrap;
  align-self: center;
  justify-content: flex-start;
  align-items: center;
  width: 612px;
}
.filterOne {
  display: flex;
  flex-flow: row wrap;
  align-self: stretch;
  font-size: 16px;
  line-height: 22px;
  font-style: normal;
  font-weight: normal;
  color:  rgba(0, 0, 0, 0.85);
  height: 38px;
  border-radius: 3px;
  width: 248px;
  align-items: center;
  justify-content: center;
  background: #FFFFFF url('assets/arrow-down-icon.svg') no-repeat 95% 50%;
  outline: none;
  margin-right: 24px;
  padding: 0 15px;
  appearance: none;

  .select {
    height: 28px;
    color: rgba(0, 0, 0, 0.85);
    width: 248px;
    border-radius: 3px;
    padding: 10px 15px;
  }
}