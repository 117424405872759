:root {
  --main-bg-image: url("../../../assets/smiles/smile.svg")
}

.tableVisitors {
  display: flex;
  flex-flow: column wrap;
  min-width: 100%;
  background-color: #FFFFFF;
  color: #434343;

  .btnLoad {
    position: absolute;
    display: flex;
    top:0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    justify-content: center;
    padding: 0;
  }

  .tableVisitorsHead {
    background-color: #E5E5E5;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 600;
  }

  .tableVisitorsHead, .tableVisitorsBodyRow {
    display: flex;
    height: 49px;
    justify-content: stretch;
    align-items: center;
    padding: 0 20px;
    font-size: 16px;
    border-bottom: 1px solid #E0E0E0;
  }

  .tableVisitorsBodyRow {
    &.blueText {
      color: #3863BB;
    }
  }
  .tableVisitorsBodyRow:hover {
    background: #F2F8FF;
    cursor: pointer;
  }

  .col05 {
    flex: 0.5;
    .imgIn {
      overflow: hidden;
      height: 36px;
      width: 36px;
      min-width: 36px;
      border-radius: 50%;
      border: none;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      align-self: stretch;
      background: transparent no-repeat center;
      background-size: 100%;
      margin-right: 15px;
      &.rnd0{
        background-image: url("./assets/randomImages/0.svg");
      }
      &.rnd1{
        background-image: url("./assets/randomImages/1.svg");
      }
      &.rnd2{
        background-image: url("./assets/randomImages/2.svg");
      }
      &.rnd3{
        background-image: url("./assets/randomImages/3.svg");
      }
      &.rnd4{
        background-image: url("./assets/randomImages/4.svg");
      }
      &.rnd5{
        background-image: url("./assets/randomImages/5.svg");
      }
      &.rnd6{
        background-image: url("./assets/randomImages/6.svg");
      }
      &.rnd7{
        background-image: url("./assets/randomImages/7.svg") ;
      }
      &.rnd8{
        background-image: url("./assets/randomImages/8.svg") ;
      }
      &.rnd9{
        background-image: url("./assets/randomImages/9.svg") ;
      }
      &.rnd10{
        background-image: url("./assets/randomImages/10.svg") ;
      }
      &.rnd11{
        background-image: url("./assets/randomImages/11.svg") ;
      }
      &.rnd12{
        background-image: url("./assets/randomImages/12.svg") ;
      }
      .mainImg {
        width: 100%;
        object-fit: cover;
        margin: 0 auto;
      }
    }
  }
  .col1 {
    flex: 1;
  }

  .col2 {
    flex: 2;
  }

  .col3 {
    flex: 3;
  }

  .col4 {
    flex: 4;
  }

  .col5 {
    flex: 5;
  }

  .col05, .col1, .col2, .col3, .col4, .col5 {
    //padding: 0 10px;
  }

}
.center {
  text-align: right;
  padding-left: 10px;
}