.login {
  position: relative;
  width: 100%;
  height: 100vh;
  padding-top: 57px;
  box-sizing: border-box;
  color: #434343;
  .header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 57px;
    background: linear-gradient(90deg, #CED5D9 0%, #DAE0E7 18.29%, #E8EBF1 37.01%, #E7E7E7 49.46%, #EFF3F7 57.73%, #F5FAFD 76.36%, #E6EBF0 89.85%, #D7DDE1 100%), #FFFFFF;
    text-align: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.23), 0 3px 6px rgba(0, 0, 0, 0.16);
    font-size: 22px;
    &:before {
      content: '';
      height: 100%;
      display: inline-block;
      vertical-align: middle;
    }
    span {
      display: inline-block;
      vertical-align: middle;
      line-height: 1.05;
      padding: 0 11px;
      font-weight: bold;
      &.blue {
        color: #3863bb;
        border-left: 2px solid #3863bb;
      }
    }
  }
  .body {
    position: relative;
    height: 100%;
    width: 100%;
    background: url('../../assets/auth-background.png');
    background-size: cover;
    text-align: center;
  }
}