.collectionSheetDetails {
  background: #E6E6E6;
  height: 48px;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.55);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 40px;
  transition: background-color 0.2s;

  div {
    padding-right: 10px;
  }

  .pipe {
    height: 15px;
    width: 2px;
    background-color: rgba(0, 0, 0, 0.16);
    align-self: center;
    padding: 0;
    margin-right: 16px;
  }

  .imgMargin {
    height: 20px;
    width: 26px;
    background: transparent url('./assets/sheetCollect.svg') no-repeat center left;
  }

  &.withSheetLinkBg {
    background: #F9FAFC;
    color: rgba(0, 0, 0, 0.85);
    height: 40px;
    transition: background-color 0.2ms;
    &:hover {
      background: #E6EFF7;
      transition: background-color 0.2s;
      .imgMargin {
        background: transparent url('./assets/sheetCollectBlue.svg') no-repeat center left;
      }
    }
  }

  &.bgTransparent {
    background: transparent;
  }

  &.textColorWhite {
    color: #FFFFFF;
    .client {
      color: #FFFFFF;
    }
  }

  &.textBold {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;

    .client {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
    }
  }

  .client {
    display: flex;
    flex-flow: row nowrap;
    transition: all 0.2ms;
    &:hover {
      text-decoration: underline;
      transition: all 0.2ms;
    }
  }
}