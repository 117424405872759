.cardsArrayTemplate {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  position: relative;
  background: linear-gradient(to left, #6A5EC1, #5785B5);
  z-index: 23;
  .card {
    display: flex;
    flex-flow: column wrap;
    cursor: pointer;
    width: 250px;
    border-radius: 5px;
    margin: 30px 0 30px 30px;

    &:last-child {
      margin-right: 30px;
    }
  }
  &.sticky {
    position: sticky;
    top: 57px;
  }
}
