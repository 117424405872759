  .buttonPanel {
    width: 340px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 100px;
    display: inline-flex;
    box-shadow: 0 2px 4px rgba(121, 121, 121, 0.14), 0 3px 4px rgba(121, 121, 121, 0.12), 0 1px 5px rgba(63, 63, 63, 0.2);
    margin-top: 10px;
  }
  .button {
    width: 130px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    vertical-align: middle;
    &:hover {
      cursor: pointer;
      background-color: #F0F6FE;
      color: #1D55D7;
    }
    &.active {
      cursor: pointer;
      background: #4A87CA;
      color: white;
    }
  }
  .buttonFirst {
    width: 170px;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    &:hover {
      cursor: pointer;
      background-color: #F0F6FE;
      color: #1D55D7;
      border-radius: 100px  0 0 100px;
    }
    &.active {
      cursor: pointer;
      background: #4A87CA;
      color: white;
      border-radius: 100px  0 0 100px;
    }
  }
  .buttonLast {
    width: 170px;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    &:hover {
      cursor: pointer;
      background: #F0F6FE;
      color: #1D55D7;
      border-radius: 0 100px 100px 0;
    }
    &.active {
      cursor: pointer;
      background: #4A87CA;
      color: white;
      border-radius: 0 100px 100px 0;
    }
  }
  .borderBox {
    background-color: rgba(0, 0, 0, 0.1);
    height: 27px;
    width: 1px;
    margin-top: 7px;
  }
.fieldContainer {
  display: inline-flex;
  align-items: center;
  & span {
    width: 130px;
  }
  &.margin {
    margin-top: 25px
  }
}
.fieldLabel {
  display: flex;
  align-self: stretch;
  padding: 0 15px;
  margin: 4px 0;
  height: 40px;
  color: #262626;
  font-size: 16px;
  flex: 1 1 auto;
  outline: none;
  width: 407px;
  justify-content: stretch;
  background: #F0F0F0;
  border: 1px solid #CCD1D9;
  box-sizing: border-box;
  border-radius: 3px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.85);
  &.phone {
    width: 184px;
    background: #E9EDF5;
  }
  &.date {
    width: 184px;
  }
  &.comment {
    padding-top: 10px;
    height: 100px;
    font-weight: normal;
    font-size: 14px;
    & input {
      width: 407px;
      &::placeholder {
        color: #949DA8;
      }
    }
  }
}
.phoneLabel {
  font-size: 14px;
  color: #949DA8;
  margin-left: 8px;
  margin-bottom: 2px;
}