.paddingDateInput {
  padding-right: 24px;
  display: flex;
  // align-items: flex-start;
  align-items: center;
  justify-content: center;
  .paddingText {
    padding: 10px 63px 0 0;
  }
  .column {
    display: flex;
    flex-flow: column wrap;
  }
}