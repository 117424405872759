.preReserveContainer {
  padding: 40px;
  display: flex;
  flex-flow: row wrap;
  border-top: 1px solid #E0E0E0;

  &.paddingTopButton20 {
    padding: 30px 40px;
  }

  &.wrapped {
    flex-flow: column wrap;

    .notice {
      // padding: 7px 40px 0 153px;
      padding: 7px 40px 0 165px;
    }
  }

  &.paddingBottom5 {
    padding-bottom: 5px;
  }

  .preReserveCol {
    display: flex;
    flex-flow: row wrap;
  }
}
