.cardShInspHeader {
    align-self: stretch;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    background-color: #898989;
    padding: 15px 25px;
    border-radius: 3px 3px 0 0;
}

.imgAndH2 {
    padding: 10px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    min-width: 300px;
}

.imgAndH2 span {
    padding-left: 10px;
    font-size: 22px;
    line-height: 30px;
    color: #FFFFFF;
}

.triangleDownB {
    position: relative;
    top: -2px;
    content: "";
    display: inline-block;
    width: 9px;
    height: 9px;
    border-right: 0.2em solid snow;
    border-top: 0.2em solid snow;
    transform: rotate(135deg);
    margin-right: 0.5em;
    margin-left: 1.0em;
}