.wrapModal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.06);
  z-index: 15;
}
.options {
  margin-top: 45px;
  position: absolute;
  background: #ffffff;
  display: flex;
  flex-grow: 1;
  flex-flow: column nowrap;
  align-self: stretch;
  justify-content: stretch;
  padding: 21px 0;
  box-shadow: 0 2px 3px rgba(8, 14, 17, 0.2), 0 5px 8px rgba(69, 77, 82, 0.18), 0 -1px 1px rgba(218, 218, 218, 0.23);
  border-radius: 3px;
  z-index: 16;
  max-height: 200px;
  max-width: 250px;
  overflow: auto;
}