.prolongationInAction {
  display: flex;
  flex-flow: wrap column;
  flex-grow: 1;
  .padding15 {
    padding: 10px 15px 0 0;
    color: #000000;
  }
  .stickyGroup {
    position: sticky;
    background: #FFFFFF;
    z-index: 40;
    top: 217px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 10%), 0 1px 4px rgb(0 0 0 / 16%);
  }

  .emptyData {
    background-color: #FFFFFF;
    color: #949DA8;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;
    border-top: 26px solid #E3E5E8;
    padding-top: 50px;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
  }
  .wrapReport {
    display: flex;
    flex-flow: wrap column;
    flex-grow: 1;
    background-color: #FFFFFF;
  }
  .row {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    background-color: #FFFFFF;
    color: grey;
    padding: 0 32px 20px 32px;
    .flexStart {
      flex-flow: row wrap;
      align-items: stretch;
      justify-content: stretch;
      display: flex;
      padding-bottom: 15px;
    }

    .flexEnd {
      align-items: flex-start;
      justify-content: flex-end;
      flex-grow: 1;
      display: flex;
      flex-flow: row wrap;
    }
  }
}