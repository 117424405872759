.loaderContainer {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  display: none;
  z-index: 33;
  text-align: center;
  &:before {
    content: '';
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }
  &.fixed {
    position: fixed;
    z-index: 100;
  }
  &.active {
    display: block;
  }
  &.circle {
    border-radius: 50%;
  }
  &.small {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    .loader {
      width: 24px;
      height: 24px;
    }
    .path {
      stroke-width: 4;
    }
  }
  &.small240 {
    position: absolute;
    max-width: 240px;
    display: flex;
    flex-flow: column wrap;
    flex: 1 1 240px;
    max-height: 42px;
    justify-content: center;
    border-radius: 50px;
    background: transparent;
    z-index: 2;
    .loader {
      width: 24px;
      height: 24px;
    }
    .path {
      stroke-width: 4;
    }
  }
  &.withoutBackground {
    background: transparent;
  }
  &.gray {
    background: rgba(0, 0, 0, 0.3);
    .path {
      stroke: rgba(255, 255, 255, 0.7);
    }
  }
  &.grayLight {
    background: rgba(0, 0, 0, 0.2);
    .path {
      stroke: rgba(255, 255, 255, 0.7);
    }
  }
  &.violetWithoutLoader {
    background: rgba(78, 71, 132, 0.4);
    .loader {
      display: none;
    }
  }
  &.light {
    background: rgba(255, 255, 255, 0.7);
    .path {
      animation: dash 1.5s ease-in-out infinite, loader-colors 6s ease-in-out infinite;
    }
  }
  &.loaderTop {
    .loader {
      vertical-align: top;
      margin-top: 300px;
    }
  }

  &.posStart {
    position: inherit;
    text-align: start;
    padding-right: 8px;
    width: content-box;
  }
}
.loader {
  position: relative;
  width: 86px;
  height: 86px;
  display: inline-block;
  vertical-align: middle;
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
  stroke: rgba(255, 255, 255, 0.5);
  stroke-width: 3;
  stroke-miterlimit: 7;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -25px;
  }
  100% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -125px;
  }
}

@keyframes loader-colors {
  100%,
  0% {
    stroke: #574CB7;
  }
  40% {
    stroke: #4077BB;
  }
  66% {
    stroke: #574CB7;
  }
  80%,
  90% {
    stroke: #4077BB;
  }
}