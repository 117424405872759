.paddingBefore {
  padding-top: 20px;
}

.btnAdd {
  align-self: flex-end;
  height: 40px;
  width: 160px;
  padding: 0;
  background: #4A87CA;
  border: 1px solid transparent;
  color: #ffffff;
  margin-top: 15px;
}