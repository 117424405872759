.formField {
  padding: 7px 10px 0 10px;
}

.formField label {
  color: rgba(0, 0, 0, 0.58);
  font-size: 12px;
  line-height: 16px;
}

.fieldInput {
  display: flex;
  align-self: stretch;
  padding: 0 15px;
  margin: 4px 0;
  height: 38px;
  border: 1px solid #E0E0E0;
  border-radius: 3px;
  color: #262626;
  font-size: 16px;
  line-height: 22px;
  flex: 1 1 auto;
  outline: none;
  width: 201px;
  justify-content: stretch;
}