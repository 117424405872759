.statuses {
  border-top: 1px solid #CCD1D9;
  padding: 28px 10px 40px 73px;
  .buttons {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: stretch;
  }
  .withStatuses {
    display: flex;
    flex-flow: column wrap;
  }

  &.light {
    border-top: 1px solid transparent;
    padding: 0;

    .withStatuses {
      padding: 10px 10px 10px 50px
    }

    .withoutStatuses {
      display: flex;
      flex-flow: column wrap;
      align-items: flex-start;
      justify-content: center;
      border-top: 1px solid #CCD1D9;
      padding: 40px 10px 10px 50px;
    }

    .buttons {
      padding: 10px 10px 30px 50px;
    }
  }
}
