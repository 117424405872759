.microButtons {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: flex-start;
  padding-right: 15px;
  margin: 2px 0;
  box-sizing: border-box;
  height: 32px;

  .microButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border: dashed 1px #CCD1D9;
    border-radius: 100px;
    padding: 0 8px;
    margin-right: 4px;
    background: transparent;
    color: #949DA8;
    transition: all ease-out .2s;
    cursor: pointer;
    user-select: none;

    &:hover {
      border-color: #4A87CA;
      color: #3863BB;
      transition: all ease-out .2s;
    }
  }
}
