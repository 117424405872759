.reasonsModal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 31;


  .whiteReasonsModal {
    position: fixed;
    top: calc(50% - 140px);
    left: calc(50% - 187px);
    min-width: 374px;
    width: 374px;
    min-height: 139px;
    background: #FFFFFF;
    text-align: center;
    display: flex;
    flex-flow: column wrap;
    border-radius: 3px;

    &.bigModal {
      top: calc(200px);
      left: calc(50% - 377px);
      min-width: 754px;
    }

    .labelText {
      display: flex;
      flex-flow: column wrap;
      flex: 1 1;
      padding: 16px 27px;
      align-items: center;
      justify-content: center;
      min-height: 78px;
      background-color: transparent;
      font-size: 16px;
      line-height: 22px;
      font-weight: normal;
      border-radius:  3px  3px 0 0;
      color: #696870;

      &.boldFirst {
        p {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 6px;
          margin-top: 0;
          color: #434343;
        }
      }

      &.bgWhite {
        background-color: #FFFFFF;
      }

      &.bgGray {
        background-color: #F3F3F3;
      }

      &.bgDanger {
        background-color: #FFE6EA;
      }

      &.bgOrange {
        background-color: #FFE1C2;
      }

      &.padding10x30 {
        padding: 10px;
      }
    }

    .labelText2 {
      display: flex;
      flex-flow: column wrap;
      flex: 1 1;
      padding: 48px 32px 25px;
    }

    &.middleModal {
      top: calc(200px);
      left: calc(50% - 243px);
      min-width: 485px;
      .labelText {
        padding: 29px 27px;
        p {
          padding-bottom: 18px;
        }
      }
    }

    &.padding10x30 {
      .labelText {
        padding: 10px 30px;
        p {
          padding-bottom: 10px;
        }
      }
    }

    &.fontSize22 {
      .labelText {
        padding: 0;
        p {
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          line-height: 30px;
        }
      }
    }

    &.labelText2pad32 {
      .labelText2 {
        padding: 32px;
      }
    }

    .headerModal {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      line-height: 25px;
      padding-left: 48px;
      min-height: 48px;
      height: 48px;
      color: #434343;

      .btnClose {
        background-color: transparent;
        border: none;
        display: flex;
        align-self: stretch;
        justify-content: center;
        min-width: 48px;
        width: 48px;
        /*min-height: 48px;*/
        padding: 0;
      }

      &.bgDanger {
        background-color: #FFC9D6;
      }

      &.maxHeaderSize {
        height: auto;
      }

      &.bgGrey {
        background-color:  #F3F3F3;
      }

      &.withoutBtnClose {
        .btnClose {
          display: none;
        }
      }

      &.hiddenWithoutHover {
        .btnClose {
          background-color: transparent;
          color: transparent;
        }
        .btnClose:hover {
          background-color: transparent;
          color: rgba(0, 0, 0, 0.3);
        }
      }
    }

    .fieldsReason {
      .childPadding {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: row wrap;

        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;

        @mixin btnsColor () {
          justify-self: center;
          height: 61px;
          width: 187px;
          padding: 0;
          border: 1px solid transparent;
          margin: 0;
          background: transparent;
          border-radius: 0 0 3px 3px;
          font-size: 14px;
          line-height: 19px;
          text-transform: uppercase;
          flex: 1 1;
          cursor: pointer;
          transition: ease-out all .2s;
        }

        .btnBlue {
          @include btnsColor;
          color: #3863BB;
          border-radius: 0 0 0 3px;
          &:hover {
            background: #F0F6FF;
          }
        }

        .pipeLine {
          border:  0.5px solid #E0E0E0;
          height: 30.5px;
          min-height: 30.5px;
        }

        .btnPink {
          @include btnsColor;
          color: #D94560;
          border-radius: 0 0 3px 0;
          &:hover {
            background: #FFF7F7;
          }
        }
      }

      &.statusOwner {
        border-top: 1px solid #CCD1D9;
        padding: 24px 32px;

        .childPadding {
          justify-content: space-between;
          .pipeLine {
            border: none;
          }
          @mixin newBtns () {
            height: 40px;
            text-transform: none;
            font-family: 'Open Sans', sans-serif;
            font-size: 16px;
            line-height: 22px;
          }

          .btnBlue {
            background-color: transparent;
            border: 1px solid #4A87CA;
            max-width: 161px;
            @include newBtns;
            &:hover {
              background: #F0F6FF;
            }
          }

          .btnPink {
            background-color: #4A87CA;
            color: white;
            border-radius: 3px;
            max-width: 220px;
            @include newBtns;
            &:hover {
              background: #3372B7;
            }
            &:disabled {
              background: rgb(146, 183, 223);
              color: #D3E2F2;
            }
          }
        }
      }

      &.greyTextButtons {
        .childPadding {
          .btnBlue {
            color: #6D6D6D;
            &:hover {
              color: #3863BB;
              background: #F0F6FF;
            }
          }
          .btnPink {
            color: #3863BB;
            &:hover {
              color: #3863BB;
              background: #F0F6FF;
            }
            &:disabled {
              color: rgba(56, 99, 187, 0.6);
              &:hover {
                color: rgba(56, 99, 187, 0.6);
                background: transparent;
              }
            }
          }
        }
      }
    }
  }
}

