.message {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  color: #D94560;
  .messageText {
    display: flex;
    flex-flow: row wrap;
    max-width: 100px;
  }

   &.green {
     color: #14A77B;

     .messageText {
       margin-right: 24px;
     }

     img {
       padding-right: 6px;
     }
   }

   &.grey {
     color: #696870;
     .messageText {
       margin-right: 24px;
     }

     img {
       padding-right: 6px;
     }
   }

   &.greyLight {
     color: #949DA8;
     .messageText {
       margin-right: 24px;
     }

     img {
       padding-right: 6px;
     }
   }

  &.maxWidthStretch {
    align-items: flex-start;
    flex-flow: column nowrap;
    .messageText {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      max-width: none;
    }
  }

  &.height40 {
    .messageText {
      height: 40px;
    }
  }

  &.paddingLeft {
    padding-left: 128px;
  }

  &.warningColor {
    color: #C76F0A;
  }

  &.flexEnd {
    align-items: flex-end;
  }

  &.paddingRight35 {
    padding-right: 35px;
  }

  &.paddingRight55 {
    padding-right: 75px;
  }
}