.carBody {
  align-self: flex-end;
  display: flex;
  flex-flow: column wrap;
  flex: 1 1 0;

  background-color: #F3F3F3;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24), 0 1px 4px rgba(0, 0, 0, 0.12);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  max-width: 340px;
  padding: 15px 40px 40px;
}