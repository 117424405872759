.grayButton {
  border: 1px solid #CCD1D9;
  box-sizing: border-box;
  border-radius: 3px;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%);
  height: 34px;
  color: #6B6E73;
  padding: 0 20px;
  font-family: 'Open Sans', sans-serif;;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  transition: all .3s ease-out;
  user-select: none;

  &:hover {
    background: linear-gradient(180deg, #FCFCFC 0%, #F2F2F2 100%);
  }
  &.small {
    padding: 0 15px;
    background: linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%);
  }

  &.redBg {
    background: #FFFFFF;
    border: 1px solid #D94560;
    color: #D94560;
    transition: all .3s ease-out;
    &:hover {
      background: #FFF5F7;
    }
  }

  &.showMore {
    background: #FFFFFF;
    color: #3863BB;
    border: 1px solid #3863BB;
    display: block;
    cursor: pointer;
    min-width: 230px;
    height: 40px;
    border-radius: 3px;
    transition: all .3s ease-out;

    &.blueBg {
      color: #FFFFFF;
      background: #4A87CA;
      border: 1px solid transparent;
      display: block;
      cursor: pointer;
      min-width: 230px;
      height: 40px;
      border-radius: 3px;
      transition: all .3s ease-out;

      &.blueBg:hover {
        background-color: #3372B7;
        color: #FFFFFF;
        transition: all .3s ease-out;
      }

      &:disabled {
        color: #d3e2f2;
        background: #92b7df;
        border: 1px solid #CCD1D9;
        &:hover {
          color: #d3e2f2;
          background: #92b7df;
          border: 1px solid #CCD1D9;
          cursor: not-allowed;
        }
      }

      &.disabled {
        color: #d3e2f2;
        background: #92b7df;
        border: 1px solid #CCD1D9;
        &:hover {
          color: #d3e2f2;
          background: #92b7df;
          border: 1px solid #CCD1D9;
        }
      }
    }

    &.showMore:hover {
      background-color: #F2F8FF;
      transition: background-color .3s ease-out;
    }

    &:disabled {
      color: #949DA8;
      background: linear-gradient(0deg, #F2F2F2, #F2F2F2), linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%);
      border: 1px solid #CCD1D9;
    }

    &.small {
      min-width: 125px;
    }

    &.redBg {
      background: #FFFFFF;
      border: 1px solid #D94560;
      color: #D94560;
      transition: all .3s ease-out;
      &:hover {
        background: #FFF5F7;
      }
    }

  }

  &.paddingMore {
    padding: 0 125px;
  }

  &.visitorButtonAdd {
    min-width: 199px;
  }

  &.minWidth {
    min-width: 40px;
  }

  &.marginRight16 {
    margin-right: 16px;
  }

  &.marginLeft8 {
    margin-left: 8px
  }

  &.bgTransparent {
    transition: all .3s ease-out;
    background: transparent;
    border-color: #4A87CA
  }

  &.thinButton {
    height: 30px;
    border-radius: 50px;

    &.blueBg {
      height: 30px;
      border-radius: 50px;
    }
  }

  &.bgGrey {
    background: #D1D1D1;
    border-color: #D1D1D1;
    color: #FFFFFF;
  }

  &.bgGreen {
    background: #36B28D;
    border-color: #36B28D;
    color: #FFFFFF;
    &:hover {
      background: #009468;
      border-color: #009468;
    }
  }

  &.height40 {
    height: 40px;
  }
  &.margin0_30_25_30 {
    margin: 0 30px 25px 30px;
  }
}
