.sheetCollectHeader {
    padding: 45px 50px 50px 88px;
    &.paddingBottom0 {
        padding-bottom: 0;
    }
}
.columnContainer {
    display: flex;
}
.cardsAnchors {
    display: flex;
    flex-flow: row wrap;
    padding-top: 29px;
    align-items: stretch;
    justify-content: flex-start;
    font-size: 22px;
}

.cardAnchor {
    display: flex;
    flex-flow: column wrap;
    padding: 0 10px 5px;
    background-color: white;
    color: #262422;
    margin: 15px 25px 15px 0;
    min-height: 50px;
    height: 84px;
    min-width: 140px;
    border: 0;
    border-radius: 3%;
    font-size: 16px;
}
.cardAnchor p {
    margin: 0 5px;
}

.cardAnchor .aroundBorder {
    margin: 0 5px;
}

.anchorCardNum {
    font-weight: 600;
    font-size: 42px;
    line-height: 57px;
    letter-spacing: 0.02em;
    &.forStateBlockImgNum {
        padding: 13px 0;
    }
}

.aroundBorder {
    height: 32px;
    width: 32px;
    border: 3px solid #1CBC8C;
    border-radius: 50%;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: bolder;
    line-height: 24.51px;
    display: flex;
    justify-content: center;
}

.colorTextRed {
    color: #E15173;
}

.colorTextGreen {
    color: #1CBC8C;
}

.colorTextGrey {
    color: #AAA9CF;
}
/*-----------------------------*/
.statuses {
    background: #FFEDF9 url("../../../assets/statuses.svg") no-repeat right bottom;
}
/*-----------------------------*/
.mileage {
    background: #D9EDFF url("../../../assets/mileage.svg") no-repeat right bottom;
}
/*-----------------------------*/
.anchors {
    background: #FFF3E2 url("../../../assets/anchor.svg") no-repeat right bottom;
}
/*-----------------------------*/
.secretary {
    background: #DFF7E5 url("../../../assets/secretary.svg") no-repeat right bottom;
}

.service {
    background: #FFEDF2 url("../../../assets/service.svg") no-repeat right bottom;
}

.settings {
    background: #55539F url("../../../assets/settings.svg") no-repeat right bottom;
}