.headerSticky {
  position: -webkit-sticky;
  position: sticky;
  top: 57px;
  background: linear-gradient(to left, #6A5EC1, #5785B5);
  &.withShadow {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1), 0 1px 4px rgba(0, 0, 0, 0.16);
  }
  &.noSticky {
    position: relative;
    top: 0;
  }
}
