.errorIndicator {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  color: #7adcc3;
}

.errorIndicator span {
  display: block;
}

.errorIndicator img {
  margin-bottom: 1rem;
  max-height: 300px;
}

.errorIndicator .boom {
  font-size: 1.7rem;
}