.rowSelectMonth {
  display: flex;
  flex-flow: nowrap row;
  align-items: stretch;
  justify-content: stretch;
  margin-bottom: 10px;
  &.withoutMargin {
    margin-bottom: 0;
    max-width: 183px;
    .monthsSelectWrap {
      width: 183px;
    }
  }
  &.marginRight12 {
    padding-right: 12px;
  }
}

.selectedValueButton {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-self: flex-start;
  padding: 0 15px;
  height: 38px;
  border: 1px solid #E0E0E0;
  border-radius: 3px;
  width: 163px;
  cursor: pointer;
  font-size: 16px;
  line-height: 22px;
  background: #F0F0F0 url("../../../assets/arrowDownSelect.svg") no-repeat 90% 50%;
  transition: all .2s ease;

  &.disableTitle {
    color: #949DA8;
  }

  &.focused {
    border-color: #4A87CA;
    transition: all .2s ease;
  }

  &.redBorder {
    border-color: #D94560;
  }
}

.monthsSelectWrap {
  display: block;
  width: 195px;
  padding: 10px 0;
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin: 43px 0 2px 0;
  background: #FFFFFF;
  color: black;
  position: absolute;
  z-index: 14;
  height: 300px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }
  .allTypes::-webkit-scrollbar-track {
    background-color: #E0E0E0;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: rgba(0, 0, 0, 0.16);
  }

  &.minHeight {
    height: min-content;
  }
}

.modalSelect {
  position: fixed;
  background-color: transparent;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 12;
}