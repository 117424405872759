.tableProlongationInAction {
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
  box-sizing: border-box;
  --height-cell: 75px;
}

.headerRow {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 5px;
}

.headerColumn {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-end;
  justify-content: center;
  height: var(--height-cell);
  box-sizing: border-box;

  min-height: var(--height-cell);
  padding: 0 10px 0 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}

.headerDataLabel {
  display: flex;
  flex-flow: column wrap;
  align-items: stretch;
  justify-content: stretch;
  flex-grow: 1;

  .tableData {
    display: flex;
    flex-flow: nowrap row;
    background-color: #FFFFFF;
    max-width: 100vw;
    overflow-x: auto;

    &::-webkit-scrollbar-thumb {
      height: 3px;
      border-radius: 50px;
      background-color:  rgba(109, 99, 184, 0.35);
      -webkit-transition: all 1s;
      -moz-transition: all 1s;
      -o-transition: all 1s;
      transition: all 1s;
    }

    &:hover::-webkit-scrollbar-thumb {
      background-color: rgba(109, 99, 184, 0.55);
      -webkit-transition: all 1s;
      -moz-transition: all 1s;
      -o-transition: all 1s;
      transition: all 1s;
    }

    &::-webkit-scrollbar {
      height: 8px;
      background-color: rgba(161, 164, 232, 0.65);
      -webkit-transition: all 1s;
      -moz-transition: all 1s;
      -o-transition: all 1s;
      transition: all 1s;
    }

    .tableDataLabels {
      color: black;
      width: 230px;
      min-width: 230px;
      box-sizing: border-box;
      display: flex;
      flex-flow: column nowrap;
      position: sticky;
      left: 0;
      z-index: 2;
      .row {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        background: #FFFFFF;
        .arrow {
          height: 9px;
          margin-left: 5px;
          cursor: pointer;
        }
      }

      .greyBg {
        background-color: #DADBDE;
        box-shadow: 0 3px 0 rgba(0, 0, 0, 0.1), 0 1px 0 rgba(0, 0, 0, 0.16);
        z-index: 1;
      }

      .lightGreyBg {
        background-color: rgba(227, 229, 232, 0.5);
      }

      .headerUp {
        height:  var(--height-cell);
        background-color: #6d63b8;
        white-space: normal;
        color: #ffffff;
        text-transform: uppercase;
        align-items: center;
        justify-content: center;
        display: flex;
        min-width: 200px;
        flex-flow: column wrap;

        span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .data {
      display: flex;
      flex-flow: column nowrap;
      color: rgba(0, 0, 0, 0.85);
      .cellDiv {
        position: relative;
        display: flex;
        min-width: 125px;
        border-right: 1px solid #f1efef;
        .arrow {
          position: absolute;
          top: 33px;
          right: 15px;
          height: 9px;
          margin-left: 5px;
        }
        .linkA {
          display: flex;
          align-self: stretch;
          justify-self: stretch;
          align-items: center;
        }
      }
      .dataRows {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: row wrap;
        background-color: #FFFFFF;
        box-sizing: border-box;
        height: var(--height-cell);
        min-height: var(--height-cell);
        border-bottom: 1px solid rgba(0, 0, 0, 0.03);

        &.year {
          background-color: rgba(227, 214, 247, 0.45);

          &.greyBg {
            background-color: rgba(221, 201, 221, 0.85);
          }
          &.lightGreyBg {
            background-color: rgba(234, 222, 234, 0.95);
          }
        }

        &.month {
          background-color: rgba(233, 225, 247, 0.35);

          &.greyBg {
            background-color: rgba(221, 201, 221, 0.65);
          }
          &.lightGreyBg {
            background-color: rgba(234, 222, 234, 0.65);
          }
        }

        &.week {
          background-color: rgba(233, 225, 247, 0.2);
          &.greyBg {
            background-color: rgba(221, 201, 221, 0.45);
          }
          &.lightGreyBg {
            background-color: rgba(234, 222, 234, 0.45);
          }
        }

        &.day {
          background-color: rgba(233, 225, 247, 0.3);
          &.headerUp {
            background-color: #e3eafc;
          }
        }

        &.total {
          background-color: #afbfe7;
          &.greyBg {
            background-color: rgba(234, 180, 234, 0.65);
            box-shadow: 0 3px 0 rgba(0, 0, 0, 0.1), 0 1px 0 rgba(0, 0, 0, 0.16);
            z-index: 2;
          }
          &.lightGreyBg {
            background-color: #afbfe7;
          }
          &.headerUp {
            background-color: #C9F1E0;
          }
        }

        &.greyBg {
          background-color: rgba(214, 214, 214, 0.65);
          box-shadow: 0 3px 0 rgba(0, 0, 0, 0.1), 0 1px 0 rgba(0, 0, 0, 0.16);
          z-index: 1;
        }
        &.lightGreyBg {
          background-color: rgba(227, 229, 232, 0.5);
        }
      }
      .dataColumn {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column wrap;
        background-color: #FFFFFF;
        height: 40px;

        &.greyBg {
          background-color: rgba(214, 214, 214, 0.65);
        }
        &.lightGreyBg {
          background-color: rgba(227, 229, 232, 0.5);
        }
      }

      .headerUp {
        box-sizing: border-box;
        height: var(--height-cell);
        min-height: var(--height-cell);
        position: relative;
        display: flex;
        background-color: rgb(227, 234, 252);
      }
    }
  }

  &.reverseTable {
    flex-flow: row wrap;
  }
}

.flexStart {
  align-items: flex-start;
}

@media(max-width: 1400px) {
  .headerDataLabel {
    .tableData {
      max-width: 100vw;
      overflow-x: auto;
    }
  }
}
