.arrowDown  {
  position: relative;
  top: -2px;
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-right: 0.2em solid #434343;
  border-top: 0.2em solid #434343;
  transform: rotate(135deg);
  margin-right: 0.5em;
  margin-left: 1.0em;
}

.arrowUp {
  position: relative;
  top: -2px;
  content: "";
  display: inline-block;
  width: 9px;
  height: 9px;
  border-right: 0.2em solid #434343;
  border-top: 0.2em solid #434343;
  transform: rotate(-45deg);
  margin-right: 0.5em;
  margin-left: 1.0em;
}


.head {
  min-height: 103px;
  border-radius: 3px 3px 0 0;
  display: flex;
  flex-flow: row wrap;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;

  &.headStatus {
    background: linear-gradient(90.5deg, #FFE0F8 0%, #FFE3F9 0.01%, #E3D6F7 100%);
  }

  &.headMileageCollect {
    background: linear-gradient(90deg, #D7EEFF 0%, #CED6FE 100%);
  }

  .contentCollect {
    display: flex;
    flex-flow: column wrap;
  }

  .contentCollect.hiddenContent {
    display: none;
  }

  .rightHead {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
  }

}

.leftHead {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  .emptyMark {
    height: 26px;
    width: 26px;
    background: transparent;
  }

  .textHeader {
    padding-left: 15px;
    color: #434343;
  }
}

.lastMileage {
  font-weight: 600;
  margin-top: 8px;
  margin-left: 15px;
  color: #696870;
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
}