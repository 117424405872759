.cardHeaderRight {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    width: 70px;
    padding: 5px;

}
.cardHeaderRight .round {
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    color:white;
    border-radius: 50px;
    background: #7F9CBF;
}

.arrowTopLeft {
    display: none;
    position: absolute;
    max-width: 15px;
    max-height: 15px;
    border: 15px solid transparent;
    border-bottom: 0;
    border-left: 0;
    border-top-color: #EB9C53;
    z-index: 6;
}


.textGrow {
    color: #6B6B6B;
    text-overflow: ellipsis;
}

.listCollectionHover:hover {
    background-color: #FFF0EA;
}

.textBl {
    color: #262626;
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    text-overflow: ellipsis;
}

.select .textGrow {
    color: #974011;
}

.select .arrowTopLeft {
    display: block;
}

.select.listCollectionHover {
    background-color: #FFF0EA;
}