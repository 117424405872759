.collectInfoReportBody {
  display: flex;
  flex-flow: column wrap;
  background: #F9FAFC;
  color: #434343;
  flex-grow: 1;

  .tableHeader {
    display: flex;
    flex-flow: column wrap;
    align-items: stretch;
    justify-content: stretch;
    font-size: 14px;
    line-height: 17px;
    height: 70px;
  }

  .tableBody {
    display: flex;
    flex-flow: column wrap;
  }
}

.table {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-grow: 1;
}

.rowOwner {
  display: flex;
  align-items: stretch;
  height: 39px;
  padding: 0 0 0 40px;
  border-top: solid 1px #E6E6E6;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;


  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px 0 0;
    width: 100px;
    a {
      padding: 4px 8px;
    }
    a:hover {
      background-color: #E6EFF7;
      border-radius: 3px;
      color: #232425;
    }
  }

  .name {
    color: #3863BB;
    width: 300px;
  }

  &:last-child {
    flex-grow: 1;
  }

  &.headerRowOwner {
    border-top: none;
    border-bottom: solid 4px #E6E6E6;
    color: #707171;
    font-size: 14px;
    line-height: 17px;
    .name {
      color: #707171;
      font-size: 14px;
      line-height: 17px;
      .arrow {
        padding-left: 5px;
      }
    }
  }
}