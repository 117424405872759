.svColumn {
  margin: 40px 0 0 0;
  display: flex;
  flex-flow: column wrap;
}

.carBasicInfo {
  background-color: #F3F3F3;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24), 0 1px 4px rgba(0, 0, 0, 0.12);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  //padding: 25px 50px;
  .headerStatuses {
    display: flex;
    flex-flow: wrap row;
    justify-content: stretch;
    align-items: stretch;
    h2 {
      color: #3863BB;
      padding: 30px 30px 0 50px;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
      flex-grow: 1;
    }
  }
}

.fields {
  align-self: stretch;
  padding: 0 15px;
  margin: 4px  10px 4px 0;
  width: 258px;
  height: 40px;
  border: 1px solid #E0E0E0;
  color: #262626;
  font-size: 16px;
  line-height: 22px;
}

select.fields, .fields option {
  background: white;
}

.blueBtn {
  background: #4A87CA;
  border: none;
  color: white;
  font-size: 16px;
  line-height: 22px;
  border-radius: 3px;
  margin: 4px  10px 4px 0;
  min-width: 150px;
  min-height: 40px;
  max-height: 40px;
}

.phone, .client {
  max-width: 168px;
  display: flex;
  flex-flow: wrap column;
  align-self: stretch;
  padding: 0 15px;
  margin: 4px  10px 4px 0;
  height: 40px;
  border: 1px solid #E0E0E0;
  color: #262626;
  font-size: 16px;
  line-height: 22px;
}

.rowFieldsClient {
  display: flex;
  flex-flow: wrap row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 25px 50px;
}

.clients {
  display: flex;
  flex-flow: wrap column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 25px 50px;
}

.userFields {
  display: flex;
  flex-flow: wrap row;
}

.btn {
  display: flex;
  align-self: center;
  justify-content: center;
  border: 1px solid #4A87CA;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  background-color: transparent;
}