.headerStatuses {
  display: flex;
  flex-flow: wrap row;
  justify-content: stretch;
  align-items: stretch;
  h2 {
    color: #3863BB;
    padding: 30px 30px 0 50px;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    flex-grow: 1;
    margin: 0;
  }
  .arrowBlue {
    width: 100px;
    height: 92px;
    display: flex;
    align-items: center;
    justify-content: center;
     &.rotate {
       transform: rotate(-90deg);
     }
  }
}