.modal{
  position: fixed;
  bottom: 0;
  left: 0;
  height: 70px;
  width: 100%;
  z-index: 15;
  color: #FFFFFF;
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  align-items: center;

  &:empty {
    height: 0;
  }

  .img {
    padding: 10px;
    height: 42px;
    width: auto;
  }

  .buttonModal {
    border-radius: 3px;
    height: 44px;
    width: 88px;
    color: #FFFFFF;
    margin-left: 30px;
    border: 1px solid transparent;
    background: rgba(0, 0, 0, 0.25);;
  }
}

.errorModal {
  background: linear-gradient(270.08deg, rgba(136, 0, 114, 0.8) 0%, rgba(150, 45, 0, 0.8) 100%);
}

.successModal {
  background: linear-gradient(270.08deg, rgba(0, 150, 78, 0.8) 0%, rgba(0, 103, 147, 0.8) 100%);
}
