.carBasicInfo {
    display: flex;
    flex-flow: column wrap;
    justify-content: stretch;
    align-items: stretch;
    background-color: #F3F3F3;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24), 0 1px 4px rgba(0, 0, 0, 0.12);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.carBasicInfoRow {
    display: flex;
    // flex-flow: row wrap; // ToDo
    flex-flow: row nowrap;
    justify-content: stretch;
    align-items: stretch;
    border-radius: 3px;
    padding: 50px;
}