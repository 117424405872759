.clientNamePhoneRow {
  display: flex;
  flex-flow: wrap row;
  // max-height: 40px;
  .fieldName {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    padding-right: 12px;
    min-width: 600px;
  }
  .fieldPhone {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    padding-right: 4px;
    min-width: 200px;
  }
}