.columns {
  display: flex;
  flex-flow: wrap row;
  flex-grow: 1;

  .first {
    display: flex;
    flex-grow: 3
  }

  .second {
    display: flex;
    margin-left: 12px;
    flex-grow: 1;
  }

  &.stretchChildren {
    align-items: stretch;
    .first {
      min-width: 600px;
    }
  }
  &.flexGrow0 {
    flex-grow: 0;
    .first {
      flex-grow: 0;
    }
    .second {
      flex-grow: 0;
    }
  }
}