.nameLines {
  width: 255px;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  div {
    display: flex;
    color: rgba(0, 0, 0, 0.55);
    height: 48px;
    padding: 0 7px 0 24px;
    align-items: center;
    border-bottom: 1px #E6E6E6 solid;
  }

  .blueType {
    color: #3863BB;
    align-items: flex-start;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }

  .underPercent {
    height: 59px;
    align-items: flex-start;
    padding-top: 10px;
  }

  .boldText {
    font-weight: 600;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.7);
  }

  .greenText {
    color: #3A8F15;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }

  div.itemEnd {
    border-bottom: 1px transparent solid;
  }
}