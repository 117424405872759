.mainInfo {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    align-self: stretch;
    color: white;
    flex-grow: 1;
    min-height: 100%;
}
/*----------------------------------*/
.listInspect {
    display: flex;
    flex-flow: column;
    justify-content: stretch;
    align-items: stretch;
    background: #476C92;
    max-width: 20%;
    flex: 1 1 18%;
}

.workArea {
    display: flex;
    flex-flow: column wrap;
    justify-content: stretch;
    align-items: stretch;
    align-self: stretch;
    background: linear-gradient(to left, #6A5EC1, #5785B5);
    max-width: 60%;
    flex: 2 1 auto;
    z-index: 1;
}
.workAreaSettings {
    display: flex;
    flex-flow: column wrap;
    justify-content: stretch;
    align-items: stretch;
    align-self: stretch;
    background: linear-gradient(to left, #6A5EC1, #5785B5);
    flex: 2 1 auto;
}

.listCollect {
    display: flex;
    flex-flow: column;
    justify-content: stretch;
    align-items: stretch;
    background: #4E4784;
    max-width: 20%;
    flex: 1 1 18%;
}
/*
@media(max-width: 1600px) {

    .workArea {
        order: 2;
        max-width: 50%;
        flex: 2 1 64%;
    }

    .listInspect {
        order: 1;
        max-width: 25%;
        flex: 1 1 18%;
    }

    .listCollect {
        order: 3;
        max-width: 25%;
        flex: 1 1 18%;
    }
}*/

@media(max-width: 1400px) {
    .mainInfo {
        flex-wrap: wrap;
    }

    .workArea {
        order: -1;
        max-width: 100%;
        flex: 1 1 100%;
    }

    .listInspect {
        order: 1;
        max-width: 50%;
        flex: 1 1 50%;
    }

    .listCollect {
        order: 2;
        max-width: 50%;
        flex: 1 1 50%;
    }

    .mainInfo {
        // flex: 1 1 90vh;
    }
}

@media(max-width: 800px) {
    .mainInfo {
        flex-wrap: wrap;
    }

    .workArea {
        order: -1;
        max-width: 100%;
        flex: 1 1 100%;
    }

    .listInspect {
        order: 0;
        max-width: 100%;
        flex: 1 1 100%;
    }

    .listCollect {
        order: 1;
        max-width: 100%;
        flex: 1 1 100%;
    }
}
