.forbidden {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: column wrap;
  flex-grow: 1;
  background: transparent url('assets/backgroundLogin.svg') no-repeat top;
  background-size: 100%;
  font-family: 'Open Sans', sans-serif;


  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 57px;
    width: 100%;
    background: linear-gradient(90deg, #CED5D9 0%, #DAE0E7 18.29%, #E8EBF1 37.01%, #E7E7E7 49.46%, #EFF3F7 57.73%, #F5FAFD 76.36%, #E6EBF0 89.85%, #D7DDE1 100%), #FFFFFF;
    mix-blend-mode: normal;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    color: #434343;
    font-style: normal;
    font-size: 22px;
    line-height: 30px;
    font-weight: bold;

    .pipe {
      margin: 0 11px;
      width: 2px;
      height: 40%;
      background: #3863BB;
    }

    span {
      color: #3863BB;
    }
  }

  .blueArea{
    display: flex;
    flex-grow: 1;
    align-items: flex-start;
    padding-top: 200px;

    .wrapWhite {
      display: flex;
      flex-flow: column nowrap;
      min-width: 684px;
      min-height: 338px;
      background: white;
      border-radius: 4px;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      justify-content: center;
      align-items: center;

      .text {
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 41px;
        color: #434343;
        padding-bottom: 57px;
      }
    }
  }
}