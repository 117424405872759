.preReserveHeader {
  display: flex;
  flex-flow: row nowrap;
  min-height: 104px;
  background-color: #E3EAFC;
  color: #434343;
  padding: 0 50px;
  .info {
    flex-grow: 1;
    padding: 15px 0;
    border-right: 2px solid #c2bfc1;
  }
  .collectServiceInfo {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    min-width: 510px;
  }

  .mode {
    display: flex;
    flex-flow: column wrap;
    color: #262626;
    background-color: transparent;
    justify-self: center;
    padding: 15px 25px;
    flex-grow: 0;
    justify-content: center;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    min-width: 120px;
  }

  .carClientInfo {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    transition: all ease-out 0.2s;

    .users {
      color: #262626;
      margin-left: 15px;
      padding:  0 15px;
      min-height: 27px;
      box-sizing: border-box;
      border-radius: 4px;
      background-color: #FFFFFF;
      transition: all ease-out 0.2s;
    }
    &:hover {
      .users {
        color: #262626;
        transition: all ease-out 0.2s;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1), 0 1px 4px rgba(0, 0, 0, 0.16);
      }
    }
  }

  &.green {
    background: #C9F1E0;
    .info {
      border-color: rgba(134, 152, 102, 0.6);
    }
    .mode {
      color: #262626;
    }
  }
  &.red {
    color: #FFFFFF;
    background: #b64843;
    .info {
      border-color: rgba(229, 108, 153, 0.6);
    }
    .mode {
      color: #FFFFFF;
    }
  }
  &.yellow {
    background: #EFF3A5;
    .info {
      border-color: rgba(155, 117, 34, 0.6);
    }
    .mode {
      color: #262626;
    }
  }
}

.rowTitleLabel {
  display: flex;
  flex-flow: column wrap;
  min-width: 150px;
  padding: 10px 50px 10px 0;
  .label {
    font-size: 16px;
    font-weight: 400;
    color: #262626;
  }
  .title {
    font-size: 22px;
    color: #A34992;
  }

  &.greenTitle {
    .label {
      color: #ffffff;
    }
    .title {
      color: #3FB618;
    }
  }
}