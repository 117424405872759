.text {
  display: block;
  &.flexWrap {
    display: flex;
    align-self: stretch;
    justify-self: stretch;
    background-color: #F0F0F0;
    border: 1px solid #CCD1D9;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 10px 15px;
    &::placeholder {
      color: #949DA8;
    }
  }
  &.minWidth605 {
    min-width: 605px;
    max-width: 700px;
  }
  &.minHeight100 {
    min-height: 100px;
    max-height: 500px;
  }
}