.howManyMonths {
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  align-items: stretch;
  min-height: 40px;
  margin: 10px 0 20px;

  .oilCycleHowYear {
    display: flex;
    min-width: 40px;
    max-height: 40px;
    max-width: 100px;
    margin: 0 15px;
    padding: 0 15px;
    min-height: 40px;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 3px;
    appearance: none;
    background: #F0F0F0;
    font-size: 14px;
    line-height: 19px;

    &:focus {
      border-color: #4A87CA;
      border-radius: 3px;
      transition: ease-out all .2s;
    }
  }

  label {
    align-self: center;
  }
}