.carBasicInfoCard {
    display: flex;
    flex-flow: column wrap;
    justify-content: stretch;
    align-items: flex-start;
    padding-left: 20px;
    font-size: 16px;
    line-height: 22px;
}

.carBasicNumberMileage {
    display: flex;
    flex-flow: row wrap;
    justify-content: stretch;
    align-items: stretch;
    flex-grow: 1;
}