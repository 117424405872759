/**  table users  **/
.usersCart {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-flow: column wrap;
  .tr {
     td {
       padding: 10px 25px;
     }
  }
}

/** --->table users **/

.svBookDetails {
  background-color: #FFFFFF;
  color: #373a3c;
  padding: 40px 50px;
}

.svColumn {
  margin: 0;
  display: flex;
  flex-flow: column wrap;
  min-height: 100vh;
  background-color: #F3F3F3;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24), 0 1px 4px rgba(0, 0, 0, 0.12);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  //padding: 25px 50px;
}

.fields {
  align-self: stretch;
  padding: 0 15px;
  margin: 4px  10px 4px 0;
  width: 258px;
  height: 40px;
  border: 1px solid #E0E0E0;
  color: #262626;
  font-size: 16px;
  line-height: 22px;
}

select.fields, .fields option {
  background: white;
}

.blueBtn {
  background: #4A87CA;
  border: none;
  color: white;
  font-size: 16px;
  line-height: 22px;
  border-radius: 3px;
  margin: 4px  10px 4px 0;
  min-width: 150px;
  min-height: 40px;
  max-height: 40px;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  cursor: pointer;
}

.phone, .client {
  max-width: 208px;
  display: flex;
  flex-flow: wrap column;
  align-self: stretch;
  padding: 0 15px;
  margin: 4px  10px 4px 0;
  height: 40px;
  border: 1px solid #E0E0E0;
  color: #262626;
  font-size: 16px;
  line-height: 22px;
}

.rowFieldsClient {
  display: flex;
  flex-flow: wrap column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 25px 50px 75px ;
  border-top: 1px #E0E0E0 solid
}

.clients {
  display: flex;
  flex-flow: wrap column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 25px 50px;
}

.shadowBlock {
  border-top: 2px #ffffff solid;
  border-bottom: 2px #E0E0E0 solid;
}

.userFields {
  display: flex;
  flex-flow: wrap row;
}

.btn {
  display: flex;
  align-self: center;
  justify-content: center;
  border: 1px solid #4A87CA;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  background-color: transparent;
}

.addFormColumn {
  display: flex;
  flex-flow: wrap column;
  padding-bottom: 10px;


  .multiSelect {
    max-width: 243px;
  }
}

.addForm {
  display: flex;
  flex-flow: wrap row;
  padding-bottom: 10px;

  .newUserFields {
    max-width: 243px;
  }
}