.from1C {
  background: linear-gradient(0deg, #EEEEEE, #EEEEEE), linear-gradient(143.5deg, #FFEAF7 -0.74%, #FFF3E0 100%);
  border-radius: 2px 2px 0 0;
  border-top: #CCD1D9 1px solid;
  padding: 0 38px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  height: 57px;

  .text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #434343;
    margin-right: 10px;
  }
}
