
.link {
  color: #FFFFFF;
  transition: all ease-out 0.2s;
  &:hover {
    color: #FFFFFF;
    transition: all ease-out 0.2s;
    & > div {
      background-color: rgba(0, 0, 0, 0.24);
      transition: all ease-out 0.2s;
    }
  }
}

.headerText {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #434343;
  background: linear-gradient(90.5deg, #FFE0F8 0%, #FFE3F9 0.01%, #E3D6F7 100%);
  min-height: 171px;
  box-sizing: border-box;
  padding: 0 50px 0 0;
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  .count {
    box-sizing: border-box;
    display: flex;
    flex-flow: column wrap;
    width: 140px;
    align-items: center;
    justify-content: center;
    border-right: 2px solid #E6C8E1;
    .row {
      display: flex;
    }
    & > h1 {
      margin: 10px 0;
    }
    & h2 {
      margin: 0;
      padding-left: 5px;
      &.green {
        color: #3FB618;
      }
      &.red {
        color: #d90030;
      }
      &.grey {
        color: #434343;
      }
    }
  }
}

.description {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  padding: 0 24px 0 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  .spanHeader1 {
    font-weight: 600;
    font-size: 30px;
    line-height: 41px;
    margin: 0;
  }
  .subHeader {
    display: flex;
    flex-flow: row nowrap;
    .columnSubHeader {
      display: flex;
      flex-flow: column wrap;
      min-width: 123px;
      &.forRed {
        &:first-child > span {
          color: #faf650;
        }
      }
    }
    .columnSubHeader:last-child > span {
      padding-left: 12px;
    }
    .columnSubHeader:last-child > div {
      padding-left: 12px;
    }
    .columnSubHeader:first-child > span {
      color: #A34992;
    }
  }
}

.report {
  padding-bottom: 50px;
  .buttonMore {
    display: flex;
    align-self: stretch;
    margin: 50px 0;
    justify-content: center;
  }
}

.emptyText {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  justify-content: center;
  display: flex;
  align-self: stretch;
  padding: 100px;
}

.prereserve {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: stretch;
  z-index: 1;

  & > div {
    padding-left: 25px;
    min-height: 60px;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    border-bottom: 1px #5d89f8 solid;
  }
  .id {
    min-width: 50px;
  }
  .date2 {
    width: 235px;
    min-width: 235px;
  }
  .date {
    width: 90px;
    min-width: 90px;
  }

  .car {
    width: 150px;
    min-width: 150px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    .text {
      display: flex;
      flex-flow: row wrap;
      max-width: 150px;
    }
  }

  .carNumber {
    width: 150px;
    min-width: 150px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    .text {
      display: flex;
      flex-flow: row wrap;
      max-width: 150px;
    }
  }
  .other {
    width: 110px;
    min-width: 110px;
  }
  .owner {
    min-width: 110px;
    flex-grow: 1;
    &.green {
      font-weight: 600;
      color: #3FB618;
    }
    &.yellow {
      font-weight: 600;
      color: #faf650;
    }
    &.red {
      font-weight: 600;
      color: #ef605b;
    }
  }

  &.prereserveHeader {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: stretch;
    background-color: #E3EAFC;
    color: rgba(0, 0, 0, 0.95);
    min-height: 80px;
    flex-flow: row nowrap;
    & > div {
      padding-left: 25px;
      min-height: 50px;
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
      border-bottom: 1px transparent solid;
    }
    .dateHeader {
      // min-width: 255px;
      flex-flow: column wrap;
      align-items: stretch;
      justify-content: stretch;
      padding-left: 0;
      min-width: 260px;
      .dateHeaderHeader {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        padding-left: 25px;
        justify-content: flex-start;
        align-self: stretch;
        justify-self: stretch;
        flex-grow: 1;
      }
      .dateHeaderRow {
        display: flex;
        flex-flow: row nowrap;
        height: 40px;
        & > div {
          padding-left: 25px;
          display: flex;
          height: 40px;
          align-items: center;
          justify-content: flex-start;
          min-width: 115px;
          flex-grow: 1;
        }
      }
    }
  }

}