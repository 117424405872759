.mainContainer {
  float: right;
  width: 304px;
  height: 52px;
  background-color: rgba(255, 255, 255, 0.16);
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #FFFFFF;
  background-image: url("./assets/ortusIcon.svg");
  background-position: 35px 16px;
  background-repeat: no-repeat;
  margin-left: auto;
  &:hover {
    cursor: pointer;
    background-color: #E5E5E5;

  }
  &.invited {
    width: 338px;
    background-position: 17px 16px;
    background-image: url("./assets/ortusInvited.svg");
    background-color: rgba(0, 0, 0, 0.15);
    color: #949DA8;
    &:hover {
      cursor: default;
    }
  }
}
.buttonText {
  margin-top: 15px;
  margin-left: 40px;
}
.activated {
  float: right;
  width: 141px;
  height: 86px;
  background-color: linear-gradient(131.16deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.016) 100%);
  opacity: 0.8;
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.14),
  inset 3px 3px 3px rgba(0, 0, 0, 0.14),
  inset -1px -1px 2px rgba(255, 255, 255, 0.1),
  inset -3px -3px 3px rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  background-image: url("./assets/ortusIconInactive.svg");
  background-position: 49px 20px;
  background-repeat: no-repeat;
  margin-right: -17px;
  margin-left: auto;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 600;
  font-size: 16px;

  & p {
    margin-top: 50px;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    font-weight: 600;
    font-size: 16px;
    margin-right: auto;
    margin-left: auto;

  }
}