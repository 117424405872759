
.reasonContainer {
  padding: 0 0 40px 0;
  display: flex;
  flex-flow: row wrap;
  .textReason {
    padding: 20px 0 0 40px;
    display: flex;
    flex-flow: row wrap;
    width: 125px;
    .test {
      max-width: 101px;
    }
  }
  .reasons {
    padding: 20px 0 0 40px;
    display: flex;
    flex-flow: column wrap;
    .cboxes {
      display: flex;
      flex-flow: row wrap;
      padding-bottom: 25px;
    }
    .textarea {
      display: flex;
      flex-flow: row wrap;
    }
  }
}
