.react-datepicker__year {
    margin: 20px 0 0 0;
}
.react-datepicker__triangle {
    display: none;
}
.react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 4px;
}
.react-datepicker {
    border-radius: 4px;
    box-shadow: 0 2px 9px rgba(28, 15, 83, 0.15), 0 16px 48px rgba(28, 15, 83, 0.12);
}

.react-datepicker__year-wrapper {
    display: flex;
    justify-content: flex-start;
    max-width: 184px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
}

.react-datepicker__year .react-datepicker__year-text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 184px;
    padding: 0 16px;
    height: 38px;
    border-radius: 0;
    margin: 0;
}

.react-datepicker__year-text--today {
    font-weight: normal;
}

.react-datepicker__year-text--keyboard-selected {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.85);
}

.react-datepicker__year-text:hover {
    background: #E8F4FF;
    color: rgba(0, 0, 0, 0.85);
}

.react-datepicker__year-text--selected {
    background: #EBEEF2;
    color: #949DA8;
}

.react-datepicker__year-text--selected:hover {
    background: #EBEEF2;
    color: #949DA8;
}